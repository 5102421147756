// mensagens padrões dos editores html do match de hospedagem no evento

// Enviar solicitação
export var solicitationPt =
  '<p>Olá, ${chooserName},</p> <p>Você recebeu um convite para compartilhar o quarto com o(s) seguinte(s) participante(s):</p>${listRoomates()}';

export var solicitationEn =
  '<p>Hi, ${chooserName},</p> <p>You have received an invitation to share a room with the following participant(s):</p>${listRoomates()}';

export var solicitationEs =
  '<p>Hola, ${chooserName},</p> <p>Ha recibido una invitación para compartir una habitación con los siguientes participantes:</p>${listRoomates()}';

// Aceite
export var acceptPt =
  '<p>Olá, ${chooserName},</p> <p>O seu convite para compartilhamento de quarto foi aceito pelo(s) seguinte(s) participante(s):</p>${listRoomates()}';

export var acceptEn =
  '<p>Hi, ${chooserName},</p><p>Your room-sharing invitation has been accepted by the following participant(s):</p>${listRoomates()}';

export var acceptEs =
  '<p>Hola, ${chooserName},</p> <p>Su invitación para compartir habitación ha sido aceptada por los siguientes participantes:</p>${listRoomates()}';

// Recusa
export var refusedPt =
  '<p>Olá, ${chooserName},</p><p>O seu convite para divisão de quarto foi cancelado, pois o(a) participante abaixo está indisponível:</p>${chosenName}';

export var refusedEn =
  '<p>Hi, ${chooserName},</p> <p>Your room-sharing invitation has been canceled because the participant below is unavailable:</p>${chosenName}';

export var refusedEs =
  '<p>Hola, ${chooserName},</p> <p>Su invitación para compartir habitación ha sido cancelada porque el siguiente participante no está disponible:</p>${chosenName}';
