import axios from 'axios';
import { urlBase } from './config/index';

async function getDashboardAccesses({ eventId, jwt }) {
  const config = {
    method: 'get',
    url: `${urlBase}/dashboards/accesses/${eventId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    }
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getDashboardFeed({eventId, jwt}) {
  const config = {
    method: 'get',
    url: `${urlBase}/dashboards/feed/${eventId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    }
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getDashboardRanking({eventId, jwt}) {
  const config = {
    method: 'get',
    url: `${urlBase}/dashboards/ranking/${eventId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    }
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getClickButtonMenu({ eventId, jwt }) {
  const config = {
    method: 'get',
    url: `${urlBase}/dashboards/analytics/buttonclick/${eventId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}
export {
  getClickButtonMenu,
  getDashboardAccesses,
  getDashboardFeed,
  getDashboardRanking,
};