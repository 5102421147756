import axios from "axios";
import { urlBase } from './config';

function getMenus(jwt, idEvent) {
  var data = JSON.stringify({
    id: idEvent,
  });

  var config = {
    method: "post",
    url: `${urlBase}/admin/menuevent`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function newMenu(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/buttons/create`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function updateMenu(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/buttons/update`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function orderButtonMenu(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/buttons/order`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }

}
async function deleteButtonMenu(id, jwt) {
  var config = {
    method: "delete",
    url: `${urlBase}/buttons/${id}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export { getMenus, newMenu, updateMenu, orderButtonMenu, deleteButtonMenu };
