import writeXlsxFile from 'write-excel-file';
import { getSurveysByGroup } from '../../../../utils/services/survey';

export async function quizProdutosNeogenXlsx({
  jwt,
  eventId,
  limit,
  search,
  totalItems,
  setFinishExport,
}) {
  const formatDate = (date) => {
    const dateFormat = date.split(' ')[0].split('-').reverse().join('/');
    const time = date.split(' ')[1].slice(0, 8);
    return `${dateFormat} - ${time}`;
  };

  const schema = [
    {
      column: 'ID',
      type: String,
      value: (e) => e.ID,
    },
    {
      column: 'Nome',
      type: String,
      value: (e) => e.Nome,
    },
    {
      column: 'Email',
      type: String,
      value: (e) => e.Email,
    },
    {
      column: 'Empresa',
      type: String,
      value: (e) => e.Empresa,
    },
    {
      column: 'Cargo',
      type: String,
      value: (e) => e.Cargo,
    },
    {
      column: 'Cidade',
      type: String,
      value: (e) => e.Cidade,
    },
    {
      column: 'WhatsApp corporativo',
      type: String,
      value: (e) => e['WhatsApp corporativo'],
    },
    {
      column:
        '1. Coloque em ordem de prioridade para seu laboratório os seguintes pontos:',
      type: String,
      value: (e) =>
        e['1. Coloque em ordem de prioridade para seu laboratório os segu'],
    },
    {
      column:
        '2. Quais soluções Neogen você tem interesse em ter mais informações?',
      type: String,
      value: (e) =>
        e['2. Quais soluções Neogen você tem interesse em ter mais info'],
    },
    {
      column: 'Selecione o produto',
      type: String,
      value: (e) => e['Selecione o produto'],
    },

    {
      column: '[CERTAS] Recepção de materias primas',
      type: String,
      value: (e) => e['[CERTAS] Recepção de materias primas'],
    },
    {
      column: '[ERRADAS] Recepção de materias primas',
      type: String,
      value: (e) => e['[ERRADAS] Recepção de materias primas'],
    },

    {
      column: '[CERTAS] Monitoramento Ambiental',
      type: String,
      value: (e) => e['[CERTAS] Monitoramento Ambiental'],
    },
    {
      column: '[ERRADAS] Monitoramento Ambiental',
      type: String,
      value: (e) => e['[ERRADAS] Monitoramento Ambiental'],
    },
    {
      column: '[CERTAS] Controle de qualidade do produto acabado',
      type: String,
      value: (e) => e['[CERTAS] Controle de qualidade do produto acabado'],
    },

    {
      column: '[ERRADAS] Controle de qualidade do produto acabado',
      type: String,
      value: (e) => e['[ERRADAS] Controle de qualidade do produto acabado'],
    },
    {
      column: 'Data e hora da resposta',
      type: String,
      value: (e) => formatDate(e.Data),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(totalItems / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getSurveysByGroup({
      jwt,
      eventId,
      offset: i,
      limit,
      search,
      reportName: 'quiz_produtos_Neogen',
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector('.export-progress');
    const percentageBar = document.querySelector('.export-percentage');

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.data.findAllSurveyData.data)
    .flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: 'quizProdutos.xlsx',
    });
  }
}
