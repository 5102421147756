import axios from "axios";
import { urlBaseFiles } from "./config/index";

async function postImg(data, jwt) {
  const config = {
    method: "post",
    url: `${urlBaseFiles}/gallery`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${jwt}`,
    },
    data
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getImg(
  jwt,
  offset,
  limit,
  type = 'InteegraDrive',
  search,
  columnSearch = ''
) {
  const config = {
    method: 'get',
    url: `${urlBaseFiles}/gallery?type=${type}&offset=${offset}&limit=${limit}${
      search && `&search=${search}&columnSearch=${columnSearch}`
    }`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function deleteImg(jwt, id) {
  const config = {
    method: "delete",
    url: `${urlBaseFiles}/gallery/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function updateImg(jwt, data) {
  const config = {
    method: "put",
    url: `${urlBaseFiles}/gallery`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

export { postImg, getImg, deleteImg, updateImg };
