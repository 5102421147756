import axios from "axios";
import { urlBaseAgenda, urlBase } from "./config";
const baseUrl = urlBaseAgenda;

function createSchedule(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "post",
    url: `${baseUrl}/agenda`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getSchedule({ id, jwt, offset, limit, search, columnSearch = "" }) {
  var config = {
    method: "get",
    url: `${baseUrl}/events/${id}/agenda/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteSchedule({ id, jwt }) {
  var config = {
    method: "delete",
    url: `${baseUrl}/agenda/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function deleteAllSchedules({ jwt, eventId }) {
  var config = {
    method: "put",
    url: `${baseUrl}/agenda/disableall/${eventId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function disableScheduleByGroup({ jwt, groupId }) {
  var config = {
    method: "put",
    url: `${baseUrl}/agenda/disablebygroup/${groupId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
function editSchedule(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "put",
    url: `${baseUrl}/agenda/${data.id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function importSchedule(data) {
  var dataForm = JSON.stringify(data);
  var config = {
    method: "post",
    url: `${baseUrl}/agenda/import`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function sendTickedEmail(data, jwt) {
  // var dataForm = JSON.stringify(data);
  var config = {
    method: "post",
    url: `${urlBase}/logineventoscotas/emailwithattachments`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getParticipantsEvent(sessionId, jwt) {
  var config = {
    method: "get",
    url: `${urlBase}/logineventoscotas/${sessionId}/session`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  createSchedule,
  getSchedule,
  deleteSchedule,
  deleteAllSchedules,
  disableScheduleByGroup,
  editSchedule,
  importSchedule,
  sendTickedEmail,
  getParticipantsEvent,
};
