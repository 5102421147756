import axios from "axios";
import { urlBase } from './config';

function getFeed({ jwt, idEvent, offset, limit, search, columnSearch = '' }) {
  var config = {
    method: 'get',
    url: `${urlBase}/admin/feeds/events/flat/${idEvent}/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
}

function newFeed(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/admin/feeds`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function updateFeed(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/postsupdate`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteFeed( id, token ) {
  var config = {
    method: "delete",
    url: `${urlBase}/admin/feeds/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function disableAllPosts({ jwt, eventId }) {
  var config = {
    method: "put",
    url: `${urlBase}/admin/feeds/disableall/${eventId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { disableAllPosts, deleteFeed, getFeed, newFeed, updateFeed };
