import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

//services
import { getParticipants } from "../../../utils/services/participants";
import { createTransfer } from "../../../utils/services/transfer";

function NewTransfer(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de participantes
  const [participants, setParticipants] = useState([]);
  const [participantSelected, setParticipantSelected] = useState();
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [sourcerError, setSourceError] = useState("");
  const [destinyError, setDestinyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [dateArrivalError, setDateArrivalError] = useState("");
  const [timeArrivalError, setTimeArrivalError] = useState("");

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const submitForm = async (e) => {
    //tratamento de erros
    if (!participantSelected || participantSelected === "Selecione") {
      setParticipantError("O campo Participante é obrigatório");
      return;
    } else setParticipantError("");

    if (e.target[1].value === "") {
      setCompanyError("O campo Empresa é obrigatório");
      return;
    } else setCompanyError("");

    if (e.target[2].value === "") {
      setSourceError("O campo Origem é obrigatório");
      return;
    } else setSourceError("");

    if (e.target[3].value === "") {
      setDestinyError("O campo Destino é obrigatório");
      return;
    } else setDestinyError("");

    if (e.target[4].value === "") {
      setDateBoardingError("O campo Data embarque é obrigatório");
      return;
    } else setDateBoardingError("");

    if (e.target[5].value === "") {
      setTimeBoardingError("O campo Hora embarque é obrigatório");
      return;
    } else setTimeBoardingError("");

    // if (e.target[6].value === "") {
    //   setDateArrivalError("O campo Data chegada é obrigatório");
    //   return;
    // } else setDateArrivalError("");

    // if (e.target[7].value === "") {
    //   setTimeArrivalError("O campo Hora chegada é obrigatório");
    //   return;
    // } else setTimeArrivalError("");

    await createTransfer({
      event: props.idEvent,
      login: participantSelected,
      company: e.target[1].value,
      origin: e.target[2].value,
      destination: e.target[3].value,
      boardingdate: e.target[4].value,
      arrivaldate: e.target[6].value !== "" ? e.target[6].value : null,
      boardingtill: e.target[5].value,
      arrivaltime: e.target[7].value,
      meetingpoint: e.target[8].value,
      drivername: e.target[9].value,
      driverphone: e.target[10].value,
      extrainformation: e.target[11].value,
      jwt: props.state.global_user_data.data.token,
    }).then((response) => {
      setsuccess_dlg(true);
      // console.log(response);
    });
    // .catch((error) => console.log("error", error));
  };

  async function getAllParticipants() {
    await getParticipants({
      jwt: props.state.global_user_data.data.token,
      idEvent: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setParticipants(response.data.data);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }

  useEffect(() => {
    getAllParticipants();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            toggle();
            // props.getAllTransfers();
            props.data[0].refetch();
            props.data[1].refetch();
            setsuccess_dlg(false);
          }}
        >
          {"Transfer criado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Transfer</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Participante*</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setParticipantSelected(e.target.value);
                  }}
                >
                  <option>Selecione</option>
                  {participants &&
                    participants.map((e, i) => (
                      <option key={i} value={e.id}>
                        {e.nome}
                      </option>
                    ))}
                </select>
                <p style={{ color: "red" }}>{participantError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Empresa*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{companyError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Origem*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{sourcerError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Destino*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{destinyError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data embarque*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{dateBoardingError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora embarque*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{timeBoardingError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data chegada</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{dateArrivalError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora chegada</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{timeArrivalError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Ponto de encontro</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Nome do motorista</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Telefone do motorista</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Informações extras</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewTransfer);
