import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getProducts,
  editScreenProducts,
} from "../../../utils/services/products";

function ModalNewScreen(props) {
  const { isOpen, toggle } = props;
  // erro
  const [erroProduct, setErroProduct] = useState('');
  const [erroScreen, setErroScreen] = useState('');
  const [erroShortName, setErroShortName] = useState('');

  // alerta
  const [successAlert, setSuccessAlert] = useState(false);
  const [optionsProducts, setOptionsProducts] = useState([]);

  function verifyErros(e) {
    if (!e.target[1].value) {
      setErroScreen('Esse campo é obrigatório');
      return;
    } else setErroScreen('');
    if (!e.target[2].value) {
      setErroShortName('Esse campo é obrigatório');
      return;
    } else setErroShortName('');
    submitForm(e);
  }

  function removerAcentos(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  function submitForm(e) {
    const data = {
      name: e.target[1].value,
      mainProductId: props?.data?.mainProduct?.id,
      screenName: e.target[1].value,
      shortName: removerAcentos(e.target[2].value).toUpperCase(),
    };

    editScreenProducts({
      jwt: props.state.global_user_data.data.token,
      data: data,
      id: props.data.id,
    }).then((res) => {
      if (res.message === 'PRODUCT_UPDATED') {
        props.getAllProducts();
        setSuccessAlert(true);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  function getAllProducts() {
    getProducts({
      jwt: props.state.global_user_data.data.token,
      offset: 1,
      limit: 200,
      mainProduct: true,
      columnSearch: 'name',
      search: '',
    }).then((res) => {
      if (res.message === 'PRODUCTS') {
        setOptionsProducts(
          res.data.products.filter((e) => e.mainProduct === null)
        );
      }
    });
  }

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Editada"
          onConfirm={() => {
            toggle();
            setSuccessAlert(false);
          }}
        >
          Tela editada com sucesso
        </SweetAlert>
      ) : null}
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Editar Tela</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyErros(e);
            }}
          >
            <Row>
              <Col md={12}>
                <label className="form-label">Produto (Sistema) *</label>
                <input
                  className="form-control"
                  type="text"
                  value={props.data.mainProduct.name}
                  disabled
                />
                {erroProduct && <p style={{ color: 'red' }}>{erroProduct}</p>}
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Tela *</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.data.name}
                />
                {erroScreen && <p style={{ color: 'red' }}>{erroScreen}</p>}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Nome para Governança *</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.data.screen.shortName}
                  disabled
                />
                {erroShortName && (
                  <p style={{ color: 'red' }}>{erroShortName}</p>
                )}
              </Col>
            </Row>
            <Row
              style={{
                width: '100%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>

              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
                type="submit"
              >
                Salvar
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalNewScreen);
