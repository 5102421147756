import axios from "axios";
import { urlBase } from "./config";

async function getMobileApps({ jwt, offset, limit, search, status = false, contractId }) {
  const config = {
    method: "get",
    url: `${urlBase}/mobileapp?offset=${offset}&limit=${limit}&search=${search}&status=${status ? 'inativo' : 'ativo'}&contractId=${contractId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
async function getMobileAppsByEvent({ jwt, eventId }) {
  const config = {
    method: "get",
    url: `${urlBase}/mobileapp/event/${eventId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function createApp({
  jwt,
  name,
  bundleId,
  idFirebaseProject,
  contractId,
  termoDeAcesso,
  telaPrincipal,
}) {
  const config = {
    method: 'post',
    url: `${urlBase}/mobileapp`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: {
      name,
      bundleId,
      idFirebaseProject,
      contractId,
      termoDeAcesso,
      telaPrincipal,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteApp({ jwt, id, contractId }) {
  const config = {
    method: "delete",
    url: `${urlBase}/mobileapp/${id}/${contractId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function updateApp({jwt, id, data}) {
  const config = {
    method: "put",
    url: `${urlBase}/mobileapp/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { getMobileApps, getMobileAppsByEvent, createApp, deleteApp, updateApp };