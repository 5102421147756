import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Collapse,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import {
  createTemplateEmail,
  getTemplateEmail,
  editTemplateEmail,
} from '../../../utils/services/templatesEmailQuota';

import SweetAlert from 'react-bootstrap-sweetalert';
import TextEditor from '../../../components/TextEditor';
import SectionComponent from '../../Apps/Personalization/SectionComponent';
import {
  confirmRegistrationHtml,
  createEditLoginHtml,
  winnersHtml,
  notChosenHtml,
} from './htmlDefaults';
import { TooltipAgendaModal } from './TooltipAgendaModal';

export default function ModalConfigTemplateEmail(props) {
  const { isOpen, toggle, eventId, jwt } = props;
  const [formData, setFormData] = useState({});
  const [erros, setErros] = useState({});
  const [sectionOne, setSectionOne] = useState(false);
  const [sectionTwo, setSectionTwo] = useState(false);
  const [sectionWinners, setSectionWinners] = useState(false);
  const [sectionNotChosen, setSectionNotChosen] = useState(false);

  const refEditorConfirmRegistration = useRef(null);
  const refEditorCreateEditLogin = useRef(null);
  const refEditorWinners = useRef(null);
  const refEditorNotChosen = useRef(null);

  const [successCreateAlert, setSuccessCreateAlert] = useState(false);
  const [successUpdateAlert, setSuccessUpdateAlert] = useState(false);
  const [typeSendApi, setTypeSendApi] = useState('');
  const [loadingData, setLoadingData] = useState(true);

  function verifyErros() {
    setErros({});
    setSectionOne(false);
    setSectionTwo(false);
    setSectionWinners(false);
    setSectionNotChosen(false);

    if (!formData.subjectCadParticipant) {
      setErros((prev) => ({
        ...prev,
        subjectCadParticipant: 'Preencher o Assunto do Email',
      }));
      setSectionOne(true);
      return;
    }

    if (!refEditorCreateEditLogin?.current?.getContent()) {
      setErros((prev) => ({
        ...prev,
        bodyCadParticipant: 'Preencher o Corpo do Email',
      }));
      setSectionOne(true);
      return;
    }

    if (!formData.subjectConfirmation) {
      setErros((prev) => ({
        ...prev,
        subjectConfirmation: 'Preencher o Assunto do Email',
      }));
      setSectionTwo(true);
      return;
    }

    if (!refEditorConfirmRegistration?.current?.getContent()) {
      setErros((prev) => ({
        ...prev,
        bodyConfirmation: 'Preencher o Corpo do Email',
      }));
      setSectionTwo(true);
      return;
    }

    if (!formData.subjectWinner) {
      setErros((prev) => ({
        ...prev,
        subjectWinner: 'Preencher o Assunto do Email',
      }));
      setSectionWinners(true);
      return;
    }

    if (!refEditorWinners?.current?.getContent()) {
      setErros((prev) => ({
        ...prev,
        bodyWinner: 'Preencher o Corpo do Email',
      }));
      setSectionWinners(true);
      return;
    }

    if (!formData.subjectNotChosen) {
      setErros((prev) => ({
        ...prev,
        subjectNotChosen: 'Preencher o Assunto do Email',
      }));
      setSectionNotChosen(true);
      return;
    }

    if (!refEditorNotChosen?.current?.getContent()) {
      setErros((prev) => ({
        ...prev,
        bodyNotChosen: 'Preencher o Corpo do Email',
      }));
      setSectionNotChosen(true);
      return;
    }
    submitForm();
  }
  async function submitForm() {
    const data = {
      ...formData,
      templateCadParticipant: refEditorCreateEditLogin?.current?.getContent(),
      templateConfirmation: refEditorConfirmRegistration?.current?.getContent(),
      templateNotChosen: refEditorNotChosen?.current?.getContent(),
      templateWinner: refEditorWinners?.current?.getContent(),
    };

    if (typeSendApi === 'put') {
      await editTemplateEmail({
        data: data,
        token: jwt,
      })
        .then((res) => {
          if (res.message === 'created') {
            setSuccessCreateAlert(true);
          } else if (res.message === 'updated') {
            setSuccessUpdateAlert(true);
          } else {
            // erro
          }
        })
        .catch((error) => {
          // console.log("erro", error);
        });
    }
    if (typeSendApi !== 'put') {
      await createTemplateEmail({
        data: data,
        token: jwt,
      })
        .then((res) => {
          if (res.message === 'created') {
            setSuccessCreateAlert(true);
          } else if (res.message === 'updated') {
            setSuccessUpdateAlert(true);
          } else {
            // erro
          }
        })
        .catch((error) => {
          // console.log("erro", error);
        });
    }
  }

  async function getTemplateEmailQuota() {
    await getTemplateEmail({
      token: jwt,
      eventId: eventId,
    })
      .then((res) => {
        const resp = res?.findAllTemplates;
        if (resp?.count > 0) {
          const data = resp.data[0];
          const { createdAt, updatedAt, ...restData } = data;

          setTypeSendApi('put');
          setFormData({ ...restData });
        } else {
          setTypeSendApi('post');

          setFormData({
            eventId: eventId,
            subjectCadParticipant: 'Inscrição no ${sessionName}',
            subjectConfirmation: 'Inscrição no sorteio ${sessionName}',
            subjectNotChosen: 'Você não foi premiado para o ${sessionName}',
            subjectWinner: 'Você foi premiado para o ${sessionName}',
            templateCadParticipant: createEditLoginHtml,
            templateConfirmation: confirmRegistrationHtml,
            templateNotChosen: notChosenHtml,
            templateWinner: winnersHtml,
          });
        }

        setLoadingData(false);
      })
      .catch((error) => {
        // console.log("Erro", error);
      });
  }

  useEffect(() => {
    getTemplateEmailQuota();
  }, []);

  const [isModalTooltip, setIsModalTooltip] = useState(false);
  const [tooltipType, setTooltipType] = useState("")

  const modalToggle = () => setIsModalTooltip(!isModalTooltip);

  const openModal = (type) => {
    setIsModalTooltip(true)
    setTooltipType(type)
  }

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        {successCreateAlert && (
          <SweetAlert
            success
            title={'Criado'}
            onConfirm={() => {
              setSuccessCreateAlert(false);
              toggle();
            }}
          >
            Configurações criadas com sucesso
          </SweetAlert>
        )}

        {successUpdateAlert && (
          <SweetAlert
            success
            title={'Editado'}
            onConfirm={() => {
              setSuccessUpdateAlert(false);
              toggle();
            }}
          >
            Configurações editadas com sucesso
          </SweetAlert>
        )}

        <ModalHeader toggle={toggle}>
          Configurações dos Templates de Email
        </ModalHeader>
        {loadingData ? (
          <div className="div-chatfixed" style={{ justifyContent: 'center' }}>
            <a style={{ color: 'black' }}>
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
              Carregando
            </a>
          </div>
        ) : (
          <ModalBody>
            <Row>
              <SectionComponent
                sectionName={'Cadastro/Edição de Participantes com Cota(s)'}
                setIsOpen={setSectionOne}
                isOpen={sectionOne}
              />
            </Row>
            <Collapse isOpen={sectionOne}>
              <Row style={{ marginTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Assunto do Email *</label>
                    <input
                      value={formData.subjectCadParticipant}
                      onChange={(el) =>
                        setFormData((prev) => ({
                          ...prev,
                          subjectCadParticipant: el.target.value,
                        }))
                      }
                      className="form-control"
                      type="text"
                    />
                  </div>
                  {erros.subjectCadParticipant && (
                    <p style={{ color: 'red' }}>
                      {erros.subjectCadParticipant}
                    </p>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Corpo do Email *
                  <div id={`email-solicitation`} style={{ paddingRight: "7px", height: "16px" }}>
                    <div onClick={() => openModal("S")} className="show-tips">
                      <span>?</span>
                    </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: '#6f42c1' }}
                        placement="right"
                        target={`email-solicitation`}
                      >
                        Email enviado no cadadastro de cotas para o participante. <br />
                      (Clique para mais instruções)
                      </UncontrolledTooltip>
                    </div>
                </label>
                <TextEditor
                  defaultState={formData?.templateCadParticipant}
                  refState={refEditorCreateEditLogin}
                />
                {erros?.bodyCadParticipant && (
                  <p style={{ color: 'red' }}>{erros?.bodyCadParticipant}</p>
                )}
              </Row>
            </Collapse>
            <Row>
              <SectionComponent
                sectionName={'Confirmação de Inscrição no Sorteio'}
                setIsOpen={setSectionTwo}
                isOpen={sectionTwo}
              />
            </Row>
            <Collapse isOpen={sectionTwo}>
              <Row style={{ marginTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Assunto do Email *</label>
                    <input
                      value={formData.subjectConfirmation}
                      onChange={(el) =>
                        setFormData((prev) => ({
                          ...prev,
                          subjectConfirmation: el.target.value,
                        }))
                      }
                      className="form-control"
                      type="text"
                    />
                  </div>
                  {erros.subjectConfirmation && (
                    <p style={{ color: 'red' }}>{erros.subjectConfirmation}</p>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Corpo do Email *
                  <div id={`email-confirmation`} style={{ paddingRight: "7px", height: "16px" }}>
                    <div onClick={() => openModal("S")} className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: '#6f42c1' }}
                      placement="right"
                      target={`email-confirmation`}
                    >
                      Email enviado no cadastro do participante no sorteio. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor
                  defaultState={formData?.templateConfirmation}
                  refState={refEditorConfirmRegistration}
                />
                {erros?.bodyConfirmation && (
                  <p style={{ color: 'red' }}>{erros?.bodyConfirmation}</p>
                )}
              </Row>
            </Collapse>

            <Row>
              <SectionComponent
                sectionName={'Ganhadores dos Sorteios'}
                setIsOpen={setSectionWinners}
                isOpen={sectionWinners}
              />
            </Row>
            <Collapse isOpen={sectionWinners}>
              <Row style={{ marginTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Assunto do Email *</label>
                    <input
                      value={formData.subjectWinner}
                      onChange={(el) =>
                        setFormData((prev) => ({
                          ...prev,
                          subjectWinner: el.target.value,
                        }))
                      }
                      className="form-control"
                      type="text"
                    />
                  </div>
                  {erros.subjectWinner && (
                    <p style={{ color: 'red' }}>{erros.subjectWinner}</p>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <label onClick={() => openModal("S")} className="form-labe label-tooltip">
                  Corpo do Email *
                  <div id={`email-winners`} style={{ paddingRight: "7px", height: "16px" }}>
                    <div className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: '#6f42c1' }}
                      placement="right"
                      target={`email-winners`}
                    >
                      Email enviado para os participantes que ganharam os
                      sorteios. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor
                  defaultState={formData?.templateWinner}
                  refState={refEditorWinners}
                />
                {erros?.bodyWinner && (
                  <p style={{ color: 'red' }}>{erros?.bodyWinner}</p>
                )}
              </Row>
            </Collapse>

            <Row>
              <SectionComponent
                sectionName={'Participantes Não Sorteados'}
                setIsOpen={setSectionNotChosen}
                isOpen={sectionNotChosen}
              />
            </Row>
            <Collapse isOpen={sectionNotChosen}>
              <Row style={{ marginTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Assunto do Email *</label>
                    <input
                      // value={subjectNotChosen}
                      // onChange={(el) => setSubjectNotChosen(el.value)}
                      value={formData.subjectNotChosen}
                      onChange={(el) =>
                        setFormData((prev) => ({
                          ...prev,
                          subjectNotChosen: el.target.value,
                        }))
                      }
                      className="form-control"
                      type="text"
                    />
                  </div>
                  {erros.subjectNotChosen && (
                    <p style={{ color: 'red' }}>{erros.subjectNotChosen}</p>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <label onClick={() => openModal("NS")} className="form-labe label-tooltip">
                  Corpo do Email *
                  <div id={`email-notchosen`} style={{ paddingRight: "7px", height: "16px" }}>
                    <div className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: '#6f42c1' }}
                      placement="right"
                      target={`email-notchosen`}
                    >
                      Email enviado para os participantes que não ganharam os
                      sorteios. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor
                  defaultState={formData?.templateNotChosen}
                  refState={refEditorNotChosen}
                />
                {erros?.bodyNotChosen && (
                  <p style={{ color: 'red' }}>{erros?.bodyNotChosen}</p>
                )}
              </Row>
            </Collapse>

            <Row
              style={{
                width: '100%',
                margin: '20px 0 0 0',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <>
                <Button
                  color="danger"
                  style={{ width: 80, marginRight: 10 }}
                  onClick={() => toggle()}
                >
                  Fechar
                </Button>
                <Button
                  // disabled={
                  //   showInputGroupA === null ||
                  //   showInputGroupB === null ||
                  //   formData.segregate === undefined
                  // }
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  style={{ width: 80 }}
                  onClick={() => verifyErros()}
                >
                  Salvar
                </Button>
              </>
            </Row>
          </ModalBody>
        )}

        {isModalTooltip && <TooltipAgendaModal toggle={modalToggle} modal={isModalTooltip} type={tooltipType} />}
      </Modal>
    </>
  );
}
