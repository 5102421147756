import axios from "axios"
import { urlBase } from "./config"

async function getAllDict({jwt, offset, limit, search}) {
  try{
    const config = {
      method: 'get',
      url: `${urlBase}/dictionary?offset=${offset}&limit=${limit}${search ? `&search=${search}` : ''}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function updateDict ({data, jwt}) {
  try{
    const config = {
      method: 'put',
      url: `${urlBase}/dictionary`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data,
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function newDict ({data, jwt}) {
  try{
    const config = {
      method: 'post',
      url: `${urlBase}/dictionary`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data,
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function deleteDict ({id, jwt}) {
  try{
    const config = {
      method: 'delete',
      url: `${urlBase}/dictionary/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export { getAllDict, updateDict, newDict, deleteDict };