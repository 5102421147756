import writeXlsxFile from 'write-excel-file';
import { getReportLoginBySession } from '../../../utils/services/quotas';

export async function createXlsxParticipantSessions({
  eventId,
  sessionId,
  limit,
  countData,
  setFinishExport,
  jwt,
}) {
  const formatDate = (date) => {
    return date.split('T')[0].split('-').reverse().join('-');
  };

  const schema = [
    {
      column: 'Id do Evento',
      type: String,
      value: (schedule) => schedule.event,
    },
    {
      column: 'Nome do Evento',
      type: String,
      value: (schedule) => schedule.eventname,
    },
    {
      column: 'ID da Sessão',
      type: String,
      value: (schedule) => schedule.session,
    },
    {
      column: 'Descrição da Sessão',
      type: String,
      value: (schedule) =>
        `${schedule.title}, ${formatDate(schedule.startdate)} ${
          schedule.starthour
        } ${schedule.endhour ? '-' : ''}${schedule.endhour}`,
    },
    {
      column: 'ID do Participante',
      type: String,
      value: (schedule) => schedule.login,
    },
    {
      column: 'Nome do Participante',
      type: String,
      value: (schedule) => schedule.name,
    },
    {
      column: 'Email do Participante',
      type: String,
      value: (schedule) => schedule.email,
    },
    {
      column: 'Quantidade Total de Ingressos',
      type: Number,
      value: (schedule) => schedule.companionsnumber,
    },
    {
      column:
        'Você ou seu(s) acompanhante(s) possuem restrição de acessibilidade?',
      type: String,
      value: (schedule) => (schedule.accessibilityrestriction ? 'Sim' : 'Não'),
    },
    {
      column: 'Qual?',
      type: String,
      value: (schedule) => schedule.typerestriction,
    },
    {
      column: 'Observações',
      type: String,
      value: (schedule) => schedule.notes,
    },
    {
      column: 'Empresa',
      type: String,
      value: (schedule) => schedule.empresa,
    },
    {
      column: 'Cargo',
      type: String,
      value: (schedule) => schedule.cargo,
    },
    {
      column: 'Código Interno 1',
      type: String,
      value: (schedule) => schedule.codigointerno1,
    },
    {
      column: 'Código Interno 2',
      type: String,
      value: (schedule) => schedule.codigointerno2,
    },
    {
      column: 'Código Interno 3',
      type: String,
      value: (schedule) => schedule.codigointerno3,
    },
    {
      column: 'Grupos/IDs',
      type: String,
      value: (schedule) => schedule.groups.map((e) => e.grupoid).join(', '),
    },
    {
      column: 'Grupos/Descrição',
      type: String,
      value: (schedule) => schedule.groups.map((e) => e.gruponame).join(', '),
    },
    {
      column: 'Data de Cadastro',
      type: String,
      value: (schedule) => formatDate(schedule.createdat),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getReportLoginBySession({
      eventId: eventId,
      sessionId: sessionId,
      jwt: jwt,
      offset: i,
      limit,
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector('.export-progress');
    const percentageBar = document.querySelector('.export-percentage');

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.loginEventosCotas.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: 'Participantes_Sessão.xlsx',
    });
  }
}
