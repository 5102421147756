import React, { useState } from "react";
//router dom
import { useNavigate } from "react-router-dom";
//
import { Input } from "reactstrap";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";
//Toast
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//service de redefinir senha
import {
  recoveryPasswordEmail,
  recoveryPasswordConfirm,
} from "../utils/services/loginUser";

export default function ForgotPassword(props) {
  const [step, setStep] = useState("_a");
  //campos do formulário
  const [emailValue, setEmailValue] = useState("");
  const [tokenValue, setTokenValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmValue, setPasswordConfirmValue] = useState("");
  const [viewPass, setViewPass] = useState(false);
  const [viewPassConfirmation, setViewPassConfirmation] = useState(false);
  //erros
  const [emailError, setEmailError] = useState("");
  const [tokenError, setTokenError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [apiValidation, setApiValidation] = useState([]);
  const [errorHasCotas, setErrorHasCotas] = useState(false);

  const history = useNavigate();

  const submitForEmail = async () => {
    setErrorHasCotas(false);

    if (emailValue === '') {
      setEmailError('Insira um email');
      return;
    } else setEmailError('');

    if (emailValue.includes('@comgas.com.br')) {
      setErrorHasCotas(true);
      return;
    }
    var userDataEmail = await recoveryPasswordEmail({
      email: emailValue.replace(/ /gi, ''),
    });

    if (userDataEmail.message === 'FIRST_ACCESS_PENDING') {
      setEmailError(
        "O email informado nunca realizou login no Inteegra Plus, gere a sua senha a partir da opção 'Primeiro Acesso' da tela login"
      );
      return;
    } else if (
      userDataEmail.message === 'TOKEN_RECOVERY_PASSWORD_SENDED_BY_EMAIL'
    ) {
      setEmailError('');

      toastr.options.progressBar = true;
      toastr.options.positionClass = 'toast-top-center';
      toastr.success(
        'Um token foi enviado para o seu email',
        'Gerenciamento de Senha'
      );

      setStep('_b');
    } else setEmailError('Email não encontrado');
    //
  };

  const submitFormToken = async () => {
    if (tokenValue === '') {
      setTokenError('Insira o token enviado pro seu email');
      return;
    } else setTokenError('');

    if (passwordValue === '') {
      setPasswordError('Insira uma nova senha');
      return;
    } else setPasswordError('');

    if (passwordConfirmValue === '') {
      setPasswordConfirmError('Confirme a nova senha');
      return;
    } else setPasswordConfirmError('');

    if (passwordValue !== passwordConfirmValue) {
      setPasswordError('As senhas são diferentes  ');
    }

    setApiValidation('');
    setTokenError('');

    var userDataToken = await recoveryPasswordConfirm({
      email: emailValue,
      password: passwordValue,
      confirmPassword: passwordConfirmValue,
      token: tokenValue,
    });

    if (userDataToken.message === 'RECOVERY_PASSWORD_SUCCESS') {
      localStorage.setItem('authUser', Date.now());
      history('/events');
      return;
    }

    if (userDataToken.message === 'TOKEN_ACCESS_INVALID_OR_EXPIRED') {
      setTokenError('Token inválido ou expirado');
      return;
    }

    setApiValidation(userDataToken.message[0]);
  };

  return (
    <div className="p-2">
      {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
      {errorHasCotas && (
        <p style={{ color: 'red' }}>
          Para acessar o sistema utilize a opção Acessar com SSO da tela de
          login
        </p>
      )}
      <AvForm className="form-horizontal">
        <div className="mb-3">
          <Input
            className="form-control"
            placeholder="Insira seu email"
            type="text"
            required
            disabled={step === '_a' ? false : true}
            onChange={(e) => setEmailValue(e.target.value)}
          />
        </div>

        {step === '_a' && (
          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              onClick={submitForEmail}
            >
              Gerar Token
            </button>
          </div>
        )}
      </AvForm>

      {step === '_b' && (
        <AvForm className="form-horizontal">
          {tokenError && <p style={{ color: 'red' }}>{tokenError}</p>}
          <div className="mb-3">
            <Input
              className="form-control"
              placeholder="Insira o token enviado para o seu email"
              type="text"
              required
              onChange={(e) => setTokenValue(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 'bold' }}>A senha deve ter:</span>
            <a>• Mínimo 8 caracteres</a>
            <a>• Letra Minúscula</a>
            <a>• Letra Maiúscula</a>
            <a>• Número</a>
            <a>• Caractere especial (ex: #, %, @, ^)</a>
          </div>

          {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
          <div
            className="mb-3"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Input
              className="form-control"
              placeholder="Insira sua senha"
              type={!viewPass ? 'password' : 'text'}
              required
              onChange={(e) => setPasswordValue(e.target.value)}
            />
            <a onClick={() => setViewPass(!viewPass)}>
              {viewPass ? (
                <i className="mdi mdi-eye-off-outline icon-password" />
              ) : (
                <i className="mdi mdi-eye-outline icon-password" />
              )}
            </a>
          </div>

          {passwordConfirmError && (
            <p style={{ color: 'red' }}>{passwordConfirmError}</p>
          )}
          <div
            className="mb-3"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Input
              className="form-control"
              placeholder="Confirme sua senha"
              type={!viewPassConfirmation ? 'password' : 'text'}
              required
              onChange={(e) => setPasswordConfirmValue(e.target.value)}
            />
            <a onClick={() => setViewPassConfirmation(!viewPassConfirmation)}>
              {viewPassConfirmation ? (
                <i className="mdi mdi-eye-off-outline icon-password" />
              ) : (
                <i className="mdi mdi-eye-outline icon-password" />
              )}
            </a>
          </div>

          {apiValidation.length > 0 && (
            <div
              style={{ display: 'flex', flexDirection: 'column', color: 'red' }}
            >
              {apiValidation.map((e) => (
                <a>• {e}</a>
              ))}
            </div>
          )}

          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              onClick={submitFormToken}
            >
              Acessar Inteegra Plus
            </button>
          </div>
        </AvForm>
      )}
      <div className="mt-4 text-center">
        <p
          className="text-muted"
          style={{ cursor: 'pointer' }}
          onClick={() => props.setScreen('_a')}
        >
          <i className="mdi mdi-lock me-1" />
          Voltar para tela de login
        </p>
      </div>
    </div>
  );
}
