import React, { useState, useEffect } from "react";

import { Table, Card, CardBody, CardTitle, Button, Collapse, Row } from "reactstrap";

//redux
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
//paginação
import Pagination from "../../../components/Paginationv3";

//service
import { getAllRanking } from "../../../utils/services/ranking";

//componentes
import ConfigRanking from "./modalNewConfigRanking";
import PlayersExtract from "./modalPlayersExtract";

import { format } from "date-fns";

import useDebounce from "../../../utils/hooks/useDebounce";
import WaitExport from "../../../components/WaitExport";
import { createXlsx } from "./createXlsx";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from '../../../components/SearchFilters';

function Ranking(props) {
  const location = useLocation();
  //state do modal
  const [modal, setModal] = useState(false);
  const [extractPointsModal, setExtractPointsModal] = useState(false);
  //state de pesquisa
  const [search, setSearch] = useState('');
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(1);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const debouncedSearch = useDebounce(search, 300);
  //ranking export
  const [exportRanking, setExportRanking] = useState(false);

  // state player points
  const [selectedPlayer, setSelectedPlayer] = useState('');

  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('name');

  const columnsFilter = [
    { name: 'Participante', value: 'name', type: 'text' },
    { name: 'Data da Última Pontuação', value: 'data', type: 'date' },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch('');
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split('/')
      .reverse()
      .join('/')
      .replace(/\//g, '-');
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      'date'
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  function toggleModal() {
    setModal(!modal);
  }

  function toggleExtractPointsModal() {
    setExtractPointsModal(!extractPointsModal);
  }

  async function getAllRankingFunc() {
    await getAllRanking({
      id: location.state.idEvent,
      jwt: props.state.global_user_data.data.token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    })
      .then((response) => {
        setData(response.data);
        setCountData(response.count);
        if (response.data.length > 0) {
          setExportRanking(true);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getAllRankingFunc();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <div>
      {modal && <ConfigRanking isOpen={modal} toggle={toggleModal} />}
      <WaitExport
        isOpen={exportInProgress}
        name="Ranking"
        preposicao="do"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <PlayersExtract
        isOpen={extractPointsModal}
        toggle={toggleExtractPointsModal}
        playerData={selectedPlayer}
      />
      <Card>
        <CardBody>
          <CardTitle>Ranking Geral</CardTitle>
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10,
                width: '50px',
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            {exportRanking && (
              <div style={{ width: '100%' }}>
                <div className="mobile-btn-section" style={{ width: '100%' }}>
                  <Row className="mobile-btns-top">
                    <form
                      className="app-search"
                      style={{ padding: '0px' }}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="position-relative">
                        <input
                          type="text"
                          value={search}
                          className="form-control"
                          placeholder="Pesquisar..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="bx bx-search-alt" />
                      </div>
                    </form>
                  </Row>
                  <SectionComponent
                    sectionName="Opções"
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                  />
                  <Collapse isOpen={isOpen}>
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsx({
                            jwt: props.state.global_user_data.data.token,
                            id: location.state.idEvent,
                            limit: 500,
                            search: search,
                            countData,
                            setFinishExport,
                          }) > setExportInProgress(true)
                        }
                      >
                        Exportar Ranking
                      </div>
                    </Row>
                  </Collapse>
                </div>

                <div className="header-btns-container">
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    style={{ marginRight: 10, marginTop: 10 }}
                    onClick={() =>
                      createXlsx({
                        jwt: props.state.global_user_data.data.token,
                        id: location.state.idEvent,
                        limit: 500,
                        search: search,
                        countData,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Exportar Ranking
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="table-responsive">
            <Table>
              <thead className="table-light">
                <tr>
                  <th>ID do Participante</th>
                  <th>Participante</th>
                  <th>Pontuação</th>
                  <th>Data/Hora da última pontuação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((e, i) => (
                    <tr
                      key={i}
                      style={{ cursor: 'pointer' }}
                      onDoubleClick={() => {
                        setSelectedPlayer(e);
                        setExtractPointsModal(true);
                      }}
                    >
                      <td>{e.loginId}</td>
                      <td>{e.name}</td>
                      <td>{e.totalPoints}</td>
                      <td>
                        {format(new Date(e.updatedAt), 'dd/MM/yyyy HH:mm:ss')}
                      </td>
                      <td>
                        <i
                          className="mdi mdi-eye"
                          style={{
                            color: 'blue',
                            fontSize: 17,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedPlayer(e);
                            setExtractPointsModal(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: 'end' }}>
        <Pagination
          data={data}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Ranking);
