import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Table,
  Collapse,
  Button,
} from "reactstrap";
// utils
import useDebounce from "../../utils/hooks/useDebounce";
// service
import { getImg, deleteImg } from "../../utils/services/drive";
// componentes
import SectionComponent from "../Apps/Personalization/SectionComponent";
import Pagination from "../../components/Paginationv3";
import ModalNewHtml from "./ModalNewHtml";
import ModalEditHtml from "./ModalEditHtml";
import ViewCode from "./ViewCode";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
import SearchFilters from "../../components/SearchFilters";
import { urlDomain } from "../../utils/services/config";

const columnsFilter = [
  { name: "Nome", value: "name", type: "text" },
  { name: "Tag", value: "value", type: "text" },
  { name: "Data de Criação", value: "createdAt", type: "date" },
];
export default function InteegraCreator() {
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  // pesquisa e paginação
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // collapse
  const [isCollapse, setIsCollapse] = useState(false);
  //   modais
  const [modalNewHtml, setModalNewHtml] = useState(false);
  const [modalEditHtml, setModalEditHtml] = useState(false);
  const [modalViewCode, setModalViewCode] = useState(false);
  // editar arquivo
  const [editDoc, setEditDoc] = useState();
  // deletar arquivo
  const [deleteDocData, setDeleteDocData] = useState();
  // confirmation
  const [deleteHtml, setDeleteHtml] = useState(false);
  const [confirmDeleteHtml, setConfirmDeleteHtml] = useState(false);
  // docs html
  const [docs, setDocs] = useState([]);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("name");

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter?.filter((item) => item?.value === activeFilter)[0]?.type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }

  function downloadFile(file) {
    // console.log(file);
    // return
    // var link = document.createElement("a");
    // link.download = "arquivo";
    // link.href = file;
    // link.click();
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `https://allow-any-origin-iframe.${urlDomain}/` + file,
      true
    );
    xhr.responseType = "blob";
    xhr.setRequestHeader(
      "Content-Disposition",
      "attachment; filename=" + "arquivo.html"
    );
    xhr.onload = function () {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "arquivo.html" || "arquivo"; // Nome do arquivo para download (opcional)

        // Simular o clique no link para iniciar o download
        link.click();
      }
    };

    xhr.send();
  }

  const getCreator = () => {
    const type = "InteegraCreator";
  
    getImg(
      jwt,
      currentIndex,
      itemsPerPage,
      type,
      treatmentSearch(),
      activeFilter
    ).then((response) => {
      // Verifica se response e response.files existem antes de fazer o mapeamento
      if (response && response.files) {
        console.log("FILES", response.files);
      } else {
        console.log("No files found");
      }
    }).catch((error) => {
      // Captura e imprime qualquer erro ocorrido na chamada de getImg
      console.error("Error fetching images:", error);
    });
  }
  
  useEffect(() => {
    getCreator();
  }, []);
  

  function getImages() {
    const type = "InteegraCreator";
    getImg(
      jwt,
      currentIndex,
      itemsPerPage,
      type,
      treatmentSearch(),
      activeFilter
    )
      .then((res) => {
        console.log("res", res)
        setCountData(res.total);
        var arrayData = [];
        if (res.files) {
          res.files.map((e) => {
            if (e.extension === "html" || e.extension === "blob") {
              arrayData.push(e);
            }
          });
        }
        console.log("ARRAY DATA", arrayData)
        setDocs(arrayData);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }

  async function deleteDoc() {
    await deleteImg(jwt, deleteDocData.id)
      .then((res) => {
        setConfirmDeleteHtml(true);
        setDeleteHtml(false);
      })
      .catch((error) => {
        // console.log("erro", error);
      });
  }

  useEffect(() => {
    getImages();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  // useEffect(() => {
  //   console.log("DOCS ORDER: ", { docs })
  // }, [])

  return (
    <div>
      {modalNewHtml && (
        <ModalNewHtml
          isOpen={modalNewHtml}
          toggle={() => setModalNewHtml(!modalNewHtml)}
          getImages={getImages}
        />
      )}

      {modalEditHtml && (
        <ModalEditHtml
          isOpen={modalEditHtml}
          toggle={() => setModalEditHtml(!modalEditHtml)}
          editDoc={editDoc}
          getImages={getImages}
        />
      )}

      {modalViewCode && (
        <ViewCode
          isOpen={modalViewCode}
          toggle={() => setModalViewCode(!modalViewCode)}
        />
      )}

      {deleteHtml ? (
        <SweetAlert
          title={`Deseja deletar o HTML ${deleteDocData.name}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteDoc();
          }}
          onCancel={() => setDeleteHtml(false)}
        >
          <a>Você não poderá reverter essa ação.</a>
        </SweetAlert>
      ) : null}

      {confirmDeleteHtml ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            getImages();
            setConfirmDeleteHtml(false);
          }}
        >
          HTML deletado com sucesso
        </SweetAlert>
      ) : null}
      <MetaTags>Inteegra Creator | Inteegra Plus</MetaTags>
      <Card>
        <CardBody>
          <CardTitle>Inteegra Creator</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewHtml(true)}>
                    Adicionar HTML
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewHtml(true)}
              >
                Adicionar HTML
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Arquivo</th>
                  <th>Tag</th>
                  <th>Data de Criação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {docs &&
                  docs
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .map((e, i) => (
                      <tr key={i}>
                        <td onClick={() => console.log(e)}>{e.name}</td>
                        <td>
                          {e.tags.map((tag) => (
                            <span className="tagDriver">{tag.value + " "}</span>
                          ))}
                        </td>
                        <td>
                          {e.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td>
                          <i
                            className="mdi mdi-content-copy"
                            style={{
                              color: "grey",
                              fontSize: 17,
                              margin: "0 3px",
                              cursor: "pointer",
                            }}
                            title="Copiar"
                            onClick={() => clipBoard(e.url)}
                          />
                          <a
                            href={e.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Abrir Arquivo"
                          >
                            <i
                              className="mdi mdi-location-enter"
                              style={{
                                color: "blue",
                                fontSize: 17,
                                margin: "0 3px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                          <i
                            className="mdi mdi-download"
                            onClick={() => downloadFile(e.url)}
                            style={{
                              color: "black",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                          />
                          {/* <i
                            title="Abrir Arquivo"
                            className="mdi mdi-code-tags"
                            onClick={() => setModalViewCode(true)}
                            style={{
                              color: "blue",
                              fontSize: 17,
                              margin: "0 3px",
                              cursor: "pointer",
                            }}
                          /> */}
                          <i
                            className="mdi mdi-pencil"
                            title="Editar"
                            style={{
                              margin: "0 3px",
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditDoc(e);
                              setModalEditHtml(true);
                            }}
                          />
                          <i
                            className="mdi mdi-trash-can"
                            title="Excluir"
                            style={{
                              color: "red",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setDeleteDocData(e);
                              setDeleteHtml(true);
                            }}
                          />
                        </td>
                      </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={docs}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
