export const instructionsPT = {
  title: "Instruções",
  chooserName: "✔️ ${chooserName} - Participante que receberá o e-mail.",
  listRoomates: "✔️ ${listRoomates()} - Lista de convidados para divisão de quartos.",
  chosenName: "✔️ ${chosenName} - Participante que solicitou a ação no convite.",
  warn: ""
}

export const instructionsEN = {
  title: "Instruções",
  chooserName: "✔️ ${chooserName} - Participant who will receive the email.",
  listRoomates: "✔️ ${listRoomates()} - Guest list for room division.",
  chosenName: "✔️ ${chosenName} - Participant who requested the action in the invitation.",
  warn: ""
}

export const instructionsSP = {
  title: "Instruções",
  chooserName: "✔️ ${chooserName} - Participante que recibirá el correo electrónico.",
  listRoomates: "✔️ ${listRoomates()} - Lista de invitados para división de habitaciones.",
  chosenName: "✔️ ${chosenName} - Participante que solicitó la acción en la invitación.",
  warn: ""
}