import axios from "axios";
import { urlBaseSorteio } from "./config";
const baseUrl = urlBaseSorteio;

async function createSorteio(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "post",
    url: `${baseUrl}/sorteio`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getSorteio({ id, jwt, offset, limit, search }) {
  var config = {
    method: "get",
    url: `${baseUrl}/sorteio/${id}/event/?offset=${offset}&limit=${limit}&columnSearch=session&search=${search}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function deleteSorteio({ id, jwt }) {
  var config = {
    method: "delete",
    url: `${baseUrl}/sorteio/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function editSorteio(data) {
  var dataForm = JSON.stringify({
    quotasAmount: data.quotasAmount,
    startRegistration: data.startRegistration,
    endRegistration: data.endRegistration,
  });

  var config = {
    method: "put",
    url: `${baseUrl}/sorteio/${data.id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

/* Sorteio Participantes */

async function listParticipants({ id, jwt, offset, limit, search }) {
  var config = {
    method: "get",
    url: `${baseUrl}/sorteiologin/${id}/sorteio/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=name`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function addParticipant(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "post",
    url: `${baseUrl}/sorteiologin`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function updateParticipant(data, id, jwt) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "put",
    url: `${baseUrl}/sorteiologin/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function deleteParticipant({ id, jwt }) {
  var config = {
    method: "delete",
    url: `${baseUrl}/sorteiologin/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function sendEmailOnDeleteUser(data, jwt) {
  var config = {
    method: "post",
    url: `${baseUrl}/sorteiologin/emailondelete`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function sendEmailOnDeleteSorteio(id, jwt) {
  var config = {
    method: "post",
    url: `${baseUrl}/sorteio/sendemaildeleted/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function sendEmailToWinnerSorteio(id, jwt) {
  var config = {
    method: "post",
    url: `${baseUrl}/sorteio/sendemailprocessed/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function processSorteio(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "post",
    url: `${baseUrl}/sorteio/process/${data.id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function listWinners({ id, jwt, offset, limit, search }) {
  var config = {
    method: "get",
    url: `${baseUrl}/sorteio/drawwinners/${id}?offset=${offset}&limit=${limit}&columnSearch=name&search=${search}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  processSorteio,
  createSorteio,
  getSorteio,
  deleteSorteio,
  editSorteio,
  listParticipants,
  addParticipant,
  deleteParticipant,
  listWinners,
  updateParticipant,
  sendEmailOnDeleteUser,
  sendEmailOnDeleteSorteio,
  sendEmailToWinnerSorteio
};
