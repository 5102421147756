import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
//service
import { editFunction } from "../../../utils/services/functions";

function ModalEditFunc(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //state de erros
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [funcObjectError, setFuncObjectError] = useState(false);
  const [returnObjectError, setReturnObjectError] = useState(false);
  //confirmção de edição
  const [success_dlg, setsuccess_dlg] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    if (!e.target[1].value) {
      setNameError(true);
      return;
    } else setNameError(false);

    if (!e.target[2].value) {
      setTypeError(true);
      return;
    } else setTypeError(false);

    if (!e.target[3].value) {
      setFuncObjectError(true);
      return;
    } else setFuncObjectError(false);

    if (!e.target[4].value) {
      setReturnObjectError(true);
      return;
    } else setReturnObjectError(false);

    await editFunction({
      id: props.editFunction.id,
      description: e.target[1].value,
      functionObject: e.target[3].value,
      responseObject: e.target[4].value,
      type: e.target[2].value,
      jwt: props.state.global_user_data.data.token,
    }).then((response) => {
      setsuccess_dlg(true);
    });
    // .catch((error) => console.log(error));
  }
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={'Editado'}
          onConfirm={() => {
            props.getAllFunc();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {'Função editada com sucesso!'}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar função</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <label className="form-label">ID da Função</label>
            <input
              className="form-control"
              type="text"
              defaultValue={props.editFunction.id}
              disabled
            />
          </Row>

          <Row style={{ marginTop: 10 }}>
            <label className="form-label">Nome da Função*</label>
            <input
              className="form-control"
              type="text"
              defaultValue={props.editFunction.description}
            />
            {nameError && (
              <p style={{ color: 'red' }}>
                O campo Nome da Função é obrigatório.
              </p>
            )}
          </Row>

          <Row style={{ marginTop: 10 }}>
            <label className="form-label">Tipo da Função*</label>
            <input
              className="form-control"
              type="text"
              defaultValue={props.editFunction.type}
            />
            {typeError && (
              <p style={{ color: 'red' }}>
                O campo Tipo da Função é obrigatório.
              </p>
            )}
          </Row>

          <Row style={{ marginTop: 10 }}>
            <label className="form-label">Objeto da Função*</label>
            <textarea
              className="form-control"
              type="text"
              defaultValue={props.editFunction.functionObject}
            />
            {funcObjectError && (
              <p style={{ color: 'red' }}>
                O campo Objeto da Função é obrigatório.
              </p>
            )}
          </Row>

          <Row style={{ marginTop: 10 }}>
            <label className="form-label">Objeto de Retorno*</label>
            <textarea
              className="form-control"
              type="text"
              defaultValue={props.editFunction.responseObject}
            />
            {returnObjectError && (
              <p style={{ color: 'red' }}>
                O campo Objeto de Retorno é obrigatório.
              </p>
            )}
          </Row>

          <Row
            style={{
              marginTop: 15,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              type={'submit'}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalEditFunc);
