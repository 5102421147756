import axios from 'axios';
import { urlBase } from './config';
const baseUrl = urlBase;

async function createTemplateEmail({ data, token }) {
  var dataForm = JSON.stringify({ ...data });

  var config = {
    method: 'post',
    url: `${baseUrl}/templateemailquotas`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getTemplateEmail({
  eventId,
  token,
  offset = 1,
  limit = 10,
  search,
}) {
  var config = {
    method: 'get',
    url: `${baseUrl}/templateemailquotas/${eventId}/event/?offset=${offset}&limit=${limit}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function editTemplateEmail({ data, token }) {
  var dataForm = JSON.stringify({ ...data });

  var config = {
    method: 'put',
    url: `${baseUrl}/templateemailquotas/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { createTemplateEmail, getTemplateEmail, editTemplateEmail };
