import axios from "axios";

async function getAccess({
  eventId,
  transmissionID,
  pageSize,
  offset,
  search,
  mobileapps,
}) {
  const config = {
    method: "get",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/getallusers?eventId=${eventId}&transmissionID=${transmissionID}&pageSize=${pageSize}&offset=${offset}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getOnline({
  eventId,
  transmissionID,
  pageSize,
  offset,
  search,
  mobileapps,
}) {
  const config = {
    method: "get",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/gettransmissionusersonline?eventId=${eventId}&transmissionID=${transmissionID}&pageSize=${pageSize}&offset=${offset}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getChat({
  eventId,
  transmissionID,
  pageSize,
  offset,
  search,
  mobileapps,
}) {
  const config = {
    method: "get",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/getchattransmission?eventId=${eventId}&transmissionID=${transmissionID}&pageSize=${pageSize}&offset=${offset}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getAsk({
  eventId,
  transmissionID,
  pageSize,
  offset,
  search,
  mobileapps,
}) {
  const config = {
    method: "get",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/getaskstransmission?eventId=${eventId}&transmissionID=${transmissionID}&pageSize=${pageSize}&offset=${offset}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getSuperEmoji({
  eventId,
  transmissionID,
  pageSize,
  offset,
  search,
  mobileapps,
}) {
  const config = {
    method: "get",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/getallemojis?eventId=${eventId}&transmissionID=${transmissionID}&pageSize=${pageSize}&offset=${offset}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function disableAsk({ eventId, transmissionID, askId, mobileapps }) {
  const config = {
    method: "post",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/editasks`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      askId: askId,
      eventId: eventId,
      status: false,
      transmissionID: transmissionID,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function disableChat({ eventId, transmissionID, chatId, mobileapps }) {
  const config = {
    method: "post",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/editchat`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      chatId: chatId,
      eventId: eventId,
      status: false,
      transmissionID: transmissionID,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getWordCloudChat({ eventId, idChat, size, mobileapps }) {
  const config = {
    method: "get",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/getcloudwordschat?eventId=${eventId}&chatID=${idChat}&cloudSize=${size}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log(config.url)

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getWordCloudAsks({ eventId, transmissionID, size, mobileapps }) {
  const config = {
    method: "get",
    url: `https://us-central1-${mobileapps}.cloudfunctions.net/getcloudwordsasks?eventId=${eventId}&transmissionID=${transmissionID}&cloudSize=${size}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  getAccess,
  getOnline,
  getChat,
  getAsk,
  getSuperEmoji,
  disableAsk,
  disableChat,
  getWordCloudChat,
  getWordCloudAsks,
};
