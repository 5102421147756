import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { editEventType } from "../../utils/services/eventType";

export default function ModalEditEventType(props) {
  const {
    isOpen,
    toggle,
    editOneEventType,
    token,
    idContract,
    getListEventType,
  } = props;
  const [typeError, setTypeError] = useState("");
  const [alertSuccess, setAlertSuccess] = useState(false);

  function verifyForm(e) {
    if (!e.target[1].value) {
      setTypeError("O campo Tipo de Evento é obrigatório");
      return;
    } else setTypeError("");

    submitForm(e);
  }

  async function submitForm(e) {
    const data = {
      contractId: idContract,
      type: e.target[1].value,
      active: true,
    };
    await editEventType({
      data: data,
      jwt: token,
      id: editOneEventType.id,
    })
      .then((res) => {
        if (res.message === "updated") {
          setAlertSuccess(true);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  return (
    <>
      {alertSuccess && (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            getListEventType();
            toggle();
          }}
        >
          {"Tipo de Evento editado com sucesso!"}
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Editar Tipo de Evento</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyForm(e);
            }}
          >
            <Row>
              <Col lg={12}>
                <label className="form-label">ID</label>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={editOneEventType.id}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <label className="form-label">Tipo de Evento*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={editOneEventType.type}
                />

                {typeError && <a style={{ color: "red" }}>{typeError}</a>}
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button
                color="danger"
                className="btn btn-danger waves-effect waves-light"
                style={{ width: 80, marginRight: "1rem" }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
              >
                Salvar
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
