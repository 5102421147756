import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Button,
  Row,
  Col,
  Collapse,
} from "reactstrap";
// service
import {
  getSurveys,
  deleteSurvey,
} from "../../../../utils/services/superScreen";
// moment
import moment from "moment";
// componentes
import ModalNewSurvey from "./ModalNewSurvey";
import ModalEditSurvey from "./ModalEditSurvey";
import ReportSurvey from "./reportSurvey";
import Pagination from "../../../../components/Paginationv3";
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../../components/SearchFilters";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";

function ModalSurvey(props) {
  const { isOpen, toggle } = props;
  // modais
  const [modalNewSurvey, setModalNewSurvey] = useState(false);
  const [modalEditSurvey, setModalEditSurvey] = useState(false);
  const [reportSurvey, setReportSurvey] = useState(false);
  // alerta
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
  // lista de enquentes
  const [allSurvey, setAllSurvey] = useState([]);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  //pesquisa
  const [search, setSearch] = useState("");
  // ações
  const [deleteSurveyData, setDeleteSurveyData] = useState({});
  const [editSurvey, setEditSurvey] = useState({});
  const [editable, setEditable] = useState(false);
  const [idReportSurvey, setIdReportSurvey] = useState("");
  // filtros
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("question");
  // modal collapse
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);

  const columnsFilter = [
    { name: "Pergunta", value: "question", type: "text" },
    { name: "Tipo", value: "tipo", type: "text" },
    { name: "Resposta certa", value: "rightAnswer", type: "text" },
    { name: "Pontuação", value: "hasScore", type: "text" },
    {
      name: "Data do agendamento / envio",
      value: "dateSurvey",
      type: "date",
    },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return search;
  }

  const reverseDate = () => {
    const reverse = search.split("/").reverse().join("/").replace(/\//g, "-");
    return reverse;
  };

  function formatDate(date) {
    const dateFormat = date.split("T")[0].split("-").reverse().join("/");
    const time = date.split("T")[1].slice(0, 5);
    return `${dateFormat} - ${time}`;
  }

  function getAllSurvey() {
    getSurveys(
      props.idEvent,
      props.idSuperScreen,
      currentIndex,
      itemsPerPage,
      treatmentSearch(),
      activeFilter,
      props.state.global_user_data.data.token
    ).then((res) => {
      if (res.message === "findAll") {
        setCountData(res.findAllTransmissionSurveyData.count);
        setAllSurvey(res.findAllTransmissionSurveyData.data);
      }
    });
  }

  function deleteSurveyFunc() {
    deleteSurvey(
      deleteSurveyData.id,
      props.state.global_user_data.data.token
    ).then((res) => {
      if (res.message === "DELETED_TRANSMISSION_SURVEY") {
        getAllSurvey();
        setConfirmDeleteAlert(true);
      }
    });
    // .catch((error) => console.log(error));
  }

  useEffect(() => {
    getAllSurvey();
  }, [search, currentIndex, itemsPerPage, activeFilter]);

  return (
    <Modal isOpen={isOpen} autoFocus={true} centered={true} size="xl">
      {modalNewSurvey && (
        <ModalNewSurvey
          isOpen={modalNewSurvey}
          toggle={() => setModalNewSurvey(!modalNewSurvey)}
          idEvent={props.idEvent}
          idSuperScreen={props.idSuperScreen}
          getAllSurvey={getAllSurvey}
        />
      )}

      {modalEditSurvey && (
        <ModalEditSurvey
          isOpen={modalEditSurvey}
          toggle={() => setModalEditSurvey(!modalEditSurvey)}
          editSurvey={editSurvey}
          idEvent={props.idEvent}
          idSuperScreen={props.idSuperScreen}
          getAllSurvey={getAllSurvey}
          editable={editable}
        />
      )}

      {reportSurvey && (
        <Modal isOpen={reportSurvey} autoFocus centered size="lg">
          <ModalHeader toggle={() => setReportSurvey(!reportSurvey)}>
            Relatório de respostas
          </ModalHeader>
          <ModalBody>
            <ReportSurvey
              idEvent={props.idEvent}
              idSuperScreen={props.idSuperScreen}
              idReportSurvey={idReportSurvey}
            />
          </ModalBody>
        </Modal>
      )}

      {deleteAlert ? (
        <SweetAlert
          title={`Deseja deletar a enquete ${deleteSurveyData.question}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteSurveyFunc();
            setDeleteAlert(false);
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {confirmDeleteAlert ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setConfirmDeleteAlert(false);
          }}
        >
          Enquete deletada com sucesso
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Enquetes</ModalHeader>
      <ModalBody>
        <div>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              width: "50px",
            }}
          >
            <SectionComponent
              sectionName="Filtros"
              setIsOpen={setCollapseIsOpen}
              isOpen={collapseIsOpen}
            />
          </Row>
          <Collapse isOpen={collapseIsOpen}>
            <SearchFilters
              columns={columnsFilter}
              activeFilter={activeFilter}
              setActiveFilterHandle={setActiveFilterHandle}
            />
          </Collapse>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 10,
            alignItems: "center",
          }}
        >
          <form
            className="app-search d-none d-lg-block events-input-search"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Pesquisar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="bx bx-search-alt" />
            </div>
          </form>

          <div className="mobile-btn-section" style={{ width: "100%" }}>
            <Row className="mobile-btns-top">
              <form
                className="app-search"
                style={{ padding: "0px" }}
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="position-relative">
                  <input
                    type="text"
                    value={search}
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
            </Row>
            <SectionComponent
              sectionName="Opções"
              setIsOpen={setIsOpenCollapse}
              isOpen={isOpenCollapse}
            />
            <Collapse isOpen={isOpenCollapse}>
              <Row className="mobile-btns-top">
                <div onClick={() => setModalNewSurvey(true)}>Nova Enquete</div>
              </Row>
            </Collapse>
          </div>

          <div className="header-btns-container">
            <Button
              color="success"
              className="btn-rounded waves-effect waves-light events-button-add"
              onClick={() => setModalNewSurvey(true)}
            >
              Nova Enquete
            </Button>
          </div>
        </div>

        <div className="table-responsive mt-2 mb-3">
          <Table className="table mb-0">
            <thead className="table-light">
              <tr>
                <th>ID</th>
                <th>Pergunta</th>
                <th>Tipo</th>
                <th>Resposta certa</th>
                <th>Pontuação</th>
                <th>Data do agendamento / envio</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {allSurvey.map((e, i) => (
                <tr key={i}>
                  <td>{e.id}</td>
                  <td>{e.question}</td>
                  <td>{e.tipo}</td>
                  <td>{e.rightAnswer ? "Sim" : "Não"}</td>
                  <td>{e.hasScore ? "Sim" : "Não"}</td>
                  <td>{formatDate(e.dateSurvey)}</td>
                  <td>
                    <td>
                      <i
                        className="mdi mdi-chart-bar-stacked"
                        style={{
                          color: "blue",
                          fontSize: 17,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIdReportSurvey(e.id);
                          setReportSurvey(true);
                        }}
                      />
                      <i
                        className="mdi mdi-pencil"
                        style={{
                          color: "green",
                          fontSize: 17,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditable(
                            moment.utc(e.dateSurvey).format() >
                              moment(new Date()).format()
                          );
                          setEditSurvey(e);
                          setModalEditSurvey(true);
                        }}
                      />
                      {moment.utc(e.dateSurvey).format() >
                      moment(new Date()).format() ? (
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDeleteSurveyData(e);
                            setDeleteAlert(true);
                          }}
                        />
                      ) : (
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "gray",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          data={allSurvey}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </ModalBody>
      {/* <Row>
        <Col md={12}>
          <Pagination
            data={allSurvey}
            search={search}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            countData={countData}
            maxItemPerPage={itemsPerPage}
            setMaxItemPerPage={setItemsPerPage}
            maxPageButtonsToShow={3}
          />
        </Col>
      </Row> */}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalSurvey);
