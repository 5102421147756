import axios from "axios";
import { urlBaseFiles } from "./config/index";

function getUrlFiles(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBaseFiles}/files`,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function deleteFile (id, jwt) {
  var config = {
    method: "delete",
    url: `${urlBaseFiles}/files/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { getUrlFiles, deleteFile };
