import writeXlsxFile from "write-excel-file";
import { getQuotasXlsx } from "../../../utils/services/quotas";
import { formatData } from "../../../utils/helpers/files/formatDate";

export async function createXlsxQuota({
  jwt,
  idEvent,
  limit,
  countData,
  setFinishExport,
}) {
  const formatColumn = (col) => {
    return col ? formatData(col) : "";
  };

  const formatDate = (date) => {
    return date.split('T')[0].split('-').reverse().join('-');
  };
  const schema = [
    {
      column: 'ID',
      type: String,
      value: (participant) => participant.login,
    },
    {
      column: 'Nome',
      type: String,
      value: (participant) => participant.nome,
    },
    {
      column: 'Email',
      type: String,
      value: (participant) => participant.email,
    },
    {
      column: 'ID da Sessão',
      type: String,
      value: (participant) => participant.session,
    },
    {
      column: 'Descrição da Sessão',
      type: String,
      value: (participant) =>
        participant.title +
        ', ' +
        formatDate(participant.startdate) +
        ' ' +
        participant.starthour +
        (participant.endhour ? '-' : '') +
        participant.endhour,
    },
    {
      column: 'Quantidade de Acompanhantes',
      type: Number,
      value: (participant) => participant.companionsnumber,
    },
    {
      column:
        'Você ou seu(s) acompanhante(s) possuem restrição de acessibilidade?',
      type: String,
      value: (participant) =>
        participant.accessibilityrestriction ? 'Sim' : 'Não',
    },
    {
      column: 'Qual?',
      type: String,
      value: (participant) =>
        participant.typerestriction ? participant.typerestriction : '',
    },
    {
      column: 'Observações',
      type: String,
      value: (participant) => (participant.notes ? participant.notes : ''),
    },
    {
      column: 'Empresa',
      type: String,
      value: (participant) => participant.empresa,
    },
    {
      column: 'Cargo',
      type: String,
      value: (participant) => participant.cargo,
    },
    {
      column: 'Código Interno 1',
      type: String,
      value: (participant) => participant.codigointerno1,
    },
    {
      column: 'Código Interno 2',
      type: String,
      value: (participant) => participant.codigointerno2,
    },
    {
      column: 'Código Interno 3',
      type: String,
      value: (participant) => participant.codigointerno3,
    },
    {
      column: 'Grupos/IDs',
      type: String,
      value: (participant) =>
        participant.groups
          .map((e) => {
            return e.grupoid;
          })
          .join(', '),
    },
    {
      column: 'Grupos/Descrição',
      type: String,
      value: (participant) =>
        participant.groups
          .map((e) => {
            return e.gruponame;
          })
          .join(', '),
    },
    {
      column: 'Data de Cadastro',
      type: String,
      value: (participant) => formatColumn(participant.criadoem),
    },
    {
      column: 'Primeiro Acesso',
      type: String,
      value: (participant) => formatColumn(participant.firstaccess),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getQuotasXlsx({
      jwt,
      offset: i,
      eventId: idEvent,
      limit,
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.data.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "participantsQuotas.xlsx",
    });
  }
}
