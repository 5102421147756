import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  Input,
} from "reactstrap";

import Select from "react-select";
//redux
import { connect } from "react-redux";
//useMutation
import { useMutation } from "react-query";
//react router dom
import { useLocation } from "react-router-dom";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//service
import { getGroups } from "../../../utils/services/group";
import { editSchedule } from "../../../utils/services/schedule";
import { getUrlFiles } from "../../../utils/services/files";
import {
  listScheduleQuotas,
  updateScheduleQuotas,
  getListSessions,
} from "../../../utils/services/scheduleCotas";

import ColorPicker from "../../../components/ColorPicker/ColorPicker";

function EditSchedule(props) {
  const { isOpen, toggle } = props;
  const location = useLocation();

  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  //state de foto
  const [photo, setPhoto] = useState(null);
  const [expires, setExpires] = useState(null);
  //state de data
  const [dateStart, setDateStart] = useState("");
  //state da img
  const [imageView, setImageView] = useState(props.schedule.picture);
  //state de erros
  const [titleError, setTitleError] = useState("");
  const [dateError, setDateError] = useState("");
  const [quotasError, setQuotasError] = useState("");
  // state cor da programação
  const [backgroundColor, setBackgroundColor] = useState(props.schedule.color);

  const [amountQuotas, setAmountQuotas] = useState(0);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }
  const isComGas = () => checkProductExists("Distribuição de Cotas");
  const isApp = () => checkProductExists("APP Eventos Plus");
  const isRsvp = () => checkProductExists("RSVP");

  function handleGroupMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  const onImageChange = (event, setStateFunction) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setStateFunction(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  async function funcPhoto(e) {
    var infoPhoto = null;
    if (isApp() || isRsvp()) {
      if (e.target[6].files[0]) {
        var data = new FormData();
        data.append("files", e.target[6].files[0]);
        data.append("folder", "agenda");
        data.append("eventId", props.idEvent);

        await getUrlFiles(data, props.state.global_user_data.data.token)
          .then((response) => {
            const { url, expires } = response[0];
            setPhoto(url);
            setExpires(new Date(expires));
            infoPhoto = expires;
          })
          .catch((error) => {
            // console.log(error);
          });
      } else infoPhoto = props.schedule.expireDate;
    }
    submitForm.mutate({ e: e, infoPhoto: infoPhoto });
  }

  function formatDateTime(date, time) {
    const timeToStartDate = time?.value ? time : "00:01";
    return `${date}T${timeToStartDate}`;
  }

  async function getAvailableQuotas() {
    const res = await getListSessions(
      props.idEvent,
      props.state.global_user_data.data.token
    );
    const agendaAvailables = res.data.cotasManuais;

    if (agendaAvailables) {
      const pickAgenda = agendaAvailables.filter(
        (e) => e.agendaid === props.schedule.id
      )[0];

      if (pickAgenda) {
        const usedManual = parseInt(pickAgenda.totalmanuais);
        const usedFechadas = parseInt(props.schedule.agendaCota.cotasFechadas);
        const usedSorteio = parseInt(props.schedule.agendaCota.cotasSorteio);
        let total = usedManual + usedFechadas + usedSorteio;
        total = total >= 1 ? total : 1;

        if (amountQuotas < total) {
          return { total, status: true };
        }
      }
      return { status: false };
    }
  }

  const submitForm = useMutation(
    async (e) => {
      if (e.e.target[1].value === "") {
        setTitleError("O campo Titulo é obrigatório.");
        return;
      } else setTitleError("");

      const iStartDate = isApp() || isRsvp() ? 3 : 2;

      if (e.e.target[iStartDate].value === "") {
        setDateError("O campo Data é obrigatório.");
        return;
      } else setDateError("");

      if (checkProductExists("Distribuição de Cotas") && !amountQuotas) {
        setQuotasError("O campo Quantidade de Cotas é obrigatório");
        return;
      } else setQuotasError("");

      if (checkProductExists("Distribuição de Cotas")) {
        const checkMin = await getAvailableQuotas();
        if (checkMin.status) {
          setQuotasError(
            `A quantidade de cotas informada é menor do que o valor de cotas já distribuídas. A quantidade precisa ser maior ou igual a ${checkMin.total}`
          );
          return;
        } else setQuotasError("");
      }

      var groupsArray = [];

      if (allGroups) {
        groups.forEach((e) => groupsArray.push(e.id));
      } else {
        groupsMulti.forEach((e) => groupsArray.push(e.value));
      }
      let foto = null;

      if (isApp() || isRsvp()) {
        if (imageView === props.schedule.picture) {
          foto = imageView;
        } else {
          foto = photo;
        }
      }
      //indexes
      const iStartHour = isApp() || isRsvp() ? 4 : 3;
      const iEndHour = isApp() || isRsvp() ? 5 : 4;

      // values,
      const descriptionValue = isApp() || isRsvp() ? e.e.target[2]?.value : "";

      const typeOrLocationValue =
        isApp() || isRsvp() ? e.e.target[7]?.value : "";

      return await editSchedule({
        eventId: props.idEvent,
        startDate: formatDateTime(
          e.e.target[iStartDate].value,
          e.e.target[iStartHour].value
        ),
        startHour: e.e.target[iStartHour].value,
        endHour: e.e.target[iEndHour].value,
        title: e.e.target[1].value,
        description: descriptionValue,
        typeOrLocation: typeOrLocationValue,
        color: backgroundColor,
        picture: foto,
        expireDate: e.infoPhoto,
        groups: groupsArray,
        jwt: props.state.global_user_data.data.token,
        id: props.schedule.id,
      });
    },
    {
      onSuccess: async (data) => {
        const { message } = data;
        if (message === "AGENDA_UPDATED") {
          if (isComGas()) {
            await updateScheduleQuotas({
              id: data.agenda.id,
              qtdCotas: amountQuotas,
              jwt: props.state.global_user_data.data.token,
            });
          }
          setsuccess_dlg(true);
        }
      },

      onError: (error) => {
        // console.log("error", error);
      },
    }
  );

  async function formatData(e) {
    let result = e.split("T");
    setDateStart(result[0]);
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function funcGroups() {
    var groupsArray = [];
    if (groups?.length > 0) {
      props.schedule.groups.map((a) => {
        groups.map((e) => {
          if (e.id === a.grupoId) {
            groupsArray.push({ label: e.name, value: e.id });
          }
        });
      });
      if (groups?.length === groupsArray.length) {
        groupsArray = [];
        groupsArray.push({ label: "TODOS", value: "todos" });
        setAllGroups(true);
      }
    }
    setGroupsMulti(groupsArray);
  }

  async function getListScheduleQuotas() {
    await listScheduleQuotas({
      id: props.schedule.id,
      event: props.idEvent,
      jwt: props.state.global_user_data.data.token,
    })
      .then((response) => {
        if (Object.entries(response.data) !== 0) {
          setAmountQuotas(response.data.agendaCota.qtdCotas);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getGroupsFunc();
    formatData(props.schedule.startDate);
    getListScheduleQuotas();
  }, [isOpen]);

  useEffect(() => {
    funcGroups();
  }, [groups]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.data[0].refetch();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Programação editada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Programação</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            funcPhoto(e);
            // submitForm.mutate(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">ID da Agenda</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.schedule.id}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Titulo*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.schedule.title}
                />
                {titleError && <p style={{ color: "red" }}>{titleError}</p>}
              </div>
            </Col>
          </Row>

          {(isApp() || isRsvp()) && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Descrição</label>
                  <textarea
                    className="form-control"
                    type="text"
                    defaultValue={props.schedule.description}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Data da Programação*</label>
                <input
                  className="form-control"
                  type="date"
                  id="example-datetime-local-input"
                  defaultValue={dateStart}
                />
                {dateError && <p style={{ color: "red" }}>{dateError}</p>}
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora Início</label>
                <input
                  className="form-control"
                  type="time"
                  id="start-datetime-local-input"
                  defaultValue={props.schedule.startHour}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora Fim</label>
                <input
                  className="form-control"
                  type="time"
                  id="end-datetime-local-input"
                  defaultValue={props.schedule.endHour}
                />
              </div>
            </Col>
          </Row>

          {(isApp() || isRsvp()) && (
            <Row style={{ marginTop: 10 }}>
              <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
                <label
                  htmlFor="formFile"
                  className="form-label"
                  style={{ marginBottom: 0 }}
                >
                  Foto
                </label>
                <label htmlFor="inputImage" style={{ marginBottom: 0 }}>
                  <i className="bx bx-pencil" style={{ cursor: "pointer" }} />
                </label>

                <input
                  id="inputImage"
                  type={"file"}
                  onChange={(e) => {
                    onImageChange(e, setImageView);
                  }}
                  style={{ display: "none" }}
                />
              </Col>

              <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
                {imageView && (
                  <img alt="edit" src={imageView} style={{ width: 70 }} />
                )}
              </Col>
            </Row>
          )}

          {(isApp() || isRsvp()) && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">
                    Tipo/Local da Programação
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={props.schedule.typeOrLocation}
                  />
                </div>
              </Col>
            </Row>
          )}

          {(isApp() || isRsvp()) && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <ColorPicker
                  classOfContainer="bg-edit-shedule-event-pickr"
                  labelText="Cor da Programação"
                  setStateFunc={setBackgroundColor}
                  defaultColor={backgroundColor}
                />
              </Col>
            </Row>
          )}

          {isComGas() && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Quantidade de Cotas*</label>
                  <input
                    className="form-control"
                    type="number"
                    value={amountQuotas}
                    onChange={(e) => setAmountQuotas(e.target.value)}
                    min={1}
                  />
                  {quotasError && <p style={{ color: "red" }}>{quotasError}</p>}
                </div>
              </Col>
            </Row>
          )}

          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Grupos</label>
                <Select
                  value={groupsMulti}
                  placeholder={"Selecione"}
                  isMulti={true}
                  onChange={(e) => {
                    handleGroupMulti(e);
                  }}
                  options={[
                    {
                      options: [
                        { label: "TODOS", value: "todos" },
                        ...groups.map((e) => {
                          return { label: e.name, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "20px auto 0 auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EditSchedule);
