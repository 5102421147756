
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { instructionTooltipAgenda } from './core';

export const TooltipAgendaModal = ({ toggle, modal, type }) => {

  const { participante, sessionDate, sessionHour, sessionName, adresSession, totalTickets } = instructionTooltipAgenda

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Modal style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "200px" }} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Instruções</ModalHeader>
        <ModalBody>
          <span>{participante}</span> <br />
          <span>{sessionName}</span> <br />
          <span>{sessionDate}</span> <br />
          <span>{sessionHour}</span> <br />
          {type !== "NS" && (
            <>
              <span>{totalTickets}</span> <br />
              <span>{adresSession}</span>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger">Fechar</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}