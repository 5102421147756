import React, { useState } from "react";
import MetaTags from "react-meta-tags";
// Redux
import { Link } from "react-router-dom";

//tradutor
import { withTranslation } from "react-i18next";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

// import images
import logo from "../assets/image/logoLogin.png";
import profile from "../assets/image/icon.png";

//componentes
import LoginComponent from "../components/Login";
import FirstAcess from "../components/FirstAccess";
import ForgotPassword from "../components/ForgotPassword";

const LoginPage = (props) => {
  const [screen, setScreen] = useState("_a");

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Inteegra Plus</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5" style={{ paddingTop: 40 }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      {screen === "_a" && (
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Bem-vindo!</h5>
                          <p>Entre com seu login e senha para continuar.</p>
                        </div>
                      )}

                      {screen === "_b" && (
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Primeiro Acesso</h5>
                          <p>
                            Digite o seu email e aperte o botão "Gerar Token"
                            para criar a sua primeira senha.
                          </p>
                        </div>
                      )}

                      {screen === "_c" && (
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Esqueceu a senha?</h5>
                          <p>
                            Digite o seu email e aperte o botão "Gerar Token"
                            para recuperar a senha.
                          </p>
                        </div>
                      )}
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={profile}
                            alt=""
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  {screen === "_a" && (
                    <LoginComponent
                      screen={screen}
                      setScreen={setScreen}
                      history={props.history}
                    />
                  )}

                  {screen === "_b" && (
                    <FirstAcess
                      screen={screen}
                      setScreen={setScreen}
                      history={props.history}
                    />
                  )}

                  {screen === "_c" && (
                    <ForgotPassword
                      screen={screen}
                      setScreen={setScreen}
                      history={props.history}
                    />
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Inteegra Plus. Feito com{" "}
                  <i className="mdi mdi-heart text-danger" /> pela Inteegra
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(LoginPage);
