import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { createRoom, getMatchNoRoom } from "../../../utils/services/match";
//redux
import { connect } from "react-redux";

function ModalCreateMatch(props) {
  const { isOpen, toggle, data, eventId } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [typeRoom, setTypeRoom] = useState("");
  const [roommate1, setRoommate1] = useState({});
  const [roommate2, setRoommate2] = useState("");
  const [roommate3, setRoommate3] = useState("");
  const [roommate4, setRoommate4] = useState("");

  const [roommatesAvailable, setRoommatesAvailable] = useState([]);

  const [isSendable, setIsSendable] = useState(false);

  const [showAlertSuccess, setShowAlertSuccess] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function getAllMatchNoRoom() {
    getMatchNoRoom({
      eventId: eventId,
      limit: 10000,
      offset: 1,
      jwt: props.state.global_user_data.data.token,
    }).then((res) => {
      setRoommatesAvailable(res.data);
    });
  }

  useEffect(() => {
    getAllMatchNoRoom();
  }, []);

  useEffect(() => {
    setIsSendable(false);
    if (typeRoom === "Duplo") {
      if (roommate1?.login_id && roommate2?.login_id) {
        setIsSendable(true);
      }
    }
    if (typeRoom === "Triplo") {
      if (roommate1.login_id && roommate2.login_id && roommate3.login_id) {
        setIsSendable(true);
      }
    }
    if (typeRoom === "Quadruplo") {
      if (
        roommate1.login_id &&
        roommate2.login_id &&
        roommate3.login_id &&
        roommate4.login_id
      ) {
        setIsSendable(true);
      }
    }
  }, [
    roommate1.login_id,
    roommate2.login_id,
    roommate3.login_id,
    roommate4.login_id,
    typeRoom,
  ]);

  function isShowRoommate(roommate) {
    if (typeRoom === "Duplo") {
      return [1, 2].includes(roommate);
    }
    if (typeRoom === "Triplo") {
      return [1, 2, 3].includes(roommate);
    }
    if (typeRoom === "Quadruplo") {
      return [1, 2, 3, 4].includes(roommate);
    }
  }

  function getStockRooms() {
    let options = [];
    data.map((roomSize) =>
      roomSize.disponivel > 0
        ? options.push(
            <option value={roomSize.descricao}>{roomSize.descricao}</option>
          )
        : ""
    );
    return options;
  }
  function clearRoommates() {
    setRoommate1({});
    setRoommate2({});
    setRoommate3({});
    setRoommate4({});
  }

  function createRoommateInfo(email, id, name, order, status, type) {
    return {
      email,
      id,
      name,
      order,
      status,
      type,
    };
  }

  function generateChoosenList(roommates, roomSize) {
    const choosenList = [];

    for (let i = 1; i <= roomSize; i++) {
      const roommate = roommates[i - 1];

      choosenList.push(
        createRoommateInfo(
          roommate.login_email,
          roommate.login_id,
          roommate.login_nome,
          i,
          "completed",
          // i === 1 ? 'chooser' : 'choosen'
          "chooser"
        )
      );
    }

    return choosenList;
  }

  async function onConfirmMatch() {
    setIsSendable(false);
    const roomSize = typeRoom === "Duplo" ? 2 : typeRoom === "Triplo" ? 3 : 4;

    const choosenList = generateChoosenList(
      [roommate1, roommate2, roommate3, roommate4],
      roomSize
    );

    const payload = {
      choosens: [choosenList],
      roommateId: roommate1.login_id,
      eventId,
      roomSize,
      status: "completed",
      enabled: true,
      idiom: "ptBr",
      sendEmail: false,
    };

    const res = await createRoom(
      payload,
      props.state.global_user_data.data.token
    );

    if (res.message === "SENDED_TO_QUEUE") {
      setShowAlertSuccess(true);
    }
  }

  useEffect(() => {
    console.log(roommatesAvailable.map(item => item.login_nome), roommate1)
  }, [roommatesAvailable, roommate1])

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {showAlertSuccess ? (
        <SweetAlert
          success
          title={"Hospedagem"}
          onConfirm={() => {
            setShowAlertSuccess(false);
            toggle();
            props.updateTables();
          }}
        >
          {"Hospedagem criada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Match de hospedagem</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            setIsSendable(false);
            onConfirmMatch();
            e.preventDefault();
          }}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Tipo de Quarto:*</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    clearRoommates();
                    setTypeRoom(e.target.value);
                  }}
                >
                  <option>
                    {getStockRooms().length > 0
                      ? "Selecionar"
                      : "Quartos indisponíveis"}
                  </option>
                  {getStockRooms()}
                </select>
              </div>
            </Col>
          </Row>
          {isShowRoommate(1) && (
            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Hóspede 1:*</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setRoommate1(JSON.parse(e.target.value));
                      setRoommate2({});
                      setRoommate3({});
                      setRoommate4({});
                    }}
                    value={JSON.stringify(roommate1)}
                  >
                    <option value={JSON.stringify({})}>Selecione</option>
                    {roommatesAvailable &&
                      roommatesAvailable
                      .sort(function (userA, userB) {
                      const nomeA = userA.login_nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                      const nomeB = userB.login_nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                      if (nomeA < nomeB) return -1;
                      if (nomeA > nomeB) return 1;
                        return 0;
                    })
                    .map((e, i) => (
                    <option key={i} value={JSON.stringify(e)}>
                      {e.login_nome}
                    </option>
                    ))}
                  </select>
                </div>
              </Col>

              {/* <div className=''>
                <input
                  // value={search}
                  type='text'
                  className='form-control'
                  placeholder='Pesquisar...'
                  // onChange={(e) => setSearch(e.target.value)}
                />
                <span className='bx bx-search-alt' />
              </div> */}
            </Row>
          )}
          {isShowRoommate(2) && (
            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Hóspede 2:*</label>
                  <select
                    disabled={!roommate1?.login_id?.length}
                    className="form-control"
                    onChange={(e) => {
                      setRoommate2(JSON.parse(e.target.value));
                      setRoommate3({});
                      setRoommate4({});
                    }}
                    value={JSON.stringify(roommate2)}
                  >
                    <option value={JSON.stringify({})}>Selecione</option>
                    {roommate1 &&
                      roommatesAvailable &&
                      roommatesAvailable
                      .sort(function (userA, userB) {
                      const nomeA = userA.login_nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                      const nomeB = userB.login_nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                      if (nomeA < nomeB) return -1;
                      if (nomeA > nomeB) return 1;
                      return 0;
                    })
                      .filter((f) => f.login_id !== roommate1.login_id)
                      .map((e, i) => (
                      <option key={i} value={JSON.stringify(e)}>
                        {e.login_nome}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
          )}
          {isShowRoommate(3) && (
            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Hóspede 3:*</label>
                  <select
                    disabled={!roommate2?.login_id?.length}
                    className="form-control"
                    onChange={(e) => {
                      setRoommate3(JSON.parse(e.target.value));
                      setRoommate4({});
                    }}
                    value={JSON.stringify(roommate3)}
                  >
                    <option value={JSON.stringify({})}>Selecione</option>
                    {roommate2 &&
                      roommatesAvailable &&
                      roommatesAvailable
                      .filter(
                      (f) =>
                        f.login_id !== roommate1.login_id &&
                        f.login_id !== roommate2.login_id
                    )
                    .sort(function (userA, userB) {
                      const nomeA = userA.login_nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                      const nomeB = userB.login_nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                      if (nomeA < nomeB) return -1;
                      if (nomeA > nomeB) return 1;
                      return 0;
                    })
                    .map((e, i) => (
                      <option key={i} value={JSON.stringify(e)}>
                        {e.login_nome}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
          )}
          {isShowRoommate(4) && (
            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Hóspede 4:*</label>
                  <select
                    disabled={!roommate3?.login_id?.length}
                    className="form-control"
                    onChange={(e) => {
                      setRoommate4(JSON.parse(e.target.value));
                    }}
                    value={JSON.stringify(roommate4)}
                  >
                    <option value="">Selecione</option>
                    {roommate3 &&
                      roommatesAvailable &&
                      roommatesAvailable
                    .filter(
                      (f) =>
                        f.login_id !== roommate1.login_id &&
                        f.login_id !== roommate2.login_id &&
                        f.login_id !== roommate3.login_id
                    )
                    .sort(function (userA, userB) {
                      const nomeA = userA.login_nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                      const nomeB = userB.login_nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                      if (nomeA < nomeB) return -1;
                      if (nomeA > nomeB) return 1;
                      return 0;
                    })
                    .map((e, i) => (
                      <option key={i} value={JSON.stringify(e)}>
                        {e.login_nome}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
          )}
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 10,
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              type="submit"
              disabled={!isSendable}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalCreateMatch);
