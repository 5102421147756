import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createApp } from "../../utils/services/mobileApps";
import { getFirebaseProjects } from "../../utils/services/firebaseProjects";
import { getMenusDefault } from './menuDefault';

export default function NewApps(props) {
  const { isOpen, toggle, refetch, contractId } = props;
  const { token } = useSelector((state) => state.global_user_data.data);
  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [app, setApp] = useState('');
  const [bundleId, setBundleId] = useState('');
  const [termoDeAcesso, setTermoDeAcesso] = useState('');
  const [telaPrincipal, setTelaPrincipal] = useState('');

  //validações campos formulários
  const [appError, setAppError] = useState(false);
  const [bundleIdError, setBundleIdError] = useState(false);
  const [idFirebaseProjectError, setIdFirebaseProjectError] = useState(false);

  //cadastrado com sucesso
  const [success, setSuccess] = useState(false);

  const [firebaseProjects, setFirebaseProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  // state options
  const [options, setOptions] = useState([]);
  const [code, setCode] = useState('Selecione');
  const [config, setConfig] = useState([]);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setApp('');
    setBundleId('');

    setAppError(false);
    setBundleIdError(false);
    setIdFirebaseProjectError(false);
  }

   const getCode = (value) => {
     switch (value) {
       case 'Geral':
         return 'GERAL';
       case 'Agenda':
         return 'AGENDA';
       case 'Feed':
         return 'FEED';
       case 'Localização':
         return 'LOCALIZATION';
       case 'Logística':
         return 'LOGISTICA';
       case 'Ranking':
         return 'RANKING';
       case 'Convidados':
         return 'CONVIDADOS';
       case 'Networking':
         return 'NETWORKING';
       case 'Notes':
         return 'NOTES';

       default:
         return '';
     }
   };
   async function submitForm() {
     if (app === '') {
       return setAppError(true);
     } else setAppError(false);

     if (!selectedProject || selectedProject === 'Selecione') {
       return setIdFirebaseProjectError(true);
     } else setIdFirebaseProjectError(false);

     if (bundleId === '') {
       return setBundleIdError(true);
     } else setBundleIdError(false);

     return createApp({
       jwt: token,
       name: app,
       bundleId,
       idFirebaseProject: selectedProject,
       contractId,
       termoDeAcesso: termoDeAcesso,
       telaPrincipal:
         telaPrincipal === 'Selecione' ? '' : getCode(telaPrincipal),
     })
       .then((data) => {
         setSuccess(true);
         resetFields();
       })
       .then(() => refetch())
       .catch((error) => {
         // console.log({ error });
       });
   }

   function getMenus() {
     const newOptions = getMenusDefault();
     setOptions([...newOptions]);
   }
   useEffect(() => {
     if (isOpen) {
       getMenus();
       getFBProjects();
       return;
     }
     setSelectedProject(null);
     setSelectedProject(null);
   }, [isOpen]);

   function getFBProjects() {
     getFirebaseProjects({ jwt: token }).then((res) => {
       setFirebaseProjects(res.findAll.data);
     });
   }

   return (
     <Modal
       size="lg"
       isOpen={isOpen}
       toggle={() => {
         toggleBackdrop();
       }}
       onClosed={() => {
         resetFields();
       }}
       autoFocus={true}
       centered={true}
     >
       {success && (
         <SweetAlert
           success
           title="Cadastro"
           onConfirm={() => {
             setSuccess(false);
             toggle();
           }}
         >
           App cadastrado com sucesso!
         </SweetAlert>
       )}
       <ModalHeader toggle={toggle}>Novo App</ModalHeader>
       <ModalBody>
         <form>
           <div>
             <Row style={{ marginTop: 10 }}>
               <Col>
                 <label className="form-label">Nome do Aplicativo*</label>
                 <input
                   className="form-control"
                   type="text"
                   onChange={(e) => setApp(e.target.value)}
                 />
                 {appError && (
                   <p style={{ color: 'red' }}>
                     O campo Nome do Aplicativo é obrigatório.
                   </p>
                 )}
               </Col>
             </Row>
           </div>
           <div>
             <Row style={{ marginTop: 10 }}>
               <Col>
                 <label className="form-label">ID do Projeto (Firebase)*</label>
                 <select
                   className="form-control"
                   onChange={(e) => {
                     setSelectedProject(e.target.value);
                   }}
                 >
                   <option>Selecione</option>
                   {firebaseProjects &&
                     firebaseProjects?.map((e, i) => (
                       <option key={i}>{e.name}</option>
                     ))}
                 </select>
                 {idFirebaseProjectError && (
                   <p style={{ color: 'red' }}>
                     O campo ID do Projeto (Firebase) é obrigatório.
                   </p>
                 )}
               </Col>
             </Row>
           </div>

           <div>
             <Row style={{ marginTop: 10 }}>
               <Col>
                 <label className="form-label">Bundle ID*</label>
                 <input
                   className="form-control"
                   type="text"
                   onChange={(e) => setBundleId(e.target.value)}
                 />
                 {bundleIdError && (
                   <p style={{ color: 'red' }}>
                     O campo Bundle ID é obrigatório.
                   </p>
                 )}
               </Col>
             </Row>
           </div>

           <div>
             <Row style={{ marginTop: 10 }}>
               <Col>
                 <label className="form-label">Termo de Acesso</label>
                 <input
                   className="form-control"
                   type="text"
                   onChange={(e) => setTermoDeAcesso(e.target.value)}
                 />
               </Col>
             </Row>
           </div>

           <Row style={{ marginTop: 10, marginBottom: 20 }}>
             <Col lg={12}>
               <label className="form-label">Tela Principal</label>
               <select
                 className="form-control"
                 onChange={({ target }) => setTelaPrincipal(target.value)}
               >
                 {/* <option>Selecione</option> */}
                 {/* <option>Geral</option> */}
                 {options &&
                   options
                     .filter(
                       (e) =>
                         !config.find(
                           (a) => a.code === e.option || a.code === e.code
                         )
                     )
                     .map((e, i) => (
                       <option key={i}>{e?.describe || e.option}</option>
                     ))}
               </select>
             </Col>
           </Row>
           <Row
             style={{
               width: '100%',
               marginTop: 15,
               display: 'flex',
               justifyContent: 'flex-end',
             }}
           >
             <Button
               color="success"
               className="btn btn-danger waves-effect waves-light"
               style={{ width: 80, marginRight: 5 }}
               onClick={() => toggle()}
             >
               Fechar
             </Button>
             <Button
               color="success"
               className="btn btn-success waves-effect waves-light"
               style={{ width: 80 }}
               onClick={submitForm}
             >
               Salvar
             </Button>
           </Row>
         </form>
       </ModalBody>
     </Modal>
   );
}
