import axios from "axios";
import { urlBase } from "./config/index";



async function getFirebaseProjects({ jwt }) {
  var config = {
    method: "get",
    url: `${urlBase}/firebase-projects`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}



export { getFirebaseProjects };
