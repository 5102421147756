import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from 'reactstrap';

import { connect } from 'react-redux';

import SweetAlert from 'react-bootstrap-sweetalert';

import { updateProduct } from '../../../utils/services/payment';

function EditTicket(props) {
  const { isOpen, toggle, list, ticket } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);

  const [order, setOrder] = useState(ticket.order);
  const [description1, setDescription1] = useState(ticket.description1);
  const [description2, setDescription2] = useState(ticket.description2);
  const [price, setPrice] = useState(
    ticket.price.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
  );
  const [pixPrice, setPixPrice] = useState(
    ticket.pixPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
  );
  const [bankslipPrice, setBankslipPrice] = useState(
    ticket.bankslipPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
  );
  const [quantityAvailable, setQuantityAvailable] = useState(
    ticket.quantityAvailable === -1 ? '' : ticket.quantityAvailable,
  );
  const [quantityType, setQuantityType] = useState(
    ticket.quantityAvailable === -1 ? 'I' : 'N',
  );
  const [eligibleType, setEligigleType] = useState(
    ticket.attendeeEligible ? 'T' : 'F',
  );

  const [priceInstallments, setPriceInstallments] = useState({
    i02xPrice: ticket.i02xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i03xPrice: ticket.i03xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i04xPrice: ticket.i04xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i05xPrice: ticket.i05xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i06xPrice: ticket.i06xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i07xPrice: ticket.i07xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i08xPrice: ticket.i08xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i09xPrice: ticket.i09xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i10xPrice: ticket.i10xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i11xPrice: ticket.i11xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    i12xPrice: ticket.i12xPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
  });

  const fillPriceInstallments = (name, value) => {
    setPriceInstallments((prevState) => ({ ...prevState, [name]: value }));
  };

  const [hiddenInstallments, setHiddenInstallments] = useState({
    i02xPrice: !(2 <= ticket.installments),
    i03xPrice: !(3 <= ticket.installments),
    i04xPrice: !(4 <= ticket.installments),
    i05xPrice: !(5 <= ticket.installments),
    i06xPrice: !(6 <= ticket.installments),
    i07xPrice: !(7 <= ticket.installments),
    i08xPrice: !(8 <= ticket.installments),
    i09xPrice: !(9 <= ticket.installments),
    i10xPrice: !(10 <= ticket.installments),
    i11xPrice: !(11 <= ticket.installments),
    i12xPrice: !(12 <= ticket.installments),
  });

  const fillHiddenInstallments = (name, value) => {
    setHiddenInstallments((prevState) => ({ ...prevState, [name]: value }));
  };

  const [startDate, setStartDate] = useState(ticket.startAt.substring(0, 10));
  const [endDate, setEndDate] = useState(ticket.endAt.substring(0, 10));
  const [startTime, setStartTime] = useState(ticket.startAt.substring(11, 19));
  const [endTime, setEndTime] = useState(ticket.endAt.substring(11, 19));
  const [purchaseMin, setPurchaseMin] = useState(ticket.purchaseMin);
  const [purchaseMax, setPurchaseMax] = useState(ticket.purchaseMax);
  const [isHalfPrice, setIsHalfPrice] = useState(ticket.isHalfPrice);
  const [halfPrice, setHalfPrice] = useState(
    ticket.isHalfPrice
      ? ticket.halfPrice.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          useGrouping: false,
        })
      : '',
  );
  const [tag, setTag] = useState(ticket.tag);
  const [online, setOnline] = useState(ticket.online);
  const [installments, setInstallments] = useState(ticket.installments);

  const [orderError, setOrderError] = useState('');
  const [description1Error, setDescription1Error] = useState('');
  const [description2Error, setDescription2Error] = useState('');
  const [quantityAvailableError, setQuantityAvailableError] = useState('');
  const [priceError, setPriceError] = useState();
  const [pixPriceError, setPixPriceError] = useState('');
  const [bankslipPriceError, setBankslipPriceError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [purchaseError, setPurchaseError] = useState('');

  const [apiError, setApiError] = useState([]);

  const toDate = (date, time) => {
    const [yearStr, monthStr, dayStr] = date.split('-');
    const year = parseInt(yearStr, 10);
    const month = parseInt(monthStr, 10);
    const day = parseInt(dayStr, 10);

    const [hoursStr, minutesStr] = time.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    return new Date(year, month - 1, day, hours, minutes);
  };

  const toHalfPrice = (value) => {
    let newValue = value.replace(',', '.');
    newValue = Math.round((parseFloat(newValue) / 2) * 100) / 100;
    return newValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    });
  };
  const isEmpty = (value) => {
    return value === undefined || value === null || String(value).trim() === '';
  };

  const emptyFields = () => {
    const isEmptyTitle = isEmpty(description1);
    setDescription1Error(isEmptyTitle ? 'Título é obrigatório' : '');

    const isEmptyDescription = isEmpty(description2);
    setDescription2Error(isEmptyDescription ? 'Descrição é obrigatório' : '');

    const isEmptyQuantityAvailable =
      quantityType !== 'I' && isEmpty(quantityAvailable);
    setQuantityAvailableError(
      isEmptyQuantityAvailable ? 'Quantidade é obrigatório' : '',
    );

    const isEmptyPrice = isEmpty(price);
    setApiError(isEmptyPrice ? ['Preço é obrigatório'] : []);

    const isEmptyPixPrice = isEmpty(pixPrice);
    setPixPriceError(isEmptyPrice ? 'Valor do Pix é obrigatório' : '');

    const isEmptyBankSlipPrice = isEmpty(bankslipPrice);
    setBankslipPriceError(isEmptyPrice ? 'Valor do Boleto é obrigatório' : '');

    const isOrderEmpty = isEmpty(order);
    setOrderError(isOrderEmpty ? 'Ordernação é obrigatório' : '');

    const isEmptyStartDate = isEmpty(startDate);
    setStartDateError(isEmptyStartDate ? 'Data é obrigatório' : '');

    const isEmptyStartTime = isEmpty(startTime);
    setStartTimeError(isEmptyStartTime ? 'Horário é obrigatório' : '');

    const isEmptyEndDate = isEmpty(endDate);
    setEndDateError(isEmptyEndDate ? 'Data é obrigatório' : '');

    const isEmptyEndTime = isEmpty(endTime);
    setEndTimeError(isEmptyEndTime ? 'Horário é obrigatório' : '');

    const isEmptyPurchase = isEmpty(purchaseMin) || isEmpty(purchaseMax);
    setPurchaseError(isEmptyPurchase ? 'Quantidade  é obrigatório' : '');

    const invalid =
      isEmptyQuantityAvailable ||
      isEmptyTitle ||
      isEmptyDescription ||
      isEmptyPrice ||
      isEmptyPixPrice ||
      isEmptyBankSlipPrice ||
      isOrderEmpty ||
      isEmptyStartDate ||
      isEmptyStartTime ||
      isEmptyEndDate ||
      isEmptyEndTime ||
      isEmptyPurchase;

    return invalid;
  };

  const isInValidInstallments = () => {
    let error = false;
    for (let i = 2; i <= parseInt(installments); i++) {
      let property = `i${i.toString().padStart(2, '0')}xPrice`;
      let price = priceInstallments[property].toString();
      price = price.replace(',', '.');
      let isInvalidPrice = price <= 0;

      if (isInvalidPrice) {
        setApiError([`Valor total em ${i}x inválido!`]);
        error = true;
        break;
      }
    }
    return error;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (emptyFields()) return;

    const infinityQuantity = quantityType === 'I';
    const attendeeEligible = eligibleType === 'T' ? true : false;

    const currentQuantity = parseInt(quantityAvailable, 10);
    const isInvalidQuantity = infinityQuantity ? false : currentQuantity < 1;
    setQuantityAvailableError(isInvalidQuantity ? 'Quantidade inválida' : '');

    const currentPrice = parseFloat(price.replace(',', '.'));
    const isInvalidPrice = currentPrice < 0;
    setApiError(isInvalidPrice ? ['Preço inválido'] : []);

    const isInvalidPrices = isInValidInstallments();

    const currentHalfPrice = isHalfPrice
      ? parseFloat(halfPrice.replace('.', ','))
      : 0;

    const currentPixPrice = parseFloat(pixPrice.replace(',', '.'));
    const isInvalidPixPrice = currentPixPrice < 0;
    setPixPriceError(isInvalidPixPrice ? 'Valor do PIX inválido' : '');

    const currentBankslipPrice = parseFloat(bankslipPrice.replace(',', '.'));
    const isInvalidBankslipPrice = currentBankslipPrice < 0;
    setBankslipPriceError(
      isInvalidBankslipPrice ? 'Valor do Boleto inválido' : '',
    );
    const currentPurchaseMin = parseInt(purchaseMin, 10);
    const currentPurchaseMax = parseInt(purchaseMax, 10);

    const ispurchaseError =
      currentPurchaseMax < currentPurchaseMin ||
      currentPurchaseMin > currentPurchaseMax;
    setPurchaseError(ispurchaseError ? 'Quantidade inválida' : '');

    const currentDate = new Date();

    const startAt = toDate(startDate, startTime);
    const endAt = toDate(endDate, endTime);

    console.log(startAt);
    console.log(endAt);

    const isPreviousStartDate = false; //currentDate.getTime() > startAt.getTime();
    const isGreaterThan = startAt.getTime() > endAt.getTime();
    const isPreviousEndDate = currentDate.getTime() > endAt.getTime();
    const isLessThan = endAt.getTime() < startAt.getTime();

    setStartDateError(
      isGreaterThan || isPreviousStartDate ? 'Data inválida' : '',
    );
    setStartTimeError(
      isGreaterThan || isPreviousStartDate ? 'Horário inválido' : '',
    );
    setEndDateError(isLessThan || isPreviousEndDate ? 'Data inválida' : '');
    setEndTimeError(isLessThan || isPreviousEndDate ? 'Horário inválido' : '');

    const isInvalid =
      isInvalidPrices ||
      isInvalidQuantity ||
      isInvalidPrice ||
      isInvalidPixPrice ||
      isInvalidBankslipPrice ||
      isPreviousStartDate ||
      isPreviousEndDate ||
      isGreaterThan ||
      isLessThan ||
      ispurchaseError;

    if (isInvalid) return;

    const installmentsInt = parseInt(installments);
    const dto = {
      id: ticket.id,
      quantityAvailable: infinityQuantity ? -1 : currentQuantity,
      description1: description1,
      description2: description2,
      attendeeEligible: attendeeEligible,
      price: currentPrice,
      pixPrice: currentPixPrice,
      bankslipPrice: currentBankslipPrice,
      order: order,
      purchaseMin: currentPurchaseMin,
      purchaseMax: currentPurchaseMax,
      isHalfPrice: isHalfPrice,
      halfPrice: currentHalfPrice,
      startAt: startAt,
      endAt: endAt,
      tag: isEmpty(tag) ? 'todos' : tag,
      online: online,
      installments: installments,
      i02xPrice:
        2 <= installmentsInt
          ? parseFloat(priceInstallments.i02xPrice.replace(',', '.'))
          : 0,
      i03xPrice:
        3 <= installmentsInt
          ? parseFloat(priceInstallments.i03xPrice.replace(',', '.'))
          : 0,
      i04xPrice:
        4 <= installmentsInt
          ? parseFloat(priceInstallments.i04xPrice.replace(',', '.'))
          : 0,
      i05xPrice:
        5 <= installmentsInt
          ? parseFloat(priceInstallments.i05xPrice.replace(',', '.'))
          : 0,
      i06xPrice:
        6 <= installmentsInt
          ? parseFloat(priceInstallments.i06xPrice.replace(',', '.'))
          : 0,
      i07xPrice:
        7 <= installmentsInt
          ? parseFloat(priceInstallments.i07xPrice.replace(',', '.'))
          : 0,
      i08xPrice:
        8 <= installmentsInt
          ? parseFloat(priceInstallments.i08xPrice.replace(',', '.'))
          : 0,
      i09xPrice:
        9 <= installmentsInt
          ? parseFloat(priceInstallments.i09xPrice.replace(',', '.'))
          : 0,
      i10xPrice:
        10 <= installmentsInt
          ? parseFloat(priceInstallments.i10xPrice.replace(',', '.'))
          : 0,
      i11xPrice:
        11 <= installmentsInt
          ? parseFloat(priceInstallments.i11xPrice.replace(',', '.'))
          : 0,
      i12xPrice:
        12 <= installmentsInt
          ? parseFloat(priceInstallments.i12xPrice.replace(',', '.'))
          : 0,
    };

    console.log(dto);

    await updateProduct({
      jwt: props.state.global_user_data.data.token,
      dto,
    })
      .then((res) => {
        console.log(res);
        if (res.success) {
          list();
          setSuccessDlg(true);
        } else {
          setApiError(res.errors);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {}, [priceInstallments, hiddenInstallments, installments]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setModalBackdrop(!modalBackdrop);
      }}
      centered={true}
      style={{ width: '60em', maxHeight: '90vh' }}
    >
      {successDlg ? (
        <SweetAlert
          success
          title={'Editado'}
          onConfirm={() => {
            setSuccessDlg(false);
            toggle();
          }}
        >
          {'Ingresso editado com sucesso!'}
        </SweetAlert>
      ) : null}

      <ModalHeader toggle={toggle}>Editar Ingresso</ModalHeader>
      <ModalBody style={{ maxHeight: '100vh' }}>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={2}>
              <label className='form-label'>Ordenação</label>
              <input
                className='form-control'
                type='text'
                value={order}
                onChange={(e) => {
                  let newValue = e.target.value;
                  newValue = newValue.replace(/\D/g, '');
                  setOrder(newValue);
                }}
              />
              <p style={{ color: 'red' }}>{orderError}</p>
            </Col>
            <Col lg={10}>
              <div>
                <label className='form-label'>Título</label>
                <input
                  className='form-control'
                  type='text'
                  value={description1}
                  onChange={(e) => setDescription1(e.target.value)}
                />
                <p style={{ color: 'red' }}>{description1Error}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className='form-label'>Descrição</label>
                <textarea
                  className='form-control'
                  type='text'
                  value={description2}
                  onChange={(e) => setDescription2(e.target.value)}
                />
                <p style={{ color: 'red' }}>{description2Error}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: '0.5rem' }}>
            <Col lg={12}>Início das Vendas </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div>
                <input
                  className='form-control'
                  type='date'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <p style={{ color: 'red' }}>{startDateError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <input
                  className='form-control'
                  type='time'
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
                <p style={{ color: 'red' }}>{startTimeError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: '0.5rem' }}>
            <Col lg={12}>Término das Vendas</Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div>
                <input
                  className='form-control'
                  type='date'
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <p style={{ color: 'red' }}>{endDateError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <input
                  className='form-control'
                  type='time'
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
                <p style={{ color: 'red' }}>{endTimeError}</p>
              </div>
            </Col>
          </Row>

          <div className='card'>
            <h5 class='card-title'>Cartão de crédito</h5>
            <Row className='mt-2 px-2'>
              <Col lg={8}>
                <label className='form-label'>Parcelamento máximo</label>
              </Col>
              <Col lg={4}>
                <select
                  className='form-control select-style'
                  value={installments}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setInstallments(newValue);

                    for (let i = 2; i <= 12; i++) {
                      fillHiddenInstallments(
                        `i${i.toString().padStart(2, '0')}xPrice`,
                        true,
                      );
                    }

                    for (let i = 2; i <= parseInt(newValue); i++) {
                      fillHiddenInstallments(
                        `i${i.toString().padStart(2, '0')}xPrice`,
                        false,
                      );
                    }
                  }}
                >
                  {Array.from({ length: 12 }, (_, index) => index + 1).map(
                    (installment) => (
                      <option
                        key={installment}
                        value={installment}
                      >
                        {installment}x
                      </option>
                    ),
                  )}
                </select>
              </Col>
            </Row>
            <Row className='mt-2 px-2'>
              <Col lg={8}>
                <label className='form-label'>Valor total em 1x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={price}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    setPrice(newValue);
                    setHalfPrice(
                      isHalfPrice && !isEmpty(newValue)
                        ? toHalfPrice(newValue)
                        : '',
                    );
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i02xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 2x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i02xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i02xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i03xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 3x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i03xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i03xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i04xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 4x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i04xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i04xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i05xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 5x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i05xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i05xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i06xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 6x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i06xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i06xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i07xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 7x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i07xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i07xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i08xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 8x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i08xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i08xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i09xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 9x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i09xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i09price', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i10xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 10x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i10xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i10xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i11xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 11x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i11xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i11xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
            <Row
              className='mt-2 px-2'
              hidden={hiddenInstallments.i12xPrice}
            >
              <Col lg={8}>
                <label className='form-label'>Valor total em 12x (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={priceInstallments.i12xPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    fillPriceInstallments('i12xPrice', newValue);
                  }}
                />
              </Col>
            </Row>
          </div>

          {/* <Col lg={8}>
              <label className='form-label'>Meia Entrada (R$)</label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  gap: '4em',
                }}
              >
                <span>
                  Sim{' '}
                  <input
                    type='checkbox'
                    value={isHalfPrice}
                    checked={isHalfPrice}
                    onChange={(e) => {
                      setIsHalfPrice(e.target.checked);
                      setHalfPrice(
                        e.target.checked && !isEmpty(price)
                          ? toHalfPrice(price)
                          : '',
                      );
                    }}
                  />
                </span>
                <Col lg={6}>
                  <input
                    className='form-control'
                    disabled={true}
                    type='text'
                    style={{
                      textAlign: 'right',
                    }}
                    value={halfPrice}
                  />
                </Col>
              </div>
            </Col> */}

          <div className='card'>
            <h5 class='card-title'>PIX</h5>
            <Row className='mt-2 px-2'>
              <Col lg={8}>
                <label className='form-label'>Valor (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={pixPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    setPixPrice(newValue);
                  }}
                />
                <p style={{ color: 'red' }}>{pixPriceError}</p>
              </Col>
            </Row>
          </div>
          <div className='card'>
            <h5 class='card-title'>Boleto</h5>
            <Row className='mt-2 px-2'>
              <Col lg={8}>
                <label className='form-label'>Valor (R$)</label>
              </Col>
              <Col lg={4}>
                <input
                  className='form-control'
                  type='text'
                  value={bankslipPrice}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, '');
                    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
                    setBankslipPrice(newValue);
                  }}
                />
                <p style={{ color: 'red' }}>{bankslipPriceError}</p>
              </Col>
            </Row>
          </div>
          <Row className='mt-4'>
            <Col lg={6}>
              <label className='form-label'>Quantidade</label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '0.35rem',
                }}
              >
                <span>
                  Ilimitada{' '}
                  <input
                    type='radio'
                    value={'I'}
                    checked={quantityType === 'I'}
                    onChange={(e) => {
                      setQuantityType(e.target.value);
                      setQuantityAvailable('');
                    }}
                  />
                </span>
                <span>
                  Limitada{' '}
                  <input
                    type='radio'
                    value={'N'}
                    checked={quantityType === 'N'}
                    onChange={(e) => setQuantityType(e.target.value)}
                  />
                </span>
                <Col lg={3}>
                  <input
                    className='form-control'
                    disabled={quantityType === 'I'}
                    type='text'
                    style={{
                      textAlign: 'right',
                    }}
                    value={quantityAvailable}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      newValue = newValue.replace(/\D/g, '');
                      newValue = newValue === '0' ? '' : newValue;
                      setQuantityAvailable(newValue);
                    }}
                  />
                </Col>
              </div>
              <p style={{ color: 'red' }}>{quantityAvailableError}</p>
            </Col>
            <Col lg={6}>
              <label className='form-label'>
                Quantidade permitada por compra
              </label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  flexDirection: 'row',
                  // justifyContent: 'space-between',
                  gap: '0.45rem',
                }}
              >
                <span>Mínima </span>
                <Col lg={3}>
                  <input
                    className='form-control'
                    type='text'
                    style={{
                      textAlign: 'right',
                    }}
                    value={purchaseMin}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      newValue = newValue.replace(/\D/g, '');
                      newValue = newValue === '0' ? '' : newValue;
                      setPurchaseMin(newValue);
                    }}
                  />
                </Col>
                <span>Máxima</span>
                <Col lg={3}>
                  <input
                    className='form-control'
                    type='text'
                    style={{
                      textAlign: 'right',
                    }}
                    value={purchaseMax}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      newValue = newValue.replace(/\D/g, '');
                      newValue = newValue === '0' ? '' : newValue;
                      setPurchaseMax(newValue);
                    }}
                  />
                </Col>
              </div>
              <p style={{ color: 'red' }}>{purchaseError}</p>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col lg={6}>
              <label className='form-label'>Disponibilidade</label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  lineHeight: '2.15rem',
                  gap: '1rem',
                }}
              >
                <span>
                  Público{' '}
                  <input
                    type='radio'
                    value={'F'}
                    checked={eligibleType === 'F'}
                    onChange={(e) => setEligigleType(e.target.value)}
                  />
                </span>
                <span>
                  Restrito{' '}
                  <input
                    type='radio'
                    value={'T'}
                    checked={eligibleType === 'T'}
                    onChange={(e) => setEligigleType(e.target.value)}
                  />
                </span>
              </div>
            </Col>
            <Col lg={6}>
              <label className='form-label'>Tag</label>
              <input
                className='form-control'
                type='text'
                value={tag}
                onChange={(e) => {
                  let newValue = e.target.value;
                  setTag(newValue);
                }}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col lg={6}>
              <label className='form-label'>Tipo de participação</label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  lineHeight: '2.15rem',
                  gap: '1rem',
                }}
              >
                <span>
                  Presencial{' '}
                  <input
                    type='radio'
                    value={online}
                    checked={!online}
                    onChange={(e) => setOnline(false)}
                  />
                </span>
                <span>
                  Virtual{' '}
                  <input
                    type='radio'
                    value={online}
                    checked={online}
                    onChange={(e) => setOnline(true)}
                  />
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <div style={{ marginTop: '1rem' }}>
              {apiError.map((error) => (
                <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
              ))}
            </div>
          </Row>
          <Row
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
            }}
          >
            <Button
              color='danger'
              className='btn btn-danger waves-effect waves-light'
              style={{ width: 80, marginRight: '1rem' }}
              onClick={() => toggle(!isOpen)}
            >
              Fechar
            </Button>
            <Button
              color='success'
              className='btn btn-success waves-effect waves-light'
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EditTicket);
