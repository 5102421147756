import axios from "axios";
import { urlBase } from "./config/index";

function createTransfer({
  event,
  login,
  company,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  meetingpoint,
  drivername,
  driverphone,
  extrainformation,
  jwt,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    meetingpoint: meetingpoint,
    drivername: drivername,
    driverphone: driverphone,
    extrainformation: extrainformation,
  });

  var config = {
    method: "post",
    url: `${urlBase}/transfers`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createTransferImport({
  event,
  login,
  company,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  meetingpoint,
  drivername,
  driverphone,
  extrainformation,
  jwt,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    meetingpoint: meetingpoint,
    drivername: drivername,
    driverphone: driverphone,
    extrainformation: extrainformation,
  });

  var config = {
    method: "post",
    url: `${urlBase}/transfers/import`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getTransfer({
  jwt,
  idEvent,
  offset,
  limit,
  search,
  columnSearch = '',
}) {
  var config = {
    method: 'get',
    url: `${urlBase}/transfers/event/${idEvent}?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteTransfer({ jwt, id }) {
  var config = {
    method: "delete",
    url: `${urlBase}/transfers/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteAllTransfers({ jwt, eventId }) {
  var config = {
    method: "put",
    url: `${urlBase}/transfers/disableall/${eventId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function updateTransfer({
  event,
  login,
  company,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  meetingpoint,
  drivername,
  driverphone,
  extrainformation,
  jwt,
  idTransfer
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    meetingpoint: meetingpoint,
    drivername: drivername,
    driverphone: driverphone,
    extrainformation: extrainformation,
  });

  var config = {
    method: "put",
    url: `${urlBase}/transfers/${idTransfer}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { createTransfer, createTransferImport, getTransfer, deleteAllTransfers, deleteTransfer, updateTransfer };
