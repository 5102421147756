import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import Select from "react-select";
//redux
import { connect } from "react-redux";
//service
import { getContract } from "../../../utils/services/contracts";

function EditContractSelected(props) {
  const { isOpen, toggle } = props;
  const [selectedGroup, setselectedGroup] = useState(null);
  //state de contratos
  const [contracts, setContracts] = useState([]);
  //state errors
  const [contractError, setContractError] = useState("");

  function getAllContracts() {
    getContract({
      jwt: props.state.global_user_data.data.token,
      offset: 1,
      limit: 10,
      search: "",
      status: true,
    }).then((res) => {
      if (res.data.contracts) {
        setContracts(res.data.contracts);
      }
    });
  }

  function submitForm(e) {
    if (e.target[0].value === "Selecione") {
      setContractError("Selecione um contrato");
      return;
    } else setContractError("");
  }

  useEffect(() => {
    getAllContracts();
  }, []);

  return (
    <Modal isOpen={isOpen} autoFocus={true} centered={true}>
      <ModalHeader toggle={toggle}>Selecionar Contrato</ModalHeader>
      <ModalBody
        style={{
          // minHeight: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
          style={{ width: "100%" }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <select className="form-control">
                  <option>Selecione</option>
                  {contracts.map((e, i) => (
                    <option key={i} value={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
                {contractError && (
                  <a style={{ color: "red" }}>{contractError}</a>
                )}
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "20px 0 0 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 130 }}
            >
              Alterar Contrato
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(EditContractSelected);
