import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from 'reactstrap';

import { connect } from 'react-redux';

import SweetAlert from 'react-bootstrap-sweetalert';

import { updateSplit, listAccounts } from '../../../utils/services/payment';

function EditSplit(props) {
  const { isOpen, toggle, list, split } = props;
  const [apiError, setApiError] = useState([]);
  const percentFormmater = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const splitScreen = {
    recipient_account_id: split.recipient_account_id,
    token: split.token,
    cents: split.cents | '',
    percent: percentFormmater.format(split.percent),
    bank_slip_cents: '',
    bank_slip_percent: '',
    credit_card_cents: split.credit_card_cents | '',
    credit_card_percent: percentFormmater.format(split.credit_card_percent),
    pix_cents: split.pix_cents | '',
    pix_percent: percentFormmater.format(split.pix_percent),
    credit_card_1x_cents: split.credit_card_1x_cents | '',
    credit_card_2x_cents: split.credit_card_2x_cents | '',
    credit_card_3x_cents: split.credit_card_3x_cents | '',
    credit_card_4x_cents: split.credit_card_4x_cents | '',
    credit_card_5x_cents: split.credit_card_5x_cents | '',
    credit_card_6x_cents: split.credit_card_6x_cents | '',
    credit_card_7x_cents: split.credit_card_7x_cents | '',
    credit_card_8x_cents: split.credit_card_8x_cents | '',
    credit_card_9x_cents: split.credit_card_9x_cents | '',
    credit_card_10x_cents: split.credit_card_10x_cents | '',
    credit_card_11x_cents: split.credit_card_11x_cents | '',
    credit_card_12x_cents: split.credit_card_12x_cents | '',
    credit_card_1x_percent: percentFormmater.format(
      split.credit_card_1x_percent,
    ),
    credit_card_2x_percent: percentFormmater.format(
      split.credit_card_2x_percent,
    ),
    credit_card_3x_percent: percentFormmater.format(
      split.credit_card_3x_percent,
    ),
    credit_card_4x_percent: percentFormmater.format(
      split.credit_card_4x_percent,
    ),
    credit_card_5x_percent: percentFormmater.format(
      split.credit_card_5x_percent,
    ),
    credit_card_6x_percent: percentFormmater.format(
      split.credit_card_6x_percent,
    ),
    credit_card_7x_percent: percentFormmater.format(
      split.credit_card_7x_percent,
    ),
    credit_card_8x_percent: percentFormmater.format(
      split.credit_card_8x_percent,
    ),
    credit_card_9x_percent: percentFormmater.format(
      split.credit_card_9x_percent,
    ),
    credit_card_10x_percent: percentFormmater.format(
      split.credit_card_10x_percent,
    ),
    credit_card_11x_percent: percentFormmater.format(
      split.credit_card_11x_percent,
    ),
    credit_card_12x_percent: percentFormmater.format(
      split.credit_card_12x_percent,
    ),
  };

  const defaultSplit = {
    id: split.id,
    event_id: split.event_id,
    recipient_account_id: split.recipient_account_id,
    token: split.token,
    cents: split.cents,
    percent: split.percent,
    bank_slip_cents: split.bank_slip_cents,
    bank_slip_percent: split.bank_slip_percent,
    credit_card_cents: split.credit_card_cents,
    credit_card_percent: split.credit_card_percent,
    pix_cents: split.pix_cents,
    pix_percent: split.pix_percent,
    permit_aggregated: split.permit_aggregated,
    credit_card_1x_cents: split.credit_card_1x_cents,
    credit_card_2x_cents: split.credit_card_2x_cents,
    credit_card_3x_cents: split.credit_card_3x_cents,
    credit_card_4x_cents: split.credit_card_4x_cents,
    credit_card_5x_cents: split.credit_card_5x_cents,
    credit_card_6x_cents: split.credit_card_6x_cents,
    credit_card_7x_cents: split.credit_card_7x_cents,
    credit_card_8x_cents: split.credit_card_8x_cents,
    credit_card_9x_cents: split.credit_card_9x_cents,
    credit_card_10x_cents: split.credit_card_10x_cents,
    credit_card_11x_cents: split.credit_card_11x_cents,
    credit_card_12x_cents: split.credit_card_12x_cents,
    credit_card_1x_percent: split.credit_card_1x_percent,
    credit_card_2x_percent: split.credit_card_2x_percent,
    credit_card_3x_percent: split.credit_card_3x_percent,
    credit_card_4x_percent: split.credit_card_4x_percent,
    credit_card_5x_percent: split.credit_card_5x_percent,
    credit_card_6x_percent: split.credit_card_6x_percent,
    credit_card_7x_percent: split.credit_card_7x_percent,
    credit_card_8x_percent: split.credit_card_8x_percent,
    credit_card_9x_percent: split.credit_card_9x_percent,
    credit_card_10x_percent: split.credit_card_10x_percent,
    credit_card_11x_percent: split.credit_card_11x_percent,
    credit_card_12x_percent: split.credit_card_12x_percent,
  };

  const [newSplit, setNewSplit] = useState(splitScreen);
  const [dto, setDto] = useState(defaultSplit);

  const fillNewSplit = (name, value) => {
    setNewSplit((prevState) => ({ ...prevState, [name]: value }));
    let newValue = value;
    if (newValue !== '') {
      if (name.includes('percent')) {
        newValue = value.replace(',', '.');
        newValue = parseFloat(value);
        console.log(newValue);
      }
      if (name.includes('cents')) {
        newValue = parseInt(value);
      }

      setDto((prevState) => ({ ...prevState, [name]: newValue }));
    }
  };

  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);

  const cents = (e) => {
    let newValue = e.target.value;
    return newValue.replace(/\D/g, '');
  };

  const percent = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/\D/g, '');
    newValue = newValue.replace(/(\d+)(\d{2})/, '$1,$2');
    newValue = newValue.replace(',', '.');
    const greaterThan100 = parseFloat(newValue) > 100;
    return greaterThan100 ? '' : newValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      dto.recipient_account_id === null ||
      dto.recipient_account_id.trim() === ''
    ) {
      setApiError(['Conta que irá receber o split sem # não foi preenchido']);
      return;
    }

    if (dto.token === null || dto.token.trim() === '') {
      setApiError(['Token da Subconta não foi preenchido']);
      return;
    }
    await updateSplit({
      jwt: props.state.global_user_data.data.token,
      dto,
    })
      .then((res) => {
        console.log(res);
        if (res.success) {
          list();
          setSuccessDlg(true);
        } else {
          setApiError(res.errors);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setModalBackdrop(!modalBackdrop);
      }}
      centered={true}
      style={{ width: '60em', maxHeight: '90vh' }}
    >
      {successDlg ? (
        <SweetAlert
          success
          title={'Alterado'}
          onConfirm={() => {
            setSuccessDlg(false);
            toggle();
          }}
        >
          {'Split alterado com sucesso!'}
        </SweetAlert>
      ) : null}

      <ModalHeader toggle={toggle}>Adicionar Split</ModalHeader>
      <ModalBody style={{ maxHeight: '100vh', display: 'flex', gap: '4em' }}>
        <form
          onSubmit={handleSubmit}
          style={{
            maxHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
          }}
        >
          <Row>
            <Col lg={12}>
              <label className='form-label'>
                Token da conta que írá gerar a fatura:
              </label>
              <input
                className='form-control'
                type='text'
                value={newSplit.token}
                onChange={(e) => {
                  fillNewSplit('token', e.target.value.trim());
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label className='form-label'>
                Conta que irá receber o split sem #:
              </label>
              <input
                className='form-control'
                type='text'
                value={newSplit.recipient_account_id}
                onChange={(e) => {
                  fillNewSplit('recipient_account_id', e.target.value.trim());
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Centavos a serem cobrados da fatura
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Porcentagem a ser cobrada da fatura
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações no cartão
                de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações no pix
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.pix_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('pix_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações no pix
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.pix_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('pix_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 1x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_1x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_1x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 2x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_2x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_2x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 3x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_3x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_3x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 4x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_4x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_4x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 5x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_5x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_5x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 6x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_6x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_6x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 7x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_7x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_7x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 8x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_8x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_8x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 9x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_9x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_9x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 10x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_10x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_10x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 11x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_11x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_11x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em centavos a ser cobrado apenas em transações em 12x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_12x_cents}
                style={{ textAlign: 'right' }}
                maxLength={2}
                onChange={(e) => {
                  fillNewSplit('credit_card_12x_cents', cents(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 1x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_1x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_1x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 2x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_2x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_2x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 3x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_3x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_3x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 4x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_4x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_4x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 5x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_5x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_5x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 6x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_6x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_6x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 7x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_7x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_7x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 8x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_8x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_8x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 9x no
                cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_9x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_9x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 10x
                no cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_10x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_10x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 11x
                no cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_11x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_11x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className='form-label'>
                Valor em porcentagem a ser cobrado apenas em transações em 12x
                no cartão de crédito
              </label>
            </Col>
            <Col lg={4}>
              <input
                className='form-control'
                type='text'
                value={newSplit.credit_card_12x_percent}
                style={{ textAlign: 'right' }}
                maxLength={6}
                onChange={(e) => {
                  fillNewSplit('credit_card_12x_percent', percent(e));
                }}
              />
            </Col>
          </Row>
          <Row>
            <div style={{ padding: '0.25rem' }}>
              {apiError.map((error) => (
                <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
              ))}
            </div>
          </Row>
          <Row
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '1rem',
            }}
          >
            <Button
              color='danger'
              className='btn btn-danger waves-effect waves-light'
              style={{ width: 80, marginRight: '1rem' }}
              onClick={() => toggle(!isOpen)}
            >
              Fechar
            </Button>
            <Button
              color='success'
              className='btn btn-success waves-effect waves-light'
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EditSplit);
