import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Collapse,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import {
  createConfigMatch,
  getConfigMatch,
} from "../../../utils/services/match";
import Select from "react-select";

// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// componentes
import TextEditor2 from "./TextEditor";
// componente de cot
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
// componente de expandir seção
import SectionComponent from "../../Apps/Personalization/SectionComponent";
// html padrão
import {
  solicitationPt,
  solicitationEn,
  solicitationEs,
  acceptPt,
  acceptEn,
  acceptEs,
  refusedPt,
  refusedEn,
  refusedEs,
} from "./htmlDefaults";
import { getGroups } from "../../../utils/services/group";
import { TooltipModal } from "./ModalTooltips";

export default function ModalConfig(props) {
  const { isOpen, toggle, eventId, jwt } = props;
  // dados do form
  const [formData, setFormData] = useState({});
  // erros
  const [erros, setErros] = useState({});
  // seção dentro do match
  const [sectionPortugues, setSectionPortugues] = useState(false);
  const [sectionIngles, setSectionIngles] = useState(false);
  const [sectionEspanhol, setSectionEspanhol] = useState(false);
  const [sectionRooms, setSectionRooms] = useState(false);
  const [sectionFooter, setSectionFooter] = useState(false);
  // cores
  const [colorBackground, setColorBackground] = useState("#FFFFFF");
  const [colorSelect, setColorSelect] = useState("#FFFFFF");
  // editores em portugues
  const refEditorPTSolicitation = useRef(null);
  const [editorPTSolicitation, setEditorPTSolicitation] =
    useState(solicitationPt);
  const refEditorPTAccept = useRef(null);
  const [editorPTAccept, setEditorPTAccept] = useState(acceptPt);
  const refEditorPTRefused = useRef(null);
  const [editorPTRefused, setEditorPTRefused] = useState(refusedPt);
  // editores em inglês
  const refEditorENSolicitation = useRef(null);
  const [editorENSolicitation, setEditorENSolicitation] =
    useState(solicitationEn);
  const refEditorENAccept = useRef(null);
  const [editorENAccept, setEditorENAccept] = useState(acceptEn);
  const refEditorENRefused = useRef(null);
  const [editorENRefused, setEditorENRefused] = useState(refusedEn);
  // editores em espanhol
  const refEditorESSolicitation = useRef(null);
  const [editorESSolicitation, setEditorESSolicitation] =
    useState(solicitationEs);
  const refEditorESAccept = useRef(null);
  const [editorESAccept, setEditorESAccept] = useState(acceptEs);
  const refEditorESRefused = useRef(null);
  const [editorESRefused, setEditorESRefused] = useState(refusedEs);
  // alertas
  const [successCreateAlert, setSuccessCreateAlert] = useState(false);
  const [successUpdateAlert, setSuccessUpdateAlert] = useState(false);
  // tipo de envio pra api
  const [typeSendApi, setTypeSendApi] = useState("");
  // carregamento
  const [loadingData, setLoadingData] = useState(true);
  //
  const [groups, setGroups] = useState([]);
  const [groupsMulti, setGroupsMulti] = useState([]);
  const [showInputGroupA, setShowInputGroupA] = useState(null);
  const [showInputGroupB, setShowInputGroupB] = useState(null);

  function verifyErros() {
    if (showInputGroupA && formData.disregardedGroups.length == 0) {
      setErros({
        disregardedGroups: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (showInputGroupB && formData.noMatchGroups.length == 0) {
      setErros({
        noMatchGroups: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (formData.segregate === undefined) {
      setErros({
        segregate: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (formData.disregardedGroups === undefined) {
      setErros({
        disregardedGroups: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (formData.noMatchGroups === undefined) {
      setErros({
        noMatchGroups: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorPTSolicitation.current.getContent()) {
      setSectionPortugues(true);
      setErros({
        ptSolicitation: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorPTAccept.current.getContent()) {
      setSectionPortugues(true);
      setErros({
        ptAccept: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorPTRefused.current.getContent()) {
      setSectionPortugues(true);
      setErros({
        ptRefused: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorENSolicitation.current.getContent()) {
      setSectionIngles(true);
      setErros({
        enSolicitation: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorENAccept.current.getContent()) {
      setSectionIngles(true);
      setErros({
        enAccept: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorENRefused.current.getContent()) {
      setSectionIngles(true);
      setErros({
        enRefused: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorESSolicitation.current.getContent()) {
      setSectionEspanhol(true);
      setErros({
        esSolicitation: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorESAccept.current.getContent()) {
      setSectionEspanhol(true);
      setErros({
        esAccept: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    if (!refEditorESRefused.current.getContent()) {
      setSectionEspanhol(true);
      setErros({
        esRefused: "Esse campo é obrigatório",
      });
      return;
    } else setErros({});

    submitForm();
  }

  async function submitForm() {
    const data = {
      eventId: eventId,
      colorBackground: colorBackground,
      colorButton: colorSelect,
      isSegregatedByPosition: formData.segregate,
      requestEmailBr: refEditorPTSolicitation.current.getContent(),
      requestEmailEn: refEditorENSolicitation.current.getContent(),
      requestEmailSp: refEditorESSolicitation.current.getContent(),
      acceptanceEmailBr: refEditorPTAccept.current.getContent(),
      acceptanceEmailEn: refEditorENAccept.current.getContent(),
      acceptanceEmailSp: refEditorESAccept.current.getContent(),
      refusalEmailBr: refEditorPTRefused.current.getContent(),
      refusalEmailEn: refEditorENRefused.current.getContent(),
      refusalEmailSp: refEditorESRefused.current.getContent(),
      unavailableRoomsBr: formData.roomPt,
      unavailableRoomsEn: formData.roomEn,
      unavailableRoomsSp: formData.roomEs,
      footerTextBr: formData.footerPt,
      footerTextEn: formData.footerEn,
      footerTextSp: formData.footerEs,
      disregardedGroups: showInputGroupA ? formData.disregardedGroups : [],
      noMatchGroups: showInputGroupB ? formData.noMatchGroups : [],
    };

    if (typeSendApi === "put") {
      data.id = formData.id;
    }

    await createConfigMatch({
      data: data,
      token: jwt,
      typeSendApi: typeSendApi,
    })
      .then((res) => {
        if (res.message === "MATCH_CONFIG_CREATED") {
          // criado
          setSuccessCreateAlert(true);
        } else if (res.message === "MATCH_CONFIG_UPDATED") {
          // editado
          setSuccessUpdateAlert(true);
        } else if (res.message === "MATCH_CONFIG_DELETED") {
          // deletado
        } else {
          // erro
        }
      })
      .catch((error) => {
        // console.log("erro", error);
      });
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: jwt,
      eventId: eventId,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  async function getConfigMatchFunc() {
    await getConfigMatch({
      token: jwt,
      eventId: eventId,
    })
      .then((res) => {
        if (res?.count > 0) {
          setTypeSendApi("put");
          setFormData({
            segregate: res.data[0].isSegregatedByPosition,
            roomPt: res.data[0].unavailableRoomsBr,
            roomEn: res.data[0].unavailableRoomsEn,
            roomEs: res.data[0].unavailableRoomsSp,
            footerPt: res.data[0].footerTextBr,
            footerEn: res.data[0].footerTextEn,
            footerEs: res.data[0].footerTextSp,
            disregardedGroups: res.data[0].disregardedGroups,
            noMatchGroups: res.data[0].noMatchGroups,
            id: res.data[0].id,
          });

          if (res.data[0].disregardedGroups)
            setShowInputGroupA(res.data[0].disregardedGroups.length > 0);
          if (res.data[0].noMatchGroups)
            setShowInputGroupB(res.data[0].noMatchGroups.length > 0);
          setColorBackground(res.data[0].colorBackground);
          setColorSelect(res.data[0].colorButton);
          setEditorPTSolicitation(res?.data[0]?.requestEmailBr);
          setEditorPTAccept(res?.data[0]?.acceptanceEmailBr);
          setEditorPTRefused(res?.data[0]?.refusalEmailBr);

          setEditorENSolicitation(res?.data[0]?.requestEmailEn);
          setEditorENAccept(res?.data[0]?.acceptanceEmailEn);
          setEditorENRefused(res?.data[0]?.refusalEmailEn);

          setEditorESSolicitation(res?.data[0]?.requestEmailSp);
          setEditorESAccept(res?.data[0]?.acceptanceEmailSp);
          setEditorESRefused(res?.data[0]?.refusalEmailSp);
        } else {
          setTypeSendApi("post");
        }

        setLoadingData(false);
      })
      .catch((error) => {
        // console.log("Erro", error);
      });
  }

  useEffect(() => {
    getConfigMatchFunc();
    getGroupsFunc();
  }, []);


  const [isModalTooltip, setIsModalTooltip] = useState(false);
  const [tooltipType, setTooltipType] = useState("")

  const modalToggle = () => setIsModalTooltip(!isModalTooltip);

  const openModal = (type) => {
    setIsModalTooltip(true);
    setTooltipType(type)
  }

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        {successCreateAlert && (
          <SweetAlert
            success
            title={"Criado"}
            onConfirm={() => {
              setSuccessCreateAlert(false);
              toggle();
            }}
          >
            Configurações criadas com sucesso
          </SweetAlert>
        )}

        {successUpdateAlert && (
          <SweetAlert
            success
            title={"Editado"}
            onConfirm={() => {
              setSuccessUpdateAlert(false);
              toggle();
            }}
          >
            Configurações editadas com sucesso
          </SweetAlert>
        )}

        <ModalHeader toggle={toggle}>Configurações</ModalHeader>
        {loadingData ? (
          <div className="div-chatfixed" style={{ justifyContent: "center" }}>
            <a style={{ color: "black" }}>
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
              Carregando
            </a>
          </div>
        ) : (
          <ModalBody>
            <Row style={{ marginBottom: 10 }}>
              <Col md={6}>
                <ColorPicker
                  classOfContainer="primary-card-pickr"
                  labelText="Cor de Fundo *"
                  setStateFunc={setColorBackground}
                  defaultColor={colorBackground}
                  idDiv={"match-corFundo"}
                  tip="Fundo da tela Tipo de Quarto, Montar Quarto e dos templates de email"
                />
              </Col>

              <Col md={6}>
                <ColorPicker
                  classOfContainer="secondary-card-pickr"
                  labelText="Cor do Botão Selecionar *"
                  setStateFunc={setColorSelect}
                  defaultColor={colorSelect}
                  idDiv={"match-corSelecionar"}
                  tip="Botão Selecionar Participante"
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <label className="form-labe label-tooltip">
                  Segregar participantes por Cargo? *
                </label>
                <div style={{ display: "flex" }}>
                  <div className="form-check mb-3" style={{ marginRight: 10 }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="segregar"
                      id="segregar2"
                      value="option2"
                      defaultChecked={
                        formData.segregate === false ? true : false
                      }
                      onChange={() => {
                        setFormData((state) => {
                          return {
                            ...state,
                            segregate: false,
                          };
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="segregar2">
                      Não
                    </label>
                  </div>
                  <div className="form-check mb-3" style={{ marginRight: 10 }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="segregar"
                      id="segregar1"
                      value="option1"
                      defaultChecked={
                        formData.segregate === true ? true : false
                      }
                      onChange={() => {
                        setFormData((state) => {
                          return {
                            ...state,
                            segregate: true,
                          };
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="segregar1">
                      Sim
                    </label>
                  </div>
                </div>
              </Col>
              {erros.segregate && (
                <p style={{ color: "red" }}>{erros.segregate}</p>
              )}
            </Row>

            <Row>
              <Col md={12}>
                <label className="form-labe label-tooltip">
                  Desconsiderar grupos na segregação dos participantes? *
                </label>
                <div style={{ display: "flex" }}>
                  <div className="form-check mb-3" style={{ marginRight: 10 }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="segregar33"
                      id="segregar5"
                      value="option5"
                      defaultChecked={formData.disregardedGroups?.length < 1}
                      onChange={() => {
                        setShowInputGroupA(false);
                        setFormData((state) => {
                          return {
                            ...state,
                            disregardedGroups: [],
                          };
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="segregar5">
                      Não
                    </label>
                  </div>
                  <div className="form-check mb-3" style={{ marginRight: 10 }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="segregar33"
                      id="segregar6"
                      value="option6"
                      defaultChecked={formData.disregardedGroups?.length > 0}
                      onChange={() => {
                        setShowInputGroupA(true);
                      }}
                    />
                    <label className="form-check-label" htmlFor="segregar6">
                      Sim
                    </label>
                  </div>
                </div>
                {showInputGroupA && (
                  <Row style={{ marginTop: 0, marginBottom: 20 }}>
                    <Col lg={12}>
                      <div>
                        <Select
                          required
                          placeholder={"Selecione os Grupos"}
                          isMulti={true}
                          value={groups
                            .filter((item) =>
                              formData.disregardedGroups?.includes(item.id)
                            )
                            .map((e) => {
                              return { label: e.name, value: e.id };
                            })}
                          options={[
                            {
                              options: [
                                { label: "TODOS", value: "todos" },
                                ...groups.map((e) => {
                                  return { label: e.name, value: e.id };
                                }),
                              ],
                            },
                          ]}
                          classNamePrefix="select2-selection"
                          onChange={(e) => {
                            setFormData((state) => {
                              return {
                                ...state,
                                disregardedGroups: e.map((e) => e.value),
                              };
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              {erros.disregardedGroups && (
                <p style={{ color: "red" }}>{erros.disregardedGroups}</p>
              )}
            </Row>

            <Row>
              <Col md={12}>
                <label className="form-labe label-tooltip">
                  Informar grupos em que os participantes não participaram do
                  match? *
                </label>
                <div style={{ display: "flex" }}>
                  <div className="form-check mb-3" style={{ marginRight: 10 }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="segregar3"
                      id="segregar22"
                      value="option22"
                      defaultChecked={formData.noMatchGroups?.length < 1}
                      onChange={() => {
                        setShowInputGroupB(false);
                        //
                        setFormData((state) => {
                          return {
                            ...state,
                            noMatchGroups: [],
                          };
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="segregar22">
                      Não
                    </label>
                  </div>
                  <div className="form-check mb-3" style={{ marginRight: 10 }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="segregar3"
                      id="segregar11"
                      value="option11"
                      defaultChecked={formData.noMatchGroups?.length > 0}
                      onChange={() => setShowInputGroupB(true)}
                    />
                    <label className="form-check-label" htmlFor="segregar11">
                      Sim
                    </label>
                  </div>
                </div>
                {showInputGroupB && (
                  <Row style={{ marginTop: 0, marginBottom: 20 }}>
                    <Col lg={12}>
                      <div>
                        <Select
                          required
                          placeholder={"Selecione os Grupos"}
                          isMulti={true}
                          value={groups
                            .filter((item) =>
                              formData.noMatchGroups?.includes(item.id)
                            )
                            .map((e) => {
                              return { label: e.name, value: e.id };
                            })}
                          options={[
                            {
                              options: [
                                { label: "TODOS", value: "todos" },
                                ...groups.map((e) => {
                                  return { label: e.name, value: e.id };
                                }),
                              ],
                            },
                          ]}
                          classNamePrefix="select2-selection"
                          onChange={(e) => {
                            setFormData((state) => {
                              return {
                                ...state,
                                noMatchGroups: e.map((e) => e.value),
                              };
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              {erros.noMatchGroups && (
                <p style={{ color: "red" }}>{erros.noMatchGroups}</p>
              )}
            </Row>
            <Row style={{ marginTop: 10 }}>
              <SectionComponent
                sectionName={"Templates de email em Português"}
                setIsOpen={setSectionPortugues}
                isOpen={sectionPortugues}
              />
            </Row>
            <Collapse isOpen={sectionPortugues}>
              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Solicitação *
                  <div id={`email-solicition`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-solicition`}
                    >
                      Email enviado no momento que o participante conclui a montagem do quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor2
                  defaultState={editorPTSolicitation}
                  refState={refEditorPTSolicitation}
                />
                {erros.ptSolicitation && (
                  <p style={{ color: "red" }}>{erros.ptSolicitation}</p>
                )}
              </Row>

              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Aceite *
                  <div id={`email-accept1`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-accept1`}
                    >
                      Email enviado no momento que o participante aceita o
                      convite para divisão de quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor2
                  defaultState={editorPTAccept}
                  refState={refEditorPTAccept}
                />
                {erros.ptAccept && (
                  <p style={{ color: "red" }}>{erros.ptAccept}</p>
                )}
              </Row>

              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Recusa *
                  <div id={`email-refused`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-refused`}
                    >
                      Email enviado no momento que o participante recusa o
                      convite para divisão de quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor2
                  defaultState={editorPTRefused}
                  refState={refEditorPTRefused}
                />
                {erros.ptRefused && (
                  <p style={{ color: "red" }}>{erros.ptRefused}</p>
                )}
              </Row>
            </Collapse>

            <Row style={{ marginTop: 10 }}>
              <SectionComponent
                sectionName={"Templates de email em Inglês"}
                setIsOpen={setSectionIngles}
                isOpen={sectionIngles}
              />
            </Row>
            <Collapse isOpen={sectionIngles}>
              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Solicitação *
                  <div id={`email-solicitation`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-solicitation`}
                    >
                      Email enviado no momento que o participante concluí a
                      montagem do quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor2
                  defaultState={editorENSolicitation}
                  refState={refEditorENSolicitation}
                />
                {erros.enSolicitation && (
                  <p style={{ color: "red" }}>{erros.enSolicitation}</p>
                )}
              </Row>

              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Aceite *
                  <div id={`email-accept`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-accept`}
                    >
                      Email enviado no momento que o participante aceita o
                      convite para divisão de quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor2
                  defaultState={editorENAccept}
                  refState={refEditorENAccept}
                />
                {erros.enAccept && (
                  <p style={{ color: "red" }}>{erros.enAccept}</p>
                )}
              </Row>

              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Recusa *
                  <div id={`email-refused`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips" >
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-refused`}
                    >
                      Email enviado no momento que o participante recusa o
                      convite para divisão de quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor2
                  defaultState={editorENRefused}
                  refState={refEditorENRefused}
                />
                {erros.enRefused && (
                  <p style={{ color: "red" }}>{erros.enRefused}</p>
                )}
              </Row>
            </Collapse>

            <Row style={{ marginTop: 10 }}>
              <SectionComponent
                sectionName={"Templates de email em Espanhol"}
                setIsOpen={setSectionEspanhol}
                isOpen={sectionEspanhol}
              />
            </Row>
            <Collapse isOpen={sectionEspanhol}>
              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Solicitação *
                  <div id={`email-solicitation`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips" >
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-solicitation`}
                    >
                      Email enviado no momento que o participante concluí a
                      montagem do quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>

                <TextEditor2
                  defaultState={editorESSolicitation}
                  refState={refEditorESSolicitation}
                />
                {erros.esSolicitation && (
                  <p style={{ color: "red" }}>{erros.esSolicitation}</p>
                )}
              </Row>

              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Aceite *
                  <div id={`email-accept`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-accept`}
                    >
                      Email enviado no momento que o participante aceita o
                      convite para divisão de quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor2
                  defaultState={editorESAccept}
                  refState={refEditorESAccept}
                />
                {erros.esAccept && (
                  <p style={{ color: "red" }}>{erros.esAccept}</p>
                )}
              </Row>

              <Row style={{ marginTop: 10 }}>
                <label className="form-labe label-tooltip">
                  Email Recusa *
                  <div id={`email-refused`} style={{ height: "16px", paddingRight: "7px" }}>
                    <div onClick={() => openModal("PT")} className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target={`email-refused`}
                    >
                      Email enviado no momento que o participante recusa o
                      convite para divisão de quarto. <br />
                      (Clique para mais instruções)
                    </UncontrolledTooltip>
                  </div>
                </label>
                <TextEditor2
                  defaultState={editorESRefused}
                  refState={refEditorESRefused}
                />
                {erros.esRefused && (
                  <p style={{ color: "red" }}>{erros.esRefused}</p>
                )}
              </Row>
            </Collapse>

            <Row style={{ marginTop: 10 }}>
              <SectionComponent
                sectionName={"Texto Quartos Indisponíveis"}
                setIsOpen={setSectionRooms}
                isOpen={sectionRooms}
              />
            </Row>

            <Collapse isOpen={sectionRooms}>
              <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                  <label className="form-labe label-tooltip">
                    Texto Quartos Indisponíveis Português
                    <div id={`rooms-unavailable`} style={{ height: "16px", paddingRight: "7px" }}>
                      <div className="show-tips">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: "#6f42c1" }}
                        placement="right"
                        target={`rooms-unavailable`}
                      >
                        Texto exibido na tela Tipo de Quarto quando o match não
                        tiver mais nenhum quarto no estoque.
                      </UncontrolledTooltip>
                    </div>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={formData.roomPt}
                    onChange={(e) => {
                      setFormData((state) => {
                        return {
                          ...state,
                          roomPt: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                  <label className="form-labe label-tooltip">
                    Texto Quartos Indisponíveis Inglês
                    <div id={`rooms-unavailable`} style={{ height: "16px", paddingRight: "7px" }}>
                      <div className="show-tips">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: "#6f42c1" }}
                        placement="right"
                        target={`rooms-unavailable`}
                      >
                        Texto exibido na tela Tipo de Quarto quando o match não
                        tiver mais nenhum quarto no estoque.
                      </UncontrolledTooltip>
                    </div>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={formData.roomEn}
                    onChange={(e) => {
                      setFormData((state) => {
                        return {
                          ...state,
                          roomEn: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                  <label className="form-labe label-tooltip">
                    Texto Quartos Indisponíveis Espanhol
                    <div id={`rooms-unavailable`} style={{ height: "16px", paddingRight: "7px" }}>
                      <div className="show-tips">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: "#6f42c1" }}
                        placement="right"
                        target={`rooms-unavailable`}
                      >
                        Texto exibido na tela Tipo de Quarto quando o match não
                        tiver mais nenhum quarto no estoque.
                      </UncontrolledTooltip>
                    </div>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={formData.roomEs}
                    onChange={(e) => {
                      setFormData((state) => {
                        return {
                          ...state,
                          roomEs: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </Collapse>

            <Row style={{ marginTop: 10 }}>
              <SectionComponent
                sectionName={"Texto Rodapé"}
                setIsOpen={setSectionFooter}
                isOpen={sectionFooter}
              />
            </Row>

            <Collapse isOpen={sectionFooter}>
              <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                  <label className="form-labe label-tooltip">
                    Texto Rodapé Português
                    <div id={`baseboard`} style={{ height: "16px", paddingRight: "7px" }}>
                      <div className="show-tips">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: "#6f42c1" }}
                        placement="right"
                        target={`baseboard`}
                      >
                        Texto exibido no rodapé de todas as telas do match
                      </UncontrolledTooltip>
                    </div>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={formData.footerPt}
                    onChange={(e) => {
                      setFormData((state) => {
                        return {
                          ...state,
                          footerPt: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                  <label className="form-labe label-tooltip">
                    Texto Rodapé Inglês
                    <div id={`baseboard`} style={{ height: "16px", paddingRight: "7px" }}>
                      <div className="show-tips">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: "#6f42c1" }}
                        placement="right"
                        target={`baseboard`}
                      >
                        Texto exibido no rodapé de todas as telas do match
                      </UncontrolledTooltip>
                    </div>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={formData.footerEn}
                    onChange={(e) => {
                      setFormData((state) => {
                        return {
                          ...state,
                          footerEn: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                  <label className="form-labe label-tooltip">
                    Texto Rodapé Espanhol
                    <div id={`baseboard`} style={{ height: "16px", paddingRight: "7px" }}>
                      <div className="show-tips">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: "#6f42c1" }}
                        placement="right"
                        target={`baseboard`}
                      >
                        Texto exibido no rodapé de todas as telas do match
                      </UncontrolledTooltip>
                    </div>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={formData.footerEs}
                    onChange={(e) => {
                      setFormData((state) => {
                        return {
                          ...state,
                          footerEs: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </Collapse>

            <Row
              style={{
                width: "100%",
                margin: "20px 0 0 0",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <>
                <Button
                  color="danger"
                  style={{ width: 80, marginRight: 10 }}
                  onClick={() => toggle()}
                >
                  Fechar
                </Button>
                <Button
                  disabled={
                    showInputGroupA === null ||
                    showInputGroupB === null ||
                    formData.segregate === undefined
                  }
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  style={{ width: 80 }}
                  onClick={() => verifyErros()}
                >
                  Salvar
                </Button>
              </>
            </Row>
          </ModalBody>
        )}

        {isModalTooltip && <TooltipModal type={tooltipType} toggle={modalToggle} modal={isModalTooltip} />}
      </Modal>
    </>
  );
}
