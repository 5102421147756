/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

//redux
import { connect } from "react-redux";

//service
import { updateMenu } from "../../../utils/services/menus";
import { getGroups } from "../../../utils/services/group";
import { getUrlFiles } from "../../../utils/services/files";

function NewMenu(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  //state de erros
  const [nameErro, setNameErro] = useState("");
  const [urlErro, setUrlErro] = useState("");
  const [nameExErro, setNameExErro] = useState("");
  const [imageErro, setImageErro] = useState("");
  // Erros da edição do botão AR (não apagar)
  const [videoUrlErro, setVideoUrlErro] = useState("");
  const [imageMarcationErro, setImageMarcationErro] = useState("");
  const [positionErro, setPositionErro] = useState("");

  //icone
  const [imageView, setImageView] = useState(props.editMenu.backgroundImage);
  // state do preview da imagem da marcação (não apagar)
  const [imageMarcationView, setImageMarcationView] = useState(
    props.editMenu.imagemarker
  );

  const [image, setImage] = useState(props.editMenu.backgroundImage);
  // state do preview da imagem da marcação (não apagar)
  const [imageMarcation, setImageMarcation] = useState(
    props.editMenu.imagemarker
  );

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function handleGroupMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  const onImageChange = (event, setStateFunction) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setStateFunction(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  async function submitForm(e) {
    let icon = null;
    let marcationIcon = null;

    let urlImg = props.editMenu.backgroundImage;
    let urlImgMarker = props.editMenu.imagemarker;
    let expireDate = props.editMenu.expireDate;
    let expireDateRa = props.editMenu.expireDateRa;

    if (!props.editMenu.isDefault) {
      //nome vazio
      if (e.target[1].value === "") {
        setNameErro("O campo Nome é obrigatório.");
        return;
      } else setNameErro("");
      //url vazia
      if (e.target[2].value === "") {
        setUrlErro("O campo URL é obrigatório.");
        return;
      } else setUrlErro("");
      //nome de exibição vazio
      if (e.target[3].value === "") {
        setNameExErro("O campo Nome de Exibição é obrigatório.");
        return;
      } else setNameExErro("");

      if (imageView === props.editMenu.backgroundImage) {
        icon = imageView;
      } else {
        icon = e.target[4].files[0];
      }

      //imagem vazia
      if (icon === undefined) {
        setImageErro("O campo Ícone é obrigatório.");
        return;
      } else setImageErro("");
    } else {
      if (e.target[1].value === "") {
        setNameExErro("O campo Nome de Exibição é obrigatório.");
        return;
      } else setNameExErro("");

      if (imageView === props.editMenu.backgroundImage) {
        icon = imageView;
      } else {
        icon = e.target[2].files[0];
      }

      //imagem vazia
      if (icon === undefined) {
        setImageErro("O campo Ícone é obrigatório.");
        return;
      } else setImageErro("");

      //Erros ao editar campo default AR (não apagar)
      if (props.editMenu.linkUrl === "AR") {
        // Campo URL do vídeo vazio
        if (e.target[6].value === "") {
          setVideoUrlErro("O campo URL do vídeo é obrigatório.");
          return;
        } else setVideoUrlErro("");

        //Verifica se marcação está com o valor default
        if (imageMarcationView === props.editMenu.imagemarker) {
          marcationIcon = imageMarcationView;
        } else {
          marcationIcon = e.target[7].files[0];
        }
        // Campo Imagem de marcação vazio
        if (marcationIcon === undefined) {
          setImageMarcationErro("O campo Imagem da Marcação é obrigatório.");
          return;
        } else setImageMarcationErro("");

        // Campo Posição vazio
        if (e.target[8].value === "") {
          setPositionErro("O campo Posição é obrigatório.");
          return;
        } else setPositionErro("");
      }
    }

    //array de grupos
    var groupsArray = [];

    if (allGroups) {
      groups.map((e) => {
        groupsArray.push({ grupo: e.id });
      });
    } else {
      groupsMulti.map((e) => {
        groupsArray.push({ grupo: e.value });
      });
    }

    var data = new FormData();
    data.append("files", icon);
    data.append("files", marcationIcon);
    data.append("folder", "menu");
    data.append("eventId", props.idEvent);

    if (
      imageView !== props.editMenu.backgroundImage ||
      imageMarcationView !== props.editMenu.imagemarker
    ) {
      await getUrlFiles(data, props.state.global_user_data.data.token)
        .then((response) => {
          response.map((e) => {
            if (
              e.name === image?.target?.files[0]?.name &&
              image !== imageView
            ) {
              urlImg = e.url;
              expireDate = e.expires;
            }
            if (
              e.name === imageMarcation?.target?.files[0]?.name &&
              imageMarcation !== imageMarcationView
            ) {
              urlImgMarker = e.url;
              expireDateRa = e.expires;
            }
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    const objButton = {
      order: props.editMenu.order,
      columnSize: 1,
      description: !props.editMenu.isDefault
        ? e.target[1].value
        : props.editMenu.description,
      nameExhibition: !props.editMenu.isDefault
        ? e.target[3].value
        : e.target[1].value,
      fontColor: "#000000",
      backgroundColor: "#fff",
      typeBtn: "default",
      linkUrl: !props.editMenu.isDefault
        ? e.target[2].value
        : props.editMenu.linkUrl,
      isEmbeded: !props.editMenu.isDefault
        ? e.target[7].checked
        : e.target[5].checked,
      isVisible: !props.editMenu.isDefault
        ? e.target[6].checked
        : e.target[4].checked,
      grupos: groupsArray,
      menuId: props.idButton,
      isDefault: props.editMenu.isDefault,
      id: props.editMenu.id,
      videora: props.editMenu.linkUrl === "AR" ? e.target[6].value : null,
      positionra: props.editMenu.linkUrl === "AR" ? e.target[8].value : null,
      backgroundImage: urlImg,
      imagemarker: urlImgMarker,
      expireDate: expireDate,
      expireDateRa: expireDateRa,
    };

    var menuData = await updateMenu(
      objButton,
      props.state.global_user_data.data.token
    );

    if (menuData.message === "BUTTON_UPDATED") {
      setsuccess_dlg(true);
    }
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function funcGroups() {
    var groupsArray = [];
    if (groups?.length > 0) {
      props.editMenu.grupos
        .filter((e) => e.enabled === true)
        .map((a) => {
          groupsArray.push({ label: a.grupo.name, value: a.grupo.id });
        });
      if (groups?.length === groupsArray.length) {
        groupsArray = [];
        groupsArray.push({ label: "TODOS", value: "todos" });
        setAllGroups(true);
      }
    }
    setGroupsMulti(groupsArray);
  }

  useEffect(() => {
    getGroupsFunc();
  }, []);

  useEffect(() => {
    funcGroups();
  }, [groups]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.getAllMenus();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Botão editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle} onClick={() => ""}>
        Editar Botão
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">ID do Botão</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editMenu.id}
                  disabled
                />
                <p style={{ color: "red" }}>{nameErro}</p>
              </div>
            </Col>
          </Row>

          {props.editMenu.isDefault === false && (
            <div>
              <Row>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Nome</label>
                    <input
                      readOnly
                      className="form-control"
                      type="text"
                      defaultValue={props.editMenu.description}
                      disabled
                    />
                    <p style={{ color: "red" }}>{nameErro}</p>
                  </div>
                </Col>
              </Row>

              <Row style={{ paddingTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">URL</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editMenu.linkUrl}
                    />
                    <p style={{ color: "red" }}>{urlErro}</p>
                  </div>
                </Col>
              </Row>
            </div>
          )}

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Nome de Exibição*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editMenu.nameExhibition}
                />
                <p style={{ color: "red" }}>{nameExErro}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
              <label
                htmlFor="formFile"
                className="form-label"
                style={{ marginBottom: 0 }}
              >
                Ícone*
              </label>
              <label htmlFor="inputImage" style={{ marginBottom: 0 }}>
                <i className="bx bx-pencil" style={{ cursor: "pointer" }} />
              </label>

              <input
                id="inputImage"
                type={"file"}
                onChange={(e) => {
                  setImage(e);
                  onImageChange(e, setImageView);
                }}
                style={{ display: "none" }}
              />
            </Col>

            <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
              <img alt="edit" src={imageView} style={{ width: 70 }} />
            </Col>
          </Row>
          <p style={{ color: "red" }}>{imageErro}</p>

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Grupos</label>
                <Select
                  value={groupsMulti}
                  placeholder={"Selecione"}
                  isMulti={true}
                  onChange={(e) => {
                    handleGroupMulti(e);
                  }}
                  options={[
                    {
                      options: [
                        { label: "TODOS", value: "todos" },
                        ...groups.map((e) => {
                          return { label: e.name, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col md={6}>
              <div className="form-check form-check-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  defaultChecked={props.editMenu.isVisible ? true : false}
                />
                <label>Visível</label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check form-check-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.editMenu.isEmbeded ? true : false}
                />
                <label>Externo</label>
              </div>
            </Col>
          </Row>
          {props.editMenu.isDefault && props.editMenu.linkUrl === "AR" && (
            <>
              <Row style={{ paddingTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label htmlFor="video-url" className="form-label">
                      URL do vídeo*
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="video-url"
                      defaultValue={props.editMenu.videora}
                    />
                    <p style={{ color: "red" }}>{videoUrlErro}</p>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
                  <label
                    htmlFor="formFile"
                    className="form-label"
                    style={{ marginBottom: 0 }}
                  ></label>
                  Imagem da Marcação*
                  <label
                    htmlFor="inputMarcationImage"
                    style={{ marginBottom: 0 }}
                  >
                    <i className="bx bx-pencil" style={{ cursor: "pointer" }} />
                  </label>
                  <input
                    id="inputMarcationImage"
                    type={"file"}
                    onChange={(e) => {
                      setImageMarcation(e);
                      onImageChange(e, setImageMarcationView);
                    }}
                    style={{ display: "none" }}
                  />
                </Col>

                <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
                  <img
                    alt="Imagem da Marcação"
                    src={imageMarcationView}
                    style={{ width: 70 }}
                  />
                </Col>
              </Row>
              <p style={{ color: "red" }}>{imageMarcationErro}</p>
              <Row style={{ paddingTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Posição*</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editMenu.positionra}
                    />
                    <p style={{ color: "red" }}>{positionErro}</p>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 10,
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewMenu);
