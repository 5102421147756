/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

//redux
import { connect } from "react-redux";

//service
import { newMenu } from "../../../utils/services/menus";
import { getGroups } from "../../../utils/services/group";
import { getUrlFiles } from "../../../utils/services/files";

function NewMenu(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de erros
  const [nameErro, setNameErro] = useState("");
  const [urlErro, setUrlErro] = useState("");
  const [nameExErro, setNameExErro] = useState("");
  const [imageErro, setImageErro] = useState("");

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function handleGroupMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  async function submitForm(e) {
    //nome vazio
    if (e.target[0].value === "") {
      setNameErro("O campo Nome é obrigatório.");
      return;
    } else setNameErro("");
    //url vazia
    if (e.target[1].value === "") {
      setUrlErro("O campo URL é obrigatório.");
      return;
    } else setUrlErro("");
    //nome de exibição vazio
    if (e.target[2].value === "") {
      setNameExErro("O campo Nome de Exibição é obrigatório.");
      return;
    } else setNameExErro("");
    //imagem vazia
    if (e.target[3].files[0] === undefined) {
      setImageErro("O campo Ícone é obrigatório.");
      return;
    } else setImageErro("");

    //array de grupos
    var groupsArray = [];

    if (allGroups) {
      groups.map((e) => {
        groupsArray.push({ grupo: e.id });
      });
    } else {
      groupsMulti.map((e) => {
        groupsArray.push({ grupo: e.value });
      });
    }
    let urlImg = "";
    let expireDate = null;
    let expireDateRa = null;

    var data = new FormData();
    data.append("files", e.target[3].files[0]);
    data.append("folder", "menu");
    data.append("eventId", props.idEvent);

    await getUrlFiles(data, props.state.global_user_data.data.token)
      .then((response) => {
        response.map((e) => {
          urlImg = e.url;
          expireDate = e.expires;
        });
      })
      .catch((error) => {
        // console.log(error);
      });
    const form = {
      order: props.menus.length + 1,
      columnSize: 1,
      description: e.target[0].value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, "")
        .toUpperCase(),
      nameExhibition: e.target[2].value,
      fontColor: "#000000",
      backgroundColor: "#fff",
      backgroundImage: urlImg,
      typeBtn: "default",
      linkUrl: e.target[1].value,
      isEmbeded: e.target[6].checked,
      isVisible: e.target[5].checked,
      menuId: props.idButton,
      isDefault: false,
      grupos: groupsArray,
      expireDate: expireDate,
      expireDateRa: expireDateRa,
    };

    var menuData = await newMenu(form, props.state.global_user_data.data.token);
    if (menuData.message === "created") {
      setsuccess_dlg(true);
    }
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        // console.log(response);
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getGroupsFunc();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.getAllMenus();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Botão criado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Botão</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Nome*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{nameErro}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">URL*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{urlErro}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Nome de Exibição*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{nameExErro}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Ícone*</label>
                <input className="form-control" type="file" id="formFile" />
                <p style={{ color: "red" }}>{imageErro}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Grupos</label>
                <Select
                  value={groupsMulti}
                  placeholder={"Selecione"}
                  isMulti={true}
                  onChange={(e) => {
                    handleGroupMulti(e);
                  }}
                  options={[
                    {
                      options: [
                        { label: "TODOS", value: "todos" },
                        ...groups.map((e) => {
                          return { label: e.name, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col md={6}>
              <div className="form-check form-check-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  defaultChecked
                />
                <label>Visível</label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check form-check-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked
                />
                <label>Externo</label>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 10,
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewMenu);
