import axios from "axios";
import { urlBase } from "./config/index";

function createSuperScreen(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/transmissions`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function editSuperScreen(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/transmissions/update`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getSuperScreen(jwt, id, offset, limit, search, columnSearch = "") {
  var config = {
    method: "get",
    url: `${urlBase}/transmissions/${id}/event?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteSuperScreen(id, jwt) {
  var config = {
    method: "delete",
    url: `${urlBase}/transmissions/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getChatID(id, jwt) {
  var config = {
    method: "get",
    url: `${urlBase}/transmissions/chatid/${id}/event`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createSurvey(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/transmissions/survey`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function updateSurvey(data, jwt, id) {
  var config = {
    method: "put",
    url: `${urlBase}/transmissions/survey/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getSurveys(
  eventId,
  idTransmission,
  offset,
  limit,
  search,
  columnsFilter,
  jwt
) {
  var config = {
    method: "get",
    url: `${urlBase}/transmissions/survey/${eventId}/event/${idTransmission}/transmission?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnsFilter}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteSurvey(surveyId, jwt) {
  var config = {
    method: "delete",
    url: `${urlBase}/transmissions/survey/${surveyId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getSurveysReport(
  eventId,
  idTransmission,
  idSurvey,
  offset,
  limit,
  search,
  columnsFilter,
  jwt
) {
  var config = {
    method: "get",
    url: `${urlBase}/transmissions/survey/report/${eventId}/event/${idTransmission}/transmission/${idSurvey}/survey?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnsFilter}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  createSuperScreen,
  editSuperScreen,
  getSuperScreen,
  deleteSuperScreen,
  getChatID,
  createSurvey,
  getSurveys,
  deleteSurvey,
  updateSurvey,
  getSurveysReport,
};
