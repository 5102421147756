import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
// service
import { editScreenProducts } from "../../../utils/services/products";

function ModalNewProduct(props) {
  const { isOpen, toggle } = props;
  // erro
  const [erroProduct, setErroProduct] = useState("");
  // alerta
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  function verifyErros(e) {
    if (!e.target[0].value) {
      setErroProduct('Esse campo é obrigatório');
      return;
    } else setErroProduct('');

    submitForm(e);
  }

  function submitForm(e) {
    const data = {
      name: e.target[0].value,
    };

    editScreenProducts({
      jwt: props.state.global_user_data.data.token,
      data: data,
      id: props.data.id,
    }).then((res) => {
      if (res.message === 'PRODUCT_UPDATED') {
        props.getAllProducts();
        setSuccessAlert(true);
      }
      if (res.message === 'PRODUCT_ALREADY_EXISTS') {
        setErrorAlert(true);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Editado"
          onConfirm={() => {
            toggle();
            setSuccessAlert(false);
          }}
        >
          Produto editado com sucesso
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title={`Não foi possível criar o produto`}
          error
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="danger"
          onCancel={() => {
            setErrorAlert(false);
          }}
        >
          Já existe um produto com esse nome.
        </SweetAlert>
      ) : null}
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Editar Produto</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyErros(e);
            }}
          >
            <Row>
              <Col md={12}>
                <label className="form-label">Produto (Sistema) *</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.data.name}
                />
                {erroProduct && <p style={{ color: 'red' }}>{erroProduct}</p>}
              </Col>
            </Row>

            <Row
              style={{
                width: '100%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>

              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
                type="submit"
              >
                Salvar
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalNewProduct);
