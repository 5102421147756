import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
// service
import {
  getConfigQuota,
  changeConfigQuota,
} from "../../../utils/services/quotas";
import { getUrlFiles } from "../../../utils/services/files";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// componentes
import ColorPicker from "../../../components/ColorPicker/ColorPicker";

export default function ModalConfigSiteQuota(props) {
  const { isOpen, toggle, jwt, eventId } = props;
  // dados do form
  const [color, setColor] = useState("#006aa7");
  const [img, setImg] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [description, setDescription] = useState(false);
  const [localization, setLocalization] = useState(false);
  const [idConfigQuota, setIdConfigQuota] = useState(null);
  // tipo de requisição
  const [typeRequets, setTypeRequets] = useState("post");
  // alerta
  const [success, setSuccess] = useState(false);
  // erros
  const [imgError, setImgError] = useState("");
  // carregamento
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);

  async function submitForm() {
    if (!img && !imgUrl) {
      setImgError("A imagem do evento é obrigatório");
      return;
    } else setImgError("");

    var urlImg = null;

    if (!img && imgUrl) {
      urlImg = imgUrl;
    } else {
      var imgDoc = new FormData();

      imgDoc.append("files", img.target.files[0]);

      await getUrlFiles(imgDoc, jwt).then((res) => {
        urlImg = res[0].url;
      });
    }

    const data = {
      eventId: eventId,
      image: urlImg,
      color: color,
      showDescription: description,
      showLocalization: localization,
    };

    await changeConfigQuota({
      jwt: jwt,
      data: data,
      id: idConfigQuota,
      type: typeRequets,
    }).then((res) => {
      if (res.message === "created" || res.message === "updated") {
        setSuccess(true);
      }
    });
  }

  async function getConfigQuotaFunc() {
    await getConfigQuota(jwt, eventId).then((res) => {
      if (res.message === "findAllConfigs" && res.findAll.count > 0) {
        const dataConfig = res.findAll.data[0];

        setImgUrl(dataConfig.image);
        setColor(dataConfig.color);
        setDescription(dataConfig.showDescription);
        setLocalization(dataConfig.showLocalization);
        setIdConfigQuota(dataConfig.id);
        setTypeRequets("put");
      }
    });
    // .catch((error) => console.log("error quota", error));

    setLoadingForm(false);
  }

  useEffect(() => {
    getConfigQuotaFunc();
  }, []);

  return (
    <>
      {success ? (
        <SweetAlert
          success
          title={"Concluído"}
          onConfirm={() => {
            toggle();
          }}
        >
          {"Configurações salvas com sucesso!"}
        </SweetAlert>
      ) : null}
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Configurar Site Cotas</ModalHeader>
        <ModalBody>
          {loadingForm ? (
            <p style={{ textAlign: "center" }}>Carregando ...</p>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              <Row>
                <Col md={!img && imgUrl ? 6 : 12}>
                  <div>
                    <Label htmlFor="formFile" className="form-label">
                      Imagem do Evento*
                    </Label>
                    <Input
                      className="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => setImg(e)}
                    />
                    {imgError && <a style={{ color: "red" }}>{imgError}</a>}
                  </div>
                </Col>

                {!img && imgUrl && (
                  <Col md={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <img src={imgUrl} style={{ width: 100, height: 100 }} />
                      <i
                        className="mdi mdi-trash-can icon-trash"
                        onClick={() => setImgUrl(null)}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              <Row>
                <ColorPicker
                  classOfContainer="bg-new-event-menu-pickr"
                  labelText="Cor do Evento"
                  setStateFunc={setColor}
                  defaultColor={color}
                />
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Col md={6}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkDescription"
                      onChange={(e) => setDescription(e.target.checked)}
                      defaultChecked={description}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="checkDescription"
                    >
                      Exibir Descrição
                    </label>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkLocalization"
                      onChange={(e) => setLocalization(e.target.checked)}
                      defaultChecked={localization}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="checkLocalization"
                    >
                      Exibir Localização
                    </label>
                  </div>
                </Col>
              </Row>

              <Row
                style={{
                  width: "100%",
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="danger"
                  style={{ width: 80, marginRight: 10 }}
                  onClick={() => toggle()}
                >
                  Fechar
                </Button>

                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  style={{ width: 80 }}
                >
                  Salvar
                </Button>
              </Row>
            </form>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
