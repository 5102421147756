import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { urlDomain } from '../utils/services/config';
//redux
import { connect } from 'react-redux';

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation';

//service login
import { loginForm, loginSso } from '../utils/services/loginUser';

//
import MicrosoftLogin from 'react-microsoft-login';
import ButtonMsal from './ButtonMsal';

function Login(props) {
  const history = useNavigate();
  const [emailValue, setEmailValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [userFound, setUserFound] = useState('');
  const [viewPass, setViewPass] = useState(false);
  const [errorHasCotas, setErrorHasCotas] = useState(false);
  const authHandler = async (err, data, msalInstance) => {
    console.log(err, data);
    if (!err && data) {
      let email = data?.account.username;

      const userData = await loginSso({
        email: email.replace(/ /gi, ''),
      });
      if (userData.message === 'NOT_FOUND') {
        setUserFound('Usuário não encontrado');
      }
      if (userData.message === 'NOT_AUTHORIZED') {
        setUserFound('Senha incorreta');
      }

      if (userData.message === 'FIRST_ACCESS_REQUIRED') {
        setUserFound(
          "O email informado nunca realizou login no Inteegra Plus, gere a sua senha a partir da opção 'Primeiro Acesso'",
        );
      }
      if (userData.message === 'SSO_TOKEN') {
        setUserFound('');
        props.saveUserGlobalData(userData);
        localStorage.setItem('authUser', Date.now());
        props.saveMsalInstance(msalInstance);
        history('/events');
      }
    }
  };

  const submitForm = async () => {
    setErrorHasCotas(false);
    setUserFound('');
    if (emailValue.includes('@comgas.com.br')) {
      setErrorHasCotas(true);
      return;
    }
    if (emailValue === '' || passValue === '') {
      setUserFound('Digite um email e senha');
      return;
    }

    var userData = await loginForm({
      email: emailValue.replace(/ /gi, ''),
      senha: passValue,
    });

    if (userData.message === 'NOT_AUTHORIZED') {
      setUserFound('Senha incorreta');
    }

    if (userData.message === 'NOT_FOUND') {
      setUserFound('Usuário não encontrado');
    }

    if (userData.message === 'FIRST_ACCESS_REQUIRED') {
      setUserFound(
        "O email informado nunca realizou login no Inteegra Plus, gere a sua senha a partir da opção 'Primeiro Acesso'",
      );
    }

    if (userData.message === 'found') {
      setUserFound('');
      props.saveUserGlobalData(userData);
      localStorage.setItem('authUser', Date.now());
      history('/events');
    }
  };

  return (
    <div className='p-2'>
      <p style={{ color: 'red' }}>{userFound}</p>
      {errorHasCotas && (
        <p style={{ color: 'red' }}>
          Para acessar o sistema utilize a opção Acessar com SSO da tela de
          login
        </p>
      )}
      <AvForm className='form-horizontal'>
        <div className='mb-3'>
          <Input
            className='form-control'
            placeholder='Insira seu email'
            type='text'
            required
            onChange={(e) => setEmailValue(e.target.value)}
            value={emailValue}
          />
        </div>

        <div
          className='mb-3'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Input
            type={!viewPass ? 'password' : 'text'}
            required
            placeholder='Insira sua senha'
            onChange={(e) => setPassValue(e.target.value)}
          />
          <a onClick={() => setViewPass(!viewPass)}>
            {viewPass ? (
              <i className='mdi mdi-eye-off-outline icon-password' />
            ) : (
              <i className='mdi mdi-eye-outline icon-password' />
            )}
          </a>
        </div>

        <div className='mt-3 d-grid '>
          <button
            className='btn btn-primary btn-block waves-effect waves-light'
            type='submit'
            onClick={submitForm}
          >
            Entrar
          </button>
        </div>

        <div className='mt-3 d-grid justify-content-center'>
          <MicrosoftLogin
            tenantUrl='https://login.microsoftonline.com/a38e94f2-456e-47b2-b626-d54f18cebc92'
            clientId={'53a156ae-3a15-46ff-a8d0-1c0594e360e3'}
            prompt='login'
            authCallback={authHandler}
            redirectUri={`https://admin-plus.${urlDomain}/sso?token`}
            postLogoutRedirectUri='/'
          >
            <ButtonMsal />
          </MicrosoftLogin>
        </div>

        <div className='mt-4 text-center'>
          <p
            className='text-muted'
            style={{ cursor: 'pointer' }}
            onClick={() => props.setScreen('_c')}
          >
            <i className='mdi mdi-lock me-1' /> Esqueceu a senha?
          </p>
        </div>

        <div className='mt-4 text-center'>
          <p
            className='text-muted'
            style={{ cursor: 'pointer' }}
            onClick={() => props.setScreen('_b')}
          >
            Primeiro acesso
          </p>
        </div>
      </AvForm>
    </div>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    saveUserGlobalData: (data) => {
      return dispatch({ type: 'SAVE_GLOBAL_USER_DATA', data });
    },
    saveMsalInstance: (data) => {
      console.log(data);
      return dispatch({ type: 'MSAL_INSTANCE', data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
