import React, { useState, useEffect } from "react";
import WaitExport from "../../../components/WaitExport";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//redux
import { connect } from "react-redux";

//paginação
import Pagination from "../../../components/Paginationv3";

import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";

//service
import {
  getMatch,
  getStockRooms,
  delMatch,
  sendInviteConfirmation,
  getMatchNoRoom,
} from "../../../utils/services/match";

import { format } from "date-fns";

import useDebounce from "../../../utils/hooks/useDebounce";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import StockCard from "./StockCard";
import ModalEditStock from "./ModalEditStock";
import ModalLinksEmbeded from "./ModalLinksEmbeded";
import ModalConfig from "./ModalConfig";
import ModalExport from "./ModalExport";
import ModalExportNoRoom from "./ModalExportNoRoom";
import ModalPersonalizeMatch from "./ModalPersonalizeMatch";
import ModalCreateMatch from "./ModalCreateMatch";

const roomType = {
  4: "Quadruplo",
  3: "Triplo",
  2: "Duplo",
};

function Ranking(props) {
  const location = useLocation();
  //state do modal
  const [modal, setModal] = useState(false);
  const [modalLinksEmbeded, setModalLinksEmbded] = useState(false);
  const [extractPointsModal, setExtractPointsModal] = useState(false);
  const [modalConfig, setModalConfig] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const [modalExportNoRoom, setModalExportNoRoom] = useState(false);
  const [modalCreateMatch, setModalCreateMatch] = useState(false);

  const [randomMatchUsers, setRandomMatchUsers] = useState(false);
  //state de pesquisa
  const [search, setSearch] = useState("");
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(1);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const debouncedSearch = useDebounce(search, 300);
  //ranking export
  const [confirmDelete, setConfirmDelete] = useState(false);
  // state player points
  const [selectedMatch, setSelectedMath] = useState("");
  //count
  const [count, setCount] = useState(0);
  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [isOpenMatch, setIsOpenMatch] = useState(false);
  //modais
  const [modalEditStock, setModalEditStock] = useState(false);

  const [stockRooms, setStockRooms] = useState([]);

  //dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);

  //
  const [confirmPending, setConfirmPending] = useState(false);

  //wait
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  const [position, setPosition] = useState("0%");
  const [pendingUsers, setPendingUsers] = useState(0);
  const [amountNoRoom, setAmountNoRoom] = useState(0);
  const [roomAmount, setRoomAmount] = useState(0);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function toggleModal() {
    setModal(!modal);
  }

  function toggleModalCreateMatch() {
    setModalCreateMatch(!modalCreateMatch);
  }
  function toggleEditStock() {
    setModalEditStock(!modalEditStock);
    getStock();
  }

  function toggleLinksEmbeded() {
    setModalLinksEmbded(!modalLinksEmbeded);
  }

  function toggleExtractPointsModal() {
    setExtractPointsModal(!extractPointsModal);
  }
  async function deleteMatch(id) {
    await delMatch({
      id: id,
      jwt: props.state.global_user_data.data.token,
    })
      .then((response) => {
        getAllMatchFunc();
        getStock();
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  async function getStock() {
    await getStockRooms({
      eventId: location.state.idEvent,
      jwt: props.state.global_user_data.data.token,
    })
      .then((response) => {
        setRoomAmount(response.filter((e) => e.disponivel > 0).length);
        setStockRooms(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  async function setAllUsersCompleted() {
    setExportInProgress(true);
    await getMatch({
      eventId: location.state.idEvent,
      jwt: props.state.global_user_data.data.token,
      offset: 1,
      limit: 9999999,
      search: "",
    })
      .then(async (response) => {
        const resultados = response.data.reduce((acc, e) => {
          const ids = [];

          for (let i = 1; i <= 4; i++) {
            const statusKey = `login_status_${i}`;
            const idKey = `login_id_${i}`;

            if (e[statusKey] === "pending_accept" && e[idKey] !== null) {
              ids.push(e[idKey]);
            }
          }

          if (ids.length > 0) {
            acc.push(ids);
          }

          return acc;
        }, []);
        for (let r = 0; r < resultados.length; r++) {
          for (let s = 0; s < resultados[r].length; s++) {
            await sendInviteConfirmation({
              eventId: location.state.idEvent,
              roommateId: resultados[r][s],
              type: "completed",
              idiom: "ptBr",
              sendEmail: false,
              jwt: props.state.global_user_data.data.token,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setFinishExport(true);
    setPosition(`${100}%`);
  }

  async function getAllMatchFunc() {
    await getMatchNoRoom({
      eventId: location.state.idEvent,
      limit: 100000000,
      offset: 1,
      jwt: props.state.global_user_data.data.token,
    }).then(async (res) => {
      const { count, data } = res;
      setAmountNoRoom(count);
    });

    await getMatch({
      eventId: location.state.idEvent,
      jwt: props.state.global_user_data.data.token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
    })
      .then((response) => {
        setData(response.data);
        setCountData(response?.data?.length);
        setCount(response.count);

        const resultados = response.data.reduce((acc, e) => {
          const ids = [];

          for (let i = 1; i <= 4; i++) {
            const statusKey = `login_status_${i}`;
            const idKey = `login_id_${i}`;

            if (e[statusKey] === "pending_accept" && e[idKey] !== null) {
              ids.push(e[idKey]);
            }
          }

          if (ids.length > 0) {
            acc.push(ids);
          }

          return acc;
        }, []);
        setPendingUsers(resultados.length);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function getCardStock() {
    return stockRooms.map((e) => (
      <StockCard type={e.descricao} stock={e.disponivel} used={e.preenchido} />
    ));
  }

  useEffect(() => {
    getAllMatchFunc();
    getStock();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <div>
      <WaitExport
        customFinishTitle={"Preenchimento concluído."}
        customHeaderText={"Preenchendo Quartos"}
        customBodyText={`Em breve o preenchimento dos quartos será concluído, aguarde alguns instantes.`}
        isOpen={exportInProgress}
        name="Participantes sem Quarto"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={() => {
          getAllMatchFunc();
        }}
        setExportInProgress={setExportInProgress}
        position={position}
      />
      {modalCreateMatch && (
        <ModalCreateMatch
          isOpen={modalCreateMatch}
          toggle={toggleModalCreateMatch}
          data={stockRooms}
          eventId={location.state.idEvent}
          updateTables={() => {
            getAllMatchFunc();
            getStock();
          }}
        />
      )}
      {modalEditStock && (
        <ModalEditStock
          isOpen={modalEditStock}
          toggle={toggleEditStock}
          data={stockRooms}
          eventId={location.state.idEvent}
        />
      )}

      {modalLinksEmbeded && (
        <ModalLinksEmbeded
          isOpen={modalLinksEmbeded}
          toggle={toggleLinksEmbeded}
          eventId={location.state.idEvent}
        />
      )}

      {modalConfig && (
        <ModalConfig
          isOpen={modalConfig}
          toggle={() => setModalConfig(!modalConfig)}
          eventId={location.state.idEvent}
          jwt={props.state.global_user_data.data.token}
        />
      )}

      {modalExport && (
        <ModalExport
          isOpen={modalExport}
          toggle={() => setModalExport(!modalExport)}
          jwt={props.state.global_user_data.data.token}
          eventId={location.state.idEvent}
          count={count}
        />
      )}

      {modalExportNoRoom && (
        <ModalExportNoRoom
          isOpen={modalExportNoRoom}
          toggle={() => setModalExportNoRoom(!modalExportNoRoom)}
          jwt={props.state.global_user_data.data.token}
          eventId={location.state.idEvent}
        />
      )}

      {randomMatchUsers && (
        <ModalPersonalizeMatch
          roomAmount={roomAmount}
          isOpen={randomMatchUsers}
          toggle={() => setRandomMatchUsers(!randomMatchUsers)}
          jwt={props.state.global_user_data.data.token}
          eventId={location.state.idEvent}
          updateTables={() => {
            getAllMatchFunc();
            getStock();
          }}
        />
      )}

      {confirmDelete ? (
        <SweetAlert
          title={`Deseja deletar o registro?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteMatch(selectedMatch.id);
            setConfirmDelete(false);
          }}
          onCancel={() => setConfirmDelete(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      ) : null}

      {confirmPending ? (
        <SweetAlert
          title={`Deseja confirmar todos os participantes pendentes?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setAllUsersCompleted();
            setConfirmPending(false);
          }}
          onCancel={() => setConfirmPending(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      ) : null}

      <Card>
        <CardBody>
          <div className="groups-match">
            <a className="title-theme-match">
              Personalizar Match de Hospedagem
            </a>

            <div className="group-btn-match header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                style={{ marginRight: 10 }}
                onClick={() => setModalLinksEmbded(true)}
              >
                Links Para Embedar
              </Button>

              <Button
                color="warning"
                className="btn-rounded waves-effect waves-light events-button-add"
                style={{ marginRight: 10 }}
                onClick={() => setModalEditStock(true)}
              >
                Editar Estoque
              </Button>

              <Button
                color="warning"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalConfig(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                  className="dripicons-gear"
                />
                <a style={{ display: "flex", alignItems: "center" }}>
                  Configurações
                </a>
              </Button>
            </div>
          </div>

          <div className="mobile-btn-section" style={{ width: "100%" }}>
            <a className="title-theme-match">Match de Participante</a>

            <SectionComponent
              sectionName="Opções"
              setIsOpen={setIsOpenMatch}
              isOpen={isOpenMatch}
            />
            <Collapse isOpen={isOpenMatch}>
              <Row className="mobile-btns-top">
                <div onClick={() => setModalLinksEmbded(true)}>
                  Links Para Embedar
                </div>
              </Row>

              {/* <Row className="mobile-btns-top">
                <div>Personalizar Match</div>
              </Row> */}

              <Row className="mobile-btns-top">
                <div onClick={() => setModalEditStock(true)}>
                  Editar Estoque
                </div>
              </Row>

              <Row className="mobile-btns-top">
                <div>Configurações</div>
              </Row>
            </Collapse>
          </div>

          <section className={"stock-card-container"}>
            {stockRooms && getCardStock()}
          </section>

          <div className="groups-match">
            <a className="title-theme-match">Relatório de Hospedagem</a>

            {/* <div className="group-btn-match">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                style={{ marginRight: 10 }}
                onClick={() => generateExcell() > setExportInProgress(true)}
              >
                Exportar Relatório
              </Button>
            </div> */}
          </div>

          {/* <div className="mobile-btn-section" style={{ width: "100%" }}>
            <a className="title-theme-match">Match de Participante</a>

            <SectionComponent
              sectionName="Opções"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
            <Collapse isOpen={isOpen}>
              <Row className="mobile-btns-top">
                <div>Editar Estoque</div>
              </Row>
            </Collapse>
          </div> */}

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpenReport}
                isOpen={isOpenReport}
              />
              <Collapse isOpen={isOpenReport}>
                {data && data.length > 0 && (
                  <Row className="mobile-btns-top">
                    <div onClick={() => setModalExport(true)}>
                      Exportar Relatório de Quartos
                    </div>
                  </Row>
                )}

                <Row className="mobile-btns-top">
                  <div onClick={() => setModalExportNoRoom(true)}>
                    Exportar Participantes sem Quarto
                  </div>
                </Row>
              </Collapse>
            </div>

            <div className="header-btns-container">
              {/* <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                style={{ marginRight: 10 }}
                onClick={() => setRandomMatchUsers(true)}
              >
                Gerar Quartos Automaticamente
              </Button> */}
              {/* {data && data.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  style={{ marginRight: 10 }}
                  onClick={() => setModalExport(true)}
                >
                  Exportar Relatório de Quartos
                </Button>
              )} */}
              {/* <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                style={{ marginRight: 10 }}
                onClick={() => setModalExportNoRoom(true)}
              >
                Exportar Participantes sem Quarto
              </Button> */}
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                direction={"down"}
              >
                <DropdownToggle caret>Opções</DropdownToggle>
                <DropdownMenu
                  right
                  style={{ height: "auto", overflow: "hidden" }}
                >
                  {amountNoRoom > 0 && (
                    <DropdownItem onClick={() => setModalExportNoRoom(true)}>
                      <i
                        style={{
                          marginRight: 10,
                        }}
                        className="dripicons-forward"
                      />
                      Exportar Participantes sem Quarto
                    </DropdownItem>
                  )}
                  {data && data.length > 0 && (
                    <DropdownItem onClick={() => setModalExport(true)}>
                      <i
                        style={{
                          marginRight: 10,
                        }}
                        className="dripicons-forward"
                      />
                      Exportar Relatório de Quartos
                    </DropdownItem>
                  )}

                  {amountNoRoom > 0 && (
                    <DropdownItem onClick={() => setModalCreateMatch(true)}>
                      <i
                        style={{
                          marginRight: 10,
                        }}
                        className="dripicons-plus"
                      />
                      Criar Quarto
                    </DropdownItem>
                  )}
                  {/* {amountNoRoom > 0 && (
                    <DropdownItem onClick={() => setRandomMatchUsers(true)}>
                      <i
                        style={{
                          marginRight: 10,
                        }}
                        className="dripicons-swap"
                      />
                      Gerar Quartos Automaticamente
                    </DropdownItem>
                  )} */}
                  {pendingUsers > 0 && (
                    <DropdownItem onClick={() => setConfirmPending(true)}>
                      <i
                        style={{
                          marginRight: 10,
                        }}
                        className="dripicons-checkmark"
                      />
                      Confirmar Todos Pendentes
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <Table>
              <thead className="table-light">
                <tr>
                  <th>Hóspede 1</th>
                  <th>Hóspede 2</th>
                  <th>Hóspede 3</th>
                  <th>Hóspede 4</th>
                  <th>Tipo de Quarto</th>
                  <th style={{ textAlign: "center" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((e, i) => (
                    <tr
                      key={i}
                      style={{ cursor: "pointer" }}
                      onDoubleClick={() => {}}
                    >
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display:
                                e.login_nome_1 == null ? "none" : "block",
                              height: 10,
                              width: 10,
                              borderRadius: 50,
                              backgroundColor:
                                e.login_status_1 === "completed"
                                  ? "#8bc34a"
                                  : "#ffc107",
                              marginRight: 3,
                            }}
                          />
                          {e.login_nome_1 == null ? "-" : e.login_nome_1}
                        </div>
                      </td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display:
                                e.login_nome_2 == null ? "none" : "block",
                              height: 10,
                              width: 10,
                              borderRadius: 50,
                              backgroundColor:
                                e.login_status_2 === "completed"
                                  ? "#8bc34a"
                                  : "#ffc107",
                              marginRight: 3,
                            }}
                          />
                          {e.login_nome_2 == null ? "-" : e.login_nome_2}
                        </div>
                      </td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display:
                                e.login_nome_3 == null ? "none" : "block",
                              height: 10,
                              width: 10,
                              borderRadius: 50,
                              backgroundColor:
                                e.login_status_3 === "completed"
                                  ? "#8bc34a"
                                  : "#ffc107",
                              marginRight: 3,
                            }}
                          />
                          {e.login_nome_3 == null ? "-" : e.login_nome_3}
                        </div>
                      </td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display:
                                e.login_nome_4 == null ? "none" : "block",
                              height: 10,
                              width: 10,
                              borderRadius: 50,
                              backgroundColor:
                                e.login_status_4 === "completed"
                                  ? "#8bc34a"
                                  : "#ffc107",
                              marginRight: 3,
                            }}
                          />
                          <span>
                            {e.login_nome_4 == null ? "-" : e.login_nome_4}
                          </span>
                        </div>
                      </td>
                      <td>
                        {e.room_size === 4 && "Quadruplo"}
                        {e.room_size === 3 && "Triplo"}
                        {e.room_size === 2 && "Duplo"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedMath(e);
                            setConfirmDelete(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={data}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={count}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Ranking);
