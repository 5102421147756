import axios from "axios";
import { urlBase } from "./config";
async function getAllUploadApp(
  jwt,
  offset,
  limit,
  eventId,
  search,
  columnSearch
) {
  try {
    const config = {
      method: 'get',
      url: `${urlBase}/uploadapp/${eventId}/event/?offset=${offset}&limit=${limit}${
        search ? `&search=${search}&columnSearch=${columnSearch}` : ''
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}
export { getAllUploadApp };
