/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
// axios
import axios from "axios";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//service
import { editEvent } from "../../utils/services/events";
import { getEventType } from "../../utils/services/eventType";
//redux
import { connect } from "react-redux";

function EditEvent(props) {
  const { isOpen, toggle, currentEventSelected } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //campos do form
  const [title, setTitle] = useState(currentEventSelected.title);
  const [description, setDescription] = useState(
    currentEventSelected.description
  );
  const [optionLocalization, setOptionLocalization] = useState(
    currentEventSelected.eventFormat === "digital"
      ? ""
      : currentEventSelected.localizations[0].description
  );
  const [latitude, setLatitude] = useState(
    currentEventSelected.eventFormat === "digital"
      ? ""
      : currentEventSelected.localizations[0].latitude
  );
  const [longitude, setLongitude] = useState(
    currentEventSelected.eventFormat === "digital"
      ? ""
      : currentEventSelected.localizations[0].longitude
  );
  const [editLatLon, setEditLatLon] = useState(false);

  const [selectedGroup, setselectedGroup] = useState(
    currentEventSelected.eventFormat
  );
  // tipos de eventos
  const [eventTypes, setEventTypes] = useState([]);
  const [eventTypesSelected, setEventTypesSelected] = useState(
    currentEventSelected.category
  );

  const [startDate, setStartDate] = useState(currentEventSelected.startDate);
  const [endDate, setEndtDate] = useState(currentEventSelected.endDate);
  const [startHour, setStartHour] = useState(currentEventSelected.startHour);
  const [endHour, setEndHour] = useState(currentEventSelected.endHour);
  const [showCompany, setShowCompany] = useState(
    currentEventSelected.showCompany
  );
  const [showOffice, setShowOffice] = useState(
    currentEventSelected.showJobPosition
  );
  const [productsSelected, setProductsSelected] = useState([]);
  //produtos
  const [productsList, setProductsList] = useState([]);
  //data formatada
  const [formattedDateStart, setFormattedDateStart] = useState("");
  const [formattedDateEnd, setFormattedDateEnd] = useState("");
  // localização
  const [localizationSelected, setLocalizationSelected] =
    useState(optionLocalization);
  //erro dos campos obrigatórios
  const [titleError, setTitleError] = useState("");
  const [dateStartError, setDateStartError] = useState("");
  const [productsError, setProductsError] = useState("");
  const [eventFormatError, setEventFormatError] = useState("");
  // const [menuLogoError, setMenuLogoError] = useState("");
  const [localizationError, setLocalizationError] = useState("");
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  // dados da requisição
  const token = props.state.global_user_data.data.token;

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function handleSelectGroup(e) {
    setselectedGroup(e);
  }

  function setProductCheck(item, check) {
    var products = productsSelected;

    if (check) {
      products.push(item.idmainproduct);
    } else {
      products = products.filter((e) => e != item.idmainproduct);
    }

    setProductsSelected(products);
  }

  function getProductChecked(item) {
    var product = currentEventSelected.product.filter(
      (e) => e.id === item.idmainproduct
    );

    if (product.length > 0) {
      return true;
    } else return false;
  }

  const submitEdit = async () => {
    if (title === "") {
      setTitleError("O campo Nome é obrigatório.");
      return;
    } else setTitleError("");

    if (!selectedGroup || selectedGroup === "Selecionar") {
      setEventFormatError("Selecione o formato do evento");
      return;
    } else setEventFormatError("");

    if (selectedGroup != "digital" && !localizationSelected) {
      setLocalizationError("Insira um local");
      return;
    } else setLocalizationError("");

    if (startDate === "") {
      setDateStartError("O campo Data de Início é obrigatório.");
      return;
    } else setDateStartError("");

    if (productsSelected.length === 0) {
      setProductsError("Selecione ao menos um produto");
      return;
    } else setProductsError("");

    const descriptionLocalization =
      selectedGroup?.value !== "digital" ? optionLocalization : "";

    const objEvento = {
      codEvent: "",
      description: description,
      title: title,
      category: eventTypesSelected === "Selecione" ? "" : eventTypesSelected,
      eventFormat: selectedGroup,
      startDate: new Date(`${startDate}T00:01`).toISOString(),
      endDate: new Date(
        `${endDate === "" ? startDate : endDate}T00:01`
      ).toISOString(),
      startHour: startHour !== "" ? startHour : "00:00",
      endHour: endHour !== "" ? endHour : "00:00",
      enabled: true,
      localizations: [{ description: descriptionLocalization }],
      products: productsSelected,
      latitude: latitude,
      longitude: longitude,
      showCompany: showCompany,
      showJobPosition: showOffice,
    };

    await editEvent(objEvento, token, currentEventSelected.id).then((res) => {
      // console.log("ok", res);
      if (res.message === "updated") {
        setsuccess_dlg(true);
      }
    });
    // .catch((error) => console.log("erro", error));
  };

  function selectedLocale(l) {
    setLocalizationSelected(l);
    document.getElementById("localInput").value = l.description;
    setOptionLocalization(l.description);
  }

  function localization(e) {
    setLocalizationSelected("");
    axios
      .get(
        `https://us-central1-inteegra-ping.cloudfunctions.net/GoogleAutoComplete?search=${e}&language=pt`
      )
      .then((localizations) => {
        if (localizations.data.success.status === "OK") {
          setOptionLocalization(localizations);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function getProductsSelected() {
    var listProductsSelected = [];

    currentEventSelected.product.map((e) => {
      listProductsSelected.push(e.id);
    });

    setProductsSelected(listProductsSelected);
  }

  function getProducts() {
    var listProducts = [];

    props.state.global_user_data.data.foundProducts.map((product) => {
      var filter = listProducts.filter(
        (e) => e.namemainproduct === product.namemainproduct
      );

      if (filter.length === 0) {
        listProducts.push({
          namemainproduct: product.namemainproduct,
          idmainproduct: product.idmainproduct,
        });
      }
    });

    setProductsList(
      listProducts.filter((f) => f.namemainproduct !== "Telas de Configuração")
    );
  }

  async function getListEventType() {
    await getEventType({
      jwt: token,
      offset: 1,
      limit: 1000,
      search: "",
      active: "",
    }).then((res) => {
      if (res.message === "findEventTypes") {
        var listTypes = [];
        var searchTypes = res.findEventTypesData.data.filter(
          (e) => e.id === currentEventSelected.category
        );

        if (searchTypes[0] && !searchTypes[0].active) {
          listTypes = res.findEventTypesData.data.filter((e) => e.active);
          listTypes.push(searchTypes[0]);
        } else {
          listTypes = res.findEventTypesData.data.filter((e) => e.active);
        }

        setEventTypes(listTypes);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  function getDateFunc() {
    if (startDate) {
      var data = new Date(startDate);
      var dia = String(data.getDate()).padStart(2, "0");
      var mes = String(data.getMonth() + 1).padStart(2, "0");
      var ano = data.getFullYear();
      var dataAtual = ano + "-" + mes + "-" + dia;

      setFormattedDateStart(dataAtual);
      setStartDate(dataAtual);
    }

    if (endDate) {
      var data = new Date(endDate);
      var dia = String(data.getDate()).padStart(2, "0");
      var mes = String(data.getMonth() + 1).padStart(2, "0");
      var ano = data.getFullYear();
      var dataAtual = ano + "-" + mes + "-" + dia;

      setFormattedDateEnd(dataAtual);
      setEndtDate(dataAtual);
    }
  }

  useEffect(() => {
    getProducts();
    getProductsSelected();
    getDateFunc();
    getListEventType();
  }, [currentEventSelected]);

  useEffect(() => {}, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.getAllEvents();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Evento editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle} onClick={() => ""}>
        Editar Evento
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div>
              <label className="form-label"> ID do Evento</label>
              <input
                className="form-control"
                type="text"
                value={currentEventSelected.id}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={6}>
            <div>
              <label className="form-label"> Nome do Evento*</label>
              <input
                className="form-control"
                type="text"
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <p style={{ color: "red" }}>{titleError}</p>
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <label className="form-label">Tipo de evento</label>
              <select
                className="form-control"
                onChange={(e) => {
                  setEventTypesSelected(e.target.value);
                }}
              >
                <option value={"Selecione"}>Selecione</option>
                {eventTypes.length > 0 &&
                  eventTypes.map((e, i) => (
                    <option
                      key={i}
                      value={e.id}
                      selected={currentEventSelected.category === e.id}
                    >
                      {e.type}
                    </option>
                  ))}
              </select>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <div>
              <label className="form-label">Descrição</label>
              <textarea
                className="form-control"
                type="text"
                defaultValue={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <div>
              <label className="form-label">Formato do Evento*</label>
              <select
                className="form-control"
                onChange={(e) => {
                  handleSelectGroup(e.target.value);
                  setEditLatLon(false);
                }}
              >
                <option
                  selected={
                    currentEventSelected.eventFormat === "presencial"
                      ? true
                      : false
                  }
                  value="presencial"
                >
                  Presencial
                </option>
                <option
                  selected={
                    currentEventSelected.eventFormat === "digital"
                      ? true
                      : false
                  }
                  value="digital"
                >
                  Digital
                </option>
                <option
                  selected={
                    currentEventSelected.eventFormat === "hibrido"
                      ? true
                      : false
                  }
                  value="hibrido"
                >
                  Híbrido
                </option>
              </select>
              <p style={{ color: "red" }}>{eventFormatError}</p>
            </div>
          </Col>
        </Row>

        {selectedGroup !== "digital" && (
          <>
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Localização*</label>
                  <input
                    className="form-control"
                    type="text"
                    id="localInput"
                    defaultValue={optionLocalization}
                    onChange={(e) => {
                      localization(e.target.value);
                      setLatitude("");
                      setLongitude("");
                    }}
                  />
                  <p style={{ color: "red" }}>{localizationError}</p>
                </div>
                {optionLocalization.data && optionLocalization.data.success ? (
                  <ul className="localization">
                    {optionLocalization.data.success.predictions.map((l) => {
                      return (
                        <li
                          key={l.description}
                          className="localizationLi"
                          onClick={() => selectedLocale(l)}
                        >
                          {l.description}
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div
                  className="form-check form-switch"
                  style={{
                    borderTop: "1px solid #dbdbdb",
                    borderBottom: "1px solid #dbdbdb",
                    marginTop: 5,
                    marginBottom: 5,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`checkLatLon`}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEditLatLon(true);
                      } else setEditLatLon(false);
                    }}
                  />
                  <label className="form-check-label" htmlFor={`checkLatLon`}>
                    Editar Latitude/Longitude
                  </label>
                </div>
              </Col>
            </Row>

            {editLatLon && (
              <Row style={{ marginTop: 2 }}>
                <Col lg={6}>
                  <div>
                    <label className="form-label">Latitude</label>
                    <input
                      value={latitude}
                      className="form-control"
                      type="text"
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <div>
                      <label className="form-label">Longitude</label>
                      <input
                        value={longitude}
                        className="form-control"
                        type="text"
                        onChange={(e) => setLongitude(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}

        <Row style={{ marginTop: 10 }}>
          <Col lg={6}>
            <div>
              <label className="form-label">Data Inicio*</label>
              <input
                className="form-control"
                type="date"
                defaultValue={formattedDateStart}
                // defaultValue="2019-08-19"
                onChange={(e) => setStartDate(e.target.value)}
              />
              <p style={{ color: "red" }}>{dateStartError}</p>
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <label className="form-label">Data Fim</label>
              <input
                className="form-control"
                type="date"
                defaultValue={formattedDateEnd}
                onChange={(e) => setEndtDate(e.target.value)}
              />
              {/* <p style={{ color: "red" }}>{dateEndError}</p> */}
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={6}>
            <div>
              <label className="form-label">Hora Inicio</label>
              <input
                className="form-control"
                type="time"
                defaultValue={startHour}
                onChange={(e) => setStartHour(e.target.value)}
              />
              {/* <p style={{ color: "red" }}>{hourStartError}</p> */}
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <label className="form-label">Hora Fim</label>
              <input
                className="form-control"
                type="time"
                defaultValue={endHour}
                onChange={(e) => setEndHour(e.target.value)}
              />
              {/* <p style={{ color: "red" }}>{hourEndError}</p> */}
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col md={6}>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={showCompany}
                id="checkCompany"
                onChange={(e) => setShowCompany(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="checkCompany">
                Exibir Empresa
              </label>
            </div>
          </Col>

          <Col md={6}>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={showOffice}
                id="checkOffice"
                onChange={(e) => setShowOffice(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="checkOffice">
                Exibir Cargo
              </label>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 18 }}>
          <p style={{ marginBottom: 14, fontWeight: "bold" }}>
            Selecione o(s) produto(s) do evento:*
          </p>
        </Row>

        {productsList &&
          productsList.sort().map((item) => (
            <Row>
              <Col md={12}>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={item.idmainproduct}
                    defaultChecked={getProductChecked(item)}
                    onChange={(e) => {
                      setProductCheck(item, e.target.checked);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={item.idmainproduct}
                  >
                    {item.namemainproduct}
                  </label>
                </div>
              </Col>
            </Row>
          ))}

        {productsError && (
          <Row style={{ color: "red" }}>
            <p>{productsError}</p>
          </Row>
        )}

        <Row
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="success"
            className="btn btn-danger waves-effect waves-light"
            style={{ width: 80, marginRight: 5 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            style={{ width: 80 }}
            onClick={submitEdit}
          >
            Salvar
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    updateEventsList: (data) => {
      return dispatch({ type: "UPDATE_EVENT_LIST", data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
