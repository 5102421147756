import writeXlsxFile from "write-excel-file";
import { getSchedule } from "../../../utils/services/schedule";
import { formatData } from "../../../utils/helpers/files/formatDate"

export async function createXlsx({
  jwt,
  id,
  limit,
  countData,
  setFinishExport,
}) {
  const schema = [
    {
      column: "Id",
      type: String,
      value: (schedule) => schedule.id,
    },
    {
      column: "Título",
      type: String,
      value: (schedule) => schedule.title,
    },
    {
      column: "Descrição",
      type: String,
      value: (schedule) => schedule.description,
    },
    {
      column: "Data",
      type: String,
      value: (schedule) => formatData(schedule.startDate),
    },
    {
      column: "Hora Início",
      type: String,
      value: (schedule) => schedule.startHour,
    },
    {
      column: "Hora Fim",
      type: String,
      value: (schedule) => schedule.endHour,
    },
    {
      column: "Foto",
      type: String,
      value: (schedule) => schedule.picture,
    },
    {
      column: "Tipo/Local da Programação",
      type: String,
      value: (schedule) => schedule.typeOrLocation,
    },
    {
      column: "Cor da Programação",
      type: String,
      value: (schedule) => schedule.color,
    },
    {
      column: "Grupos/IDs",
      type: String,
      value: (schedule) => schedule.groups.map((e) => e.grupoId).join(", "),
    },
    {
      column: "Grupos/Descrição",
      type: String,
      value: (schedule) => schedule.groups.map((e) => e.grupo.name).join(", "),
    },

  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getSchedule({
      jwt,
      id,
      offset: i,
      limit,
      search: "",
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    // return

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.agenda.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "Agenda.xlsx",
    });
  }
}
