import axios from "axios";
import { urlBase } from './config';

function updateProfile(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/admin/updateuser`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
}

export { updateProfile };
