import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";

import { useLocation } from "react-router-dom";

//paginação
import Pagination from "../../../components/Paginationv3";

//componentes
import ModalNewNotification from "./ModalNewNotification";
import ModalEdidNotification from "./ModalEditNotification";
import ModalNewNotificationPublic from "./ModalNewNotificationPublic";
//service
import {
  getNotification,
  deleteNotification,
  getNotificationPublic,
} from "../../../utils/services/notification";
import { getMobileAppsByEvent } from "../../../utils/services/mobileApps";

import useDebounce from "../../../utils/hooks/useDebounce";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";

// const participantsData = [
//   {
//     title: "title 1",
//     message: "message",
//     date: "date",
//   },
//   {
//     title: "title 2",
//     message: "message",
//     date: "date",
//   },
//   {
//     title: "title 3",
//     message: "message",
//     date: "date",
//   },
// ];

function Notification(props) {
  const location = useLocation();
  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewNotification, setModalNewNotification] = useState(false);
  const [modalNewNotificationPublic, setModalNewNotificationPublic] =
    useState(false);
  const [modalEditNotification, setModalEditNotification] = useState(false);
  //deletar notificação
  const [notificationDelete, setNotificationDelete] = useState();
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  //editar notificação
  const [editNotification, setEditNotification] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  //state das notificações
  const [notification, setNotification] = useState([]);
  const [notificationPublic, setNotificationPublic] = useState([]);
  //estado com a quantidade total de items na API
  const [countData, setCountData] = useState(1);
  const [countDataPublic, setCountDataPublic] = useState(1);

  const [itemsPerPage, setItemsPerPage] = useState(10);

  // collapse
  const [isOpen, setIsOpen] = useState(false);

  const debouncedSearch = useDebounce(search, 300);
  const [isPublic, setIsPublic] = useState(false);

  const [mobileapps, setMobileApps] = useState([]);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("title");

  const columnsFilter = [
    { name: "Título", value: "title", type: "text" },
    { name: "Mensagem", value: "message", type: "text" },
    { name: "Data", value: "date", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  function toggleNewNotification() {
    setModalNewNotification(!modalNewNotification);
  }

  function toggleNewNotificationPublic() {
    setModalNewNotificationPublic(!modalNewNotificationPublic);
  }
  function toggleEditNotification() {
    setModalEditNotification(!modalEditNotification);
  }

  async function getAllNotification() {
    var formData = await getNotification({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    });

    if (formData.message === "NOTIFICATION_FINDED") {
      setNotification(formData.findOneNotificationData.data);
      setCountData(formData.findOneNotificationData.count);
    }
  }

  async function getAllNotificationPublic() {
    var formData = await getNotificationPublic({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    });

    if (formData.message === "NOTIFICATION_PUBLIC_FINDED") {
      setNotificationPublic(formData.findOneNotificationData.data);
      setCountDataPublic(formData.findOneNotificationData.count);
    }
  }
  async function confirmDelete() {
    var formData = await deleteNotification(
      props.state.global_user_data.data.token,
      notificationDelete.id
    );

    if (formData.message === "NOTIFICATION_DISABLED") {
      setconfirm_alert(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletado");
      setdynamic_description("Notificação Deletada");
    }
  }
  function formatData(startDate) {
    var data = new Date(startDate);
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "/" + mes + "/" + ano;

    return dataAtual;
  }
  function formatHour(startDate) {
    return startDate.split("T")[1].slice(0, 5);
  }

  async function getMobileAppEvent() {
    await getMobileAppsByEvent({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
    })
      .then((response) => {
        setMobileApps(response.findOneMobileAppData);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getAllNotification();
    getAllNotificationPublic();
    getMobileAppEvent();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  useEffect(() => {
    getAllNotification();
    getAllNotificationPublic();
  }, [isPublic]);

  function getAppName(bundle) {
    if (mobileapps.length < 1) return "";
    return mobileapps?.filter((e) => e.bundle === bundle)[0]?.name;
  }

  return (
    <div>
      {modalNewNotification && (
        <ModalNewNotification
          isOpen={modalNewNotification}
          toggle={toggleNewNotification}
          idEvent={location.state.idEvent}
          getAllNotification={getAllNotification}
        />
      )}
      {modalNewNotificationPublic && (
        <ModalNewNotificationPublic
          isOpen={modalNewNotificationPublic}
          toggle={toggleNewNotificationPublic}
          idEvent={location.state.idEvent}
          getAllNotificationPublic={getAllNotificationPublic}
        />
      )}
      {modalEditNotification && (
        <ModalEdidNotification
          isOpen={modalEditNotification}
          toggle={toggleEditNotification}
          editNotification={editNotification}
          idEvent={location.state.idEvent}
          getAllNotification={getAllNotification}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar ${notificationDelete.title}`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDelete();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            getAllNotification();
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      <Card>
        <CardBody>
          <CardTitle>
            {!isPublic
              ? "Notificações Push (Privadas)"
              : "Notificações Push (Públicas)"}
          </CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Row className="mobile-btns-top">
                <div
                  onClick={() => {
                    setIsPublic(!isPublic);
                  }}
                >
                  {isPublic ? "Filtrar: Privados" : "Filtrar: Públicos"}
                </div>
              </Row>
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewNotification(true)}>
                    Criar Notificação Privada
                  </div>
                </Row>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewNotificationPublic(true)}>
                    Criar Notificação Pública
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="secondary"
                className="btn-rounded waves-effect waves-light events-button-add-lg"
                onClick={() => {
                  setIsPublic(!isPublic);
                }}
              >
                {isPublic ? "Filtrar: Privados" : "Filtrar: Públicos"}
              </Button>
              {isPublic && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => {
                    setIsPublic(true);
                    setModalNewNotificationPublic(true);
                  }}
                >
                  Push Público
                </Button>
              )}
              {!isPublic && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => {
                    setIsPublic(false);
                    setModalNewNotification(true);
                  }}
                >
                  Push Privado
                </Button>
              )}
            </div>
          </div>

          {!isPublic && (
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Título</th>
                    <th>Mensagem</th>
                    <th>Data do agendamento / envio</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {notification
                    .filter((e) => e.title.toLocaleLowerCase())
                    .map((e, i) => (
                      <tr key={i}>
                        <th scope="row">{e.title}</th>
                        <td>{e.text}</td>
                        <td>{`${formatData(e.date)}  ${e.hour} `}</td>
                        <td>
                          <i
                            className="mdi mdi-pencil"
                            style={{
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditNotification(e);
                              setModalEditNotification(true);
                            }}
                          />
                          {""}
                          {new Date(e.date) > new Date() && e.schedule ? (
                            <i
                              className="mdi mdi-trash-can"
                              style={{
                                color: "red",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (new Date(e.date) > new Date()) {
                                  setNotificationDelete(e);
                                  setconfirm_alert(true);
                                }
                              }}
                            />
                          ) : (
                            <i
                              className="mdi mdi-trash-can"
                              style={{
                                color: "#adb5bd",
                                fontSize: 17,
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}

          {isPublic && (
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Título</th>
                    <th>Mensagem</th>
                    <th>Data do envio</th>
                    <th>Aplicativo</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationPublic
                    .filter((e) => e.title.toLocaleLowerCase())
                    .map((e, i) => (
                      <tr key={i}>
                        <th scope="row">{e.title}</th>
                        <td>{e.message}</td>
                        <td>{`${formatData(e.createdAt)}  ${formatHour(
                          e.createdAt
                        )}`}</td>
                        <td>{getAppName(e.bundleId)}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={!isPublic ? notification : notificationPublic}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={!isPublic ? countData : countDataPublic}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Notification);
