import axios from "axios";
import { urlBaseProduct } from "./config";
const baseUrl = urlBaseProduct;

function getProducts({
  jwt,
  offset,
  limit = 10,
  mainProduct,
  columnSearch = 'name',
  search = '',
}) {
  var config = {
    method: 'get',
    url: `${baseUrl}?offset=${
      offset ? offset : 1
    }&limit=${limit} &mainProduct=${mainProduct}&columnSearch=${columnSearch}&search=${search}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createScreenProducts({ jwt, data }) {
  var config = {
    method: "post",
    url: baseUrl,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function editScreenProducts({ jwt, data, id }) {
  var config = {
    method: "put",
    url: `${baseUrl}/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteScreenProducts({ jwt, id }) {
  var config = {
    method: "delete",
    url: `${baseUrl}/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  getProducts,
  createScreenProducts,
  deleteScreenProducts,
  editScreenProducts,
};
