import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Collapse, Row, Button, Table } from "reactstrap";
import { useLocation } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
//service
import {
  getFunctions,
  deleteFunctions,
} from "../../../utils/services/functions";
//components
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import Pagination from "../../../components/Paginationv3";
import ModalNewFunc from "./ModalNewFunc";
import ModalEditFunc from "./ModalEditFunc";
import ModalQRCode from "./ModalQRCode";

import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from '../../../components/SearchFilters';

function RegistrationFunctions(props) {
  // collapse
  const [isCollapse, setIsCollapse] = useState(false);
  //pesquisa
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  //modal new func
  const [modalNewFunc, setModalNewFunc] = useState(false);
  //modal editFunc
  const [modalEditFunc, setModalEditFunc] = useState(false);
  const [editFunction, setEditFunction] = useState();
  //delete state
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [delFunc, setDelFunc] = useState();
  //state get functions
  const [functions, setFunctions] = useState([]);
  //modal qrcode
  const [modalQrcode, setModalQrcode] = useState(false);

  const location = useLocation();
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('name');

  const columnsFilter = [
    { name: 'Nome', value: 'name', type: 'text' },
    { name: 'Tipo', value: 'type', type: 'text' },
    { name: 'Data Criação', value: 'date_create', type: 'date' },
    { name: 'Últ. Atualização', value: 'date_update', type: 'date' },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch('');
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split('/')
      .reverse()
      .join('/')
      .replace(/\//g, '-');
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      'date'
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  //date format
  const formatDate = (date) => {
    const formatedDate = date.split('T')[0].split('-').reverse().join('/');
    return formatedDate;
  };

  async function getAllFunc() {
    await getFunctions({
      limit: itemsPerPage,
      offset: currentPage > 0 ? currentPage : 1,
      id: location.state.idEvent,
      jwt: props.state.global_user_data.data.token,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    }).then((response) => {
      if (response.functions) {
        setFunctions(response.functions);
        setTotalItems(response.total);
      }
    });
  }

  async function funcDel() {
    await deleteFunctions({
      id: delFunc.id,
      jwt: props.state.global_user_data.data.token,
    }).then((response) => {
      setIsDeleteOpen(false);
      setSuccessDelete(true);
    });
  }

  useEffect(() => {
    getAllFunc();
  }, [debouncedSearch, itemsPerPage, currentPage]);
  return (
    <div>
      {isDeleteOpen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Você tem certeza que deseja excluir a função ${delFunc.description}?`}
          onConfirm={() => {
            funcDel();
          }}
          onCancel={() => {
            setIsDeleteOpen(false);
          }}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}
      {successDelete && (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            getAllFunc();
            setSuccessDelete(false);
          }}
        >
          {`Função deletada com sucesso`}
        </SweetAlert>
      )}
      {modalNewFunc && (
        <ModalNewFunc
          toggle={() => setModalNewFunc(false)}
          isOpen={modalNewFunc}
          idEvent={location.state.idEvent}
          getAllFunc={getAllFunc}
        />
      )}
      {modalEditFunc && (
        <ModalEditFunc
          toggle={() => setModalEditFunc(false)}
          isOpen={modalEditFunc}
          editFunction={editFunction}
          getAllFunc={getAllFunc}
        />
      )}
      {modalQrcode && (
        <ModalQRCode
          toggle={() => setModalQrcode(false)}
          isOpen={modalQrcode}
          editFunction={editFunction}
        />
      )}
      <Card>
        <CardBody>
          <CardTitle>Criar Função</CardTitle>
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10,
                width: '50px',
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  idEvent
                  onChange={(e) => setSearch(e.target.value)}
                  //   onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: '100%' }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: '0px' }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewFunc(true)}>
                    Criar Função JS
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewFunc(true)}
              >
                Criar Função JS
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome da Função</th>
                  <th>Tipo da Função</th>
                  <th>Data da Criação</th>
                  <th>Última Atualização</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {functions.length > 0 &&
                  functions
                    // .filter((func) => func.enabled === true)
                    .map((func, i) => (
                      <tr key={i}>
                        <td>{func.description}</td>
                        <td>{func.type}</td>
                        <td>{formatDate(func.createdAt)}</td>
                        <td>{formatDate(func.updatedAt)}</td>
                        <td>
                          <i
                            className="mdi mdi-eye"
                            style={{
                              color: 'blue',
                              fontSize: 17,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setEditFunction(func);
                              setModalQrcode(true);
                            }}
                          />
                          <i
                            className="mdi mdi-pencil"
                            title="Editar"
                            style={{
                              margin: '0 3px',
                              color: 'green',
                              fontSize: 17,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setEditFunction(func);
                              setModalEditFunc(true);
                            }}
                          />
                          <i
                            className="mdi mdi-trash-can"
                            title="Excluir"
                            style={{
                              color: 'red',
                              fontSize: 17,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setDelFunc(func);
                              setIsDeleteOpen(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: 'end' }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={functions}
          search={search}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(RegistrationFunctions);
