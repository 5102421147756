import axios from "axios";
import { urlBase, urlBaseImports } from "./config";

function getParticipantsLogistic({
  jwt,
  idEvent,
  offset,
  limit,
  search = "",
  status = "ATIVO",
  columnSearch = "",
}) {
  var config = {
    method: "get",
    url: `${urlBase}/events/${idEvent}/logistics/logins?offset=${offset}&limit=${limit}&search=${search}&status=${status}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function getParticipants({
  jwt,
  idEvent,
  offset,
  limit,
  search = "",
  status = "ATIVO",
  columnSearch = "",
}) {
  var config = {
    method: "get",
    url: `${urlBase}/login/loginbyevent/${idEvent}?offset=${offset}&limit=${limit}&search=${search}&status=${status}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function getLoginIdsToNotification({ jwt, offset, limit, data }) {
  var config = {
    method: "post",
    url: `${urlBase}/login/logintonotification/?offset=${offset}&limit=${limit}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
function newParticipant({
  email,
  nome,
  senha,
  titleQrCode,
  codeQrCode,
  infoAcess,
  token,
  id_evento,
  grupos = [],
  empresa,
  cpf,
  cargo,
  interesseChat,
  codigoInterno1,
  codigoInterno2,
  codigoInterno3,
}) {
  var data = JSON.stringify({
    email: email,
    nome: nome,
    senha: senha,
    foto: "",
    titleQrCode: titleQrCode,
    codeQrCode: codeQrCode,
    infoAcess: infoAcess,
    idEvento: id_evento,
    grupos: grupos,
    empresa: empresa,
    cpf: cpf,
    cargo: cargo,
    interesseChat: interesseChat,
    codigoInterno1: codigoInterno1,
    codigoInterno2: codigoInterno2,
    codigoInterno3: codigoInterno3,
  });
  var config = {
    method: "post",
    url: `${urlBase}/credentials`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function updateParticipant(data, token) {
  var config = {
    method: "post",
    url: `${urlBase}/login/updateuser`,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteParticipant(id, token, eventId) {
  var config = {
    method: "delete",
    url: `${urlBase}/credentials/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: { idEvent: eventId },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteAllParticipants({ jwt, eventId }) {
  var config = {
    method: "put",
    url: `${urlBase}/login/disableall/${eventId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function uploadFile(data, token, event) {
  var config = {
    method: "post",
    url: `${urlBase}/login/uploadcsv/${event}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function reactivateParticipant(event, login, token) {
  const data = {
    eventId: event,
    loginId: login,
  };
  var config = {
    method: "post",
    url: `${urlBase}/login/reactivate`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function createParticipant(data, token) {
  const config = {
    method: "post",
    url: `${urlBaseImports}/credentials`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // if (error.response) {
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      // } else if (error.request) {
      //   console.log(error.request);
      // } else {
      //   console.log('Error', error.message);
      // }
      return error.response.data;
    });
}

export {
  getParticipantsLogistic,
  getParticipants,
  getLoginIdsToNotification,
  newParticipant,
  updateParticipant,
  deleteParticipant,
  deleteAllParticipants,
  reactivateParticipant,
  uploadFile,
  createParticipant,
};
