import axios from "axios";
import { urlBase } from './config';

function getUsers({ jwt, offset, limit, search, columnSearch = '' }) {
  // console.log(search)
  var config = {
    method: 'get',
    url: `${urlBase}/admin?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteUser({ jwt, id }) {
  var config = {
    method: "delete",
    url: `${urlBase}/admin/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };
  
  return axios(config).then(function (response) {
      return response.data;
  }).catch(function (error) {
      return error.response.data
  });
 
}
function updateUser(data,jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/admin/updateuser`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // console.log(error)
      return error.response;
    });
 
}
function createUser({ nome, email, senha, profile }, jwt) {
  var data = JSON.stringify({ 
    nome,
    email,
    senha,
    profile
  })
  var config = {
    method: "post",
    url: `${urlBase}/signup`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    data
  };
  
  return axios(config).then(function (response) {
      return response.data;
  }).catch(function (error) {
      return error.response.data
  });
 
}


export {getUsers, deleteUser, updateUser, createUser}