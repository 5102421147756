import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Collapse,
  UncontrolledTooltip,
} from "reactstrap";

import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import { getCertificateByName, updateCertificate } from "../../../utils/services/certificate";
import { getUrlFiles } from "../../../utils/services/files";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

export default function ModalEditCertificate(props) {
  const { isOpen, toggle, certificate, eventId, refetch } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [tags, setTags] = useState(certificate.tags.map((tag) => tag.name));
  const [image, setImage] = useState(certificate.image);
  const [color, setColor] = useState(certificate.color);
  //error state
  const [nameError, setNameError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [fontSizeError, setFontSizeError] = useState(false);
  const [nameIsRegistered, setNameIsRegistered] = useState(false);
  // const [colorError, setColorError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [alignError, setAlignError] = useState(false);
  // collapse
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);

  const [success, setSuccess] = useState(false);
  const [newImg, setNewImg] = useState('');

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function addTags(newTags) {
    setTags([...new Set([...tags, newTags])]);
  }

  function removeTags(index) {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  }

  const onImageChange = (event, setStateFunction) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setStateFunction(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  async function submitForm(e) {
    if (!e.target[1].value) {
      setNameError(true);
      return;
    } else setNameError(false);

    if (!image) {
      setImageError(true);
      return;
    } else setImageError(false);

    if (!e.target[4].value) {
      setCollapseIsOpen(true);
      setFontSizeError(true);
      return;
    } else setFontSizeError(false);

    if (e.target[5].value === "Selecione") {
      setCollapseIsOpen(true);
      setAlignError(true);
      return;
    } else setAlignError(false);

    if (!e.target[6].value) {
      setCollapseIsOpen(true);
      setHeightError(true);
      return;
    } else setHeightError(false);

    if (!e.target[7].value) {
      setCollapseIsOpen(true);
      setWidthError(true);
      return;
    } else setWidthError(false);

    let registeredMessage = '';

    if (e.target[1].value !== certificate.name) {
      const nameAlreadyExists = await getCertificateByName({
        jwt,
        eventId,
        name: e.target[1].value,
      });

      registeredMessage = nameAlreadyExists.data.message;
    } else registeredMessage = '';

    if (registeredMessage === 'CERTIFICATE_FOUND') {
      setNameIsRegistered(true);
      return;
    } else setNameIsRegistered(false);
    
    let urlFile = undefined;
    let expireDate = certificate.expireDate;
    let imageId = null;

    if (newImg.files) {
      const formData = new FormData();
      formData.append('files', newImg.files[0]);
      formData.append('name', e.target[1].value);
      formData.append('folder', 'certificados');
      formData.append('eventId', eventId);
  
  
      await getUrlFiles(formData, jwt)
      .then((response) => {
        response.forEach(e => {
          urlFile = e.url;
          expireDate = e.expires;
          imageId = e.fileId;
        })
      })
      .catch((error) => {
        // console.log(error);
      });
    }

    const data = {
      name: e.target[1].value,
      eventId,
      imageId: imageId ? imageId : certificate.imageId,
      image: urlFile || image,
      fontSize: +e.target[4].value,
      textAlignment: e.target[5].value,
      heightPosition: +e.target[6].value,
      widthPosition: +e.target[7].value,
      color: color,
      expireImage: expireDate,
      tags: tags.map((tag) => ({name: tag})),
    };

    await updateCertificate({ data, jwt, id: certificate.id });

    setSuccess(true);
    refetch();
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus
      centered
      size="lg"
    >
      {success && (
        <SweetAlert
          success
          title="Edição de Certificado"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Certificado editado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Editar Certificado</ModalHeader>
      <ModalBody style={{ padding: 20 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row style={{ marginTop: 10 }}>
            <label className="form-label" style={{ padding: 0 }}>
              ID do Certificado
            </label>
            <input
              className="form-control"
              type="text"
              value={certificate?.id ? certificate.id : null}
              disabled
            />
          </Row>

          <Row style={{ paddingLeft: 0, paddingRight: 0 }}>
            <label className="form-label" style={{ padding: 0 }}>
              Nome*
            </label>
            <input className="form-control" type="text" defaultValue={certificate.name} />
            {nameError && (
              <p style={{ color: "red", padding: 0 }}>
                O campo Nome é obrigatório
              </p>
            )}
            {nameIsRegistered && (
              <p style={{ color: "red", padding: 0 }}>
                Já existe um certificado com esse nome
              </p>
            )}
          </Row>

          <Row style={{ marginTop: 10 }}>
            <label className="form-label" style={{ padding: 0 }}>
              Tags
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Digite e pressione a tecla Enter para adicionar a Tag"
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value !== "") {
                  addTags(e.target.value);
                  e.target.value = "";
                }
              }}
            />
          </Row>

          {tags?.length > 0 && (
            <Row>
              <div className="tag-container" style={{ padding: 0 }}>
                {tags.map((tags, index) => (
                  <div key={index} className="tag-wrapper">
                    <span className="tags-field">{tags}</span>
                    <i
                      className="mdi mdi-close"
                      onClick={() => removeTags(index)}
                    />
                  </div>
                ))}
              </div>
            </Row>
          )}

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col
              md={6}
              style={{ padding: 0, display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="formFile"
                className="form-label"
                style={{ marginBottom: 0, display: "flex" }}
              >
                Imagem*{" "}
                <div id="tooltipImgCertificate" style={{ paddingRight: "7px", height: "16px" }} >
                  <div className="show-tips">
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip
                    style={{ backgroundColor: "#6f42c1" }}
                    placement="right"
                    target="tooltipImgCertificate"
                  >
                    Tamanho 1000x550
                  </UncontrolledTooltip>
                </div>
              </label>
              <label htmlFor="inputImage" style={{ marginBottom: 0 }}>
                <i className="bx bx-pencil" style={{ cursor: "pointer", marginLeft: 5 }} />
              </label>
              <input
                id="inputImage"
                type={"file"}
                onChange={(e) => {
                  setNewImg({ files: e.target.files });
                  onImageChange(e, setImage);
                }}
                style={{ display: "none" }}
              />
            </Col>
            <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
              <img alt="edit" src={image} style={{ width: 70 }} />
            </Col>
            {imageError && (
              <p style={{ color: "red", padding: 0 }}>
                O campo Upload de Imagem é obrigatório
              </p>
            )}
          </Row>

          <Row style={{ marginBottom: 10 }}>
            <SectionComponent
              sectionName="Configurações do Nome dos Participantes"
              setIsOpen={setCollapseIsOpen}
              isOpen={collapseIsOpen}
            />
          </Row>

          <Collapse isOpen={collapseIsOpen}>
            <Row>
              <Col md={6} style={{ paddingLeft: 0 }}>
                <label className="form-label" style={{ padding: 0 }}>
                  Tamanho da Fonte*
                </label>
                <input className="form-control" type="text" defaultValue={certificate.fontSize} />
                {fontSizeError && (
                  <p style={{ color: "red", padding: 0 }}>
                    O campo Tamanho da Fonte é obrigatório
                  </p>
                )}
              </Col>

              <Col md={6} style={{ padding: 0 }}>
                <label className="form-label" style={{ padding: 0 }}>
                  Alinhamento do Texto*
                </label>
                <select className="form-control" defaultValue={certificate.textAlignment}>
                  <option>Selecione</option>
                  <option>Esquerda</option>
                  <option>Centro</option>
                  <option>Direita</option>
                </select>
                {alignError && (
                  <p style={{ color: "red", padding: 0 }}>
                    O campo Alinhamento do Texto é obrigatório
                  </p>
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={6} style={{ paddingLeft: 0 }}>
                <label className="form-label" style={{ padding: 0 }}>
                  Posição em Altura*
                </label>
                <input className="form-control" type="text" defaultValue={certificate.heightPosition}/>
                {heightError && (
                  <p style={{ color: "red", padding: 0 }}>
                    O campo Posição em Altura é obrigatório
                  </p>
                )}
              </Col>

              <Col md={6} style={{ padding: 0 }}>
                <label className="form-label" style={{ padding: 0 }}>
                  Posição Largura*
                </label>
                <input className="form-control" type="text" defaultValue={certificate.widthPosition} />
                {widthError && (
                  <p style={{ color: "red", padding: 0 }}>
                    O campo Posição Largura é obrigatório
                  </p>
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <label className="form-label" style={{ padding: 0 }}>
                Cor*
              </label>
              <ColorPicker
                classOfContainer="colorpiker-certificate"
                labelText=""
                setStateFunc={setColor}
                defaultColor={color}
              />
              {/* {colorError && (
                <p style={{ color: "red", padding: 0 }}>
                  O campo Cor é obrigatório
                </p>
              )} */}
            </Row>
          </Collapse>

          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              type="sumit"
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
