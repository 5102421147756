import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { createEventType } from "../../utils/services/eventType";

export default function ModalNewEventType(props) {
  const { isOpen, toggle, idContract, token, getListEventType } = props;
  const [typeError, setTypeError] = useState("");
  const [alertSuccess, setAlertSuccess] = useState(false);

  function verifyForm(e) {
    if (!e.target[0].value) {
      setTypeError("O campo Tipo de Evento é obrigatório");
      return;
    } else setTypeError("");

    submitForm(e);
  }

  async function submitForm(e) {
    const data = {
      contractId: idContract,
      type: e.target[0].value,
      active: true,
    };

    await createEventType(data, token)
      .then((res) => {
        if (res.message === "created") {
          setAlertSuccess(true);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  return (
    <>
      {alertSuccess && (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            getListEventType();
            toggle();
          }}
        >
          {"Tipo de Evento criado com sucesso!"}
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Novo Tipo de Evento</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyForm(e);
            }}
          >
            <Row>
              <Col lg={12}>
                <label className="form-label">Tipo de Evento*</label>
                <input className="form-control" type="text" />

                {typeError && <a style={{ color: "red" }}>{typeError}</a>}
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button
                color="danger"
                className="btn btn-danger waves-effect waves-light"
                style={{ width: 80, marginRight: "1rem" }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
              >
                Salvar
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
