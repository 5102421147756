import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { updateImg } from "../../utils/services/drive";

export default function ModalEditHtml(props) {
  const { isOpen, toggle } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  // campos do form
  const [nameHtml, setNameHtml] = useState(props.editDoc.name);
  const [tags, setTags] = useState([]);
  // state de erros
  const [nameError, setNameError] = useState("");
  // alerta
  const [confirmEdit, setConfirmEdit] = useState(false);

  function addTags(newTags) {
    setTags([...new Set([...tags, newTags])]);
  }

  function removeTags(index) {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  }

  async function submitForm(e) {
    if (!nameHtml) {
      setNameError("O campo Nome do HTML é obrigatório");
      return;
    } else setNameError("");

    const obj = {
      id: props.editDoc.id,
      name: nameHtml,
      tags: tags,
    };

    await updateImg(jwt, obj).then((res) => {
      console.log("ok", res);
      setConfirmEdit(true);
      props.getImages();
    });
  }

  useEffect(() => {
    if (props.editDoc) {
      setTags(props.editDoc.tags.map((tag) => tag.value));
    }
  }, []);
  return (
    <Modal isOpen={isOpen} autoFocus centered>
      {confirmEdit && (
        <SweetAlert
          success
          title="Editado"
          onConfirm={() => {
            setConfirmEdit(false);
            toggle();
          }}
        >
          Arquivo editado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Editar HTML</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <label
              className="form-label"
              onClick={() => console.log(props.editDoc)}
            >
              Nome do HTML*
            </label>
            <input
              className="form-control"
              type="text"
              defaultValue={nameHtml}
              onChange={(e) => setNameHtml(e.target.value)}
            />
            {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            <label className="form-label">Tags*</label>
            <input
              className="form-control"
              type="text"
              placeholder="Digite e pressione a tecla Enter para adicionar a Tag"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && e.target.value !== '') {
                  addTags(e.target.value);
                  e.target.value = '';
                }
              }}
            />
          </Col>
        </Row>

        {tags?.length > 0 && (
          <Row>
            <div className="tag-container">
              {tags.map((tags, index) => (
                <div key={index} className="tag-wrapper">
                  <span className="tags-field">{tags}</span>
                  <i
                    className="mdi mdi-close"
                    onClick={() => removeTags(index)}
                  />
                </div>
              ))}
            </div>
          </Row>
        )}

        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            <label className="form-label">Link do HTML*</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={props.editDoc.url}
            />
          </Col>
        </Row>

        <Row
          style={{
            width: '100%',
            marginTop: 15,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="danger"
            style={{ width: 80, marginRight: 10 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            style={{ width: 80 }}
            onClick={() => submitForm()}
          >
            Salvar
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}
