import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
} from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

//service
import { createHotel } from "../../../utils/services/hotel";

function NewHotel(props) {
  const { isOpen, toggle, participants } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de participantes
  // const [participants, setParticipants] = useState([]);
  const [participantSelected, setParticipantSelected] = useState();
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [dateArrivalError, setDateArrivalError] = useState("");
  const [timeArrivalError, setTimeArrivalError] = useState("");
  const [addresError, setAddresError] = useState("");

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const submitForm = async (e) => {
    //tratamento de erros
    if (!participantSelected || participantSelected === "Selecione") {
      setParticipantError("O campo Participante é obrigatório");
      return;
    } else setParticipantError("");

    if (e.target[1].value === "") {
      setCompanyError("O campo Hotel é obrigatório");
      return;
    } else setCompanyError("");

    if (e.target[2].value === "") {
      setDateBoardingError("O campo Data checkIn é obrigatório");
      return;
    } else setDateBoardingError("");

    if (e.target[3].value === "") {
      setTimeBoardingError("O campo Hora checkIn é obrigatório");
      return;
    } else setTimeBoardingError("");

    if (e.target[4].value === "") {
      setDateArrivalError("O campo Data checkOut é obrigatório");
      return;
    } else setDateArrivalError("");

    if (e.target[5].value === "") {
      setTimeArrivalError("O campo Hora checkOut é obrigatório");
      return;
    } else setTimeArrivalError("");

    if (e.target[7].value === "") {
      setAddresError("O campo Endereço é obrigatório");
      return;
    } else setAddresError("");

    await createHotel({
      event: props.idEvent,
      login: participantSelected,
      hotel: e.target[1].value,
      checkindate: e.target[2].value,
      checkoutdate: e.target[4].value,
      checkintime: e.target[3].value,
      checkouttime: e.target[5].value,
      reservationnumber: e.target[6].value,
      address: e.target[7].value,
      phonenumber: e.target[8].value,
      extrainformation: e.target[9].value,
      jwt: props.state.global_user_data.data.token,
    })
      .then((response) => {
        setsuccess_dlg(true);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={'Criado'}
          onConfirm={() => {
            props.data[0].refetch();
            props.data[1].refetch();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {'Hotel criado com sucesso!'}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Hotel</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Participante*</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setParticipantSelected(e.target.value);
                  }}
                >
                  <option>Selecione</option>
                  {participants &&
                    participants.map((e, i) => (
                      <option key={i} value={e.id}>
                        {e.nome}
                      </option>
                    ))}
                </select>
                <p style={{ color: 'red' }}>{participantError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Hotel*</label>
                <input className="form-control" type="text" />
                <p style={{ color: 'red' }}>{companyError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data checkIn*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: 'red' }}>{dateBoardingError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora checkIn*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: 'red' }}>{timeBoardingError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data checkOut*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: 'red' }}>{dateArrivalError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora checkOut*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: 'red' }}>{timeArrivalError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Número da reserva</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Endereço*</label>
                <input className="form-control" type="text" />
                <p style={{ color: 'red' }}>{addresError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Telefone do hotel</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Informações extras</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewHotel);
