import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import {
  editSuperScreen,
  getChatID,
} from "../../../utils/services/superScreen";

function NewSuperTela(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  // chat
  const [chatActive, setChatActive] = useState(props.data.visibleChat);
  const [selectChatId, setSelectChatId] = useState(
    props.data.chats[0] ? true : false
  );
  // channel
  const [listChannel, setLitsChannel] = useState([]);
  // dados do form
  const [dataForm, setDataForm] = useState({});
  // dados de um novo canal
  const [channel, setChannel] = useState({});
  // erros
  const [erroForm, setErroForm] = useState({});
  // chatId
  const [listChatId, setListChatId] = useState([]);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function formatChatId(e) {
    var stringFormat = e
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase()
      .replace(/\s/g, "");

    return stringFormat;
  }

  function addChannelItem() {
    if (!channel.channelUrl) {
      setErroForm({ channelUrl: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (!channel.channelName) {
      setErroForm({ channelName: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (!channel.channelVisible || channel.channelVisible === "Selecione") {
      setErroForm({ channelVisible: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (!channel.channelLanguage || channel.channelLanguage === "Selecione") {
      setErroForm({ channelLanguage: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (!channel.channelPlayerType) {
      setErroForm({ channelPlayerType: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (!channel.channelGuidance) {
      setErroForm({ channelGuidance: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    setLitsChannel(() => {
      return [
        ...listChannel,
        {
          channelUrl: channel.channelUrl,
          channelName: channel.channelName,
          channelVisible: channel.channelVisible,
          channelLanguage: channel.channelLanguage,
          channelPlayerType: channel.channelPlayerType,
          channelGuidance: channel.channelGuidance,
        },
      ];
    });

    setChannel({});
  }

  function removeChannelItem(el) {
    var newArray = listChannel.filter((e) => e.id !== el.id);
    setLitsChannel(newArray);
  }

  function verifyErros() {
    if (!dataForm.name) {
      setErroForm({ name: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (chatActive && !dataForm.chatId) {
      setErroForm({ chatId: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (selectChatId && dataForm.chatId === "Selecione") {
      setErroForm({ chatId: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (
      (chatActive && !dataForm.approval) ||
      dataForm.approval === "Selecione"
    ) {
      setErroForm({ approval: "Esse campo é obrigatório" });
      return;
    } else setErroForm({});

    if (listChannel.length === 0) {
      setErroForm({ channel: "Adicione pelo menos um canal" });
      return;
    } else setErroForm({});

    var error = false;
    listChannel.map((e) => {
      if (
        !e.channelUrl ||
        !e.channelName ||
        !e.channelVisible ||
        e.channelVisible === "Selecione" ||
        !e.channelLanguage ||
        e.channelLanguage === "Selecione" ||
        !e.channelPlayerType ||
        !e.channelGuidance
      ) {
        error = true;
      }
    });

    if (error) {
      setErroForm({ Listchannel: "Todos os campos são obrigatórios" });
    } else submitForm();
  }

  function submitForm() {
    var data = {
      id: props.data.id,
      name: dataForm.name,
      event: props.idEvent,
      channels: [
        ...listChannel.map((e) => {
          return {
            event: props.idEvent,
            url: e.channelUrl,
            name: e.channelName,
            visible: e.channelVisible === "Sim" ? true : false,
            idiom: e.channelLanguage,
            player: e.channelPlayerType,
            ordination: e.channelGuidance,
          };
        }),
      ],
      chats: chatActive
        ? [
            {
              event: props.idEvent,
              chatId: dataForm.chatId,
              needApproval: dataForm.approval
                ? dataForm.approval === "Sim"
                  ? true
                  : false
                : false,
            },
          ]
        : [],
      visibleChannel: true,
      visibleChat: chatActive,
      emojiActive: dataForm.superEmoji ? true : false,
      questions: dataForm.activeQuestion,
    };

    editSuperScreen(data, props.state.global_user_data.data.token).then(
      (res) => {
        if (res.message === "updated") {
          setsuccess_dlg(true);
        }
      }
    );
    // .catch((error) => console.log("erro", error));
  }

  function getAllChatId() {
    getChatID(props.idEvent, props.state.global_user_data.data.token).then(
      (res) => {
        if (res.message === "findChatId") {
          setListChatId(res.findChatId);
        }
      }
    );
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getAllChatId();
  }, []);

  useEffect(() => {
    setDataForm((state) => {
      return {
        ...state,
        chatId: null,
      };
    });
  }, [selectChatId]);

  useEffect(() => {
    setLitsChannel(
      props.data.channels.map((e) => {
        return {
          id: e.id,
          channelUrl: e.url,
          channelName: e.name,
          channelVisible: e.visible ? "Sim" : "Não",
          channelLanguage: e.idiom,
          channelPlayerType: e.player,
          channelGuidance: e.ordination,
        };
      })
    );

    setDataForm({
      name: props.data.name,
      chatId: props.data?.chats[0]?.chatId ? props.data.chats[0].chatId : null,
      approval: props.data?.chats[0]
        ? props.data.chats[0].needApproval
          ? "Sim"
          : "Não"
        : null,
      superEmoji: props.data.emojiActive,
      activeQuestion: props.data.questions,
    });
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
      size="lg"
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            setsuccess_dlg(false);
            toggle();
            setTimeout(() => {
              props.getAllSuperScreen();
            }, 1000);
          }}
        >
          {"SuperTela editada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Super Tela</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <div>
              <label
                className="form-label"
              >
                ID
              </label>
              <input
                className="form-control"
                type="text"
                value={props.data.id}
                disabled
              />
              {erroForm.name && <p style={{ color: "red" }}>{erroForm.name}</p>}
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={12}>
            <div>
              <label className="form-label">Nome *</label>
              <input
                className="form-control"
                type="text"
                defaultValue={dataForm && dataForm.name}
                onChange={(e) =>
                  setDataForm((state) => {
                    return {
                      ...state,
                      name: e.target.value,
                    };
                  })
                }
              />
              {erroForm.name && <p style={{ color: "red" }}>{erroForm.name}</p>}
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={2}>
            <div>
              <label className="form-label">Ativar chat *</label>
              <div>
                <input
                  type="radio"
                  name="chat"
                  value="link"
                  style={{ marginRight: 5 }}
                  id="chatOption1"
                  defaultChecked={!chatActive}
                  onChange={() => {
                    setChatActive(false);
                    setDataForm((state) => {
                      return {
                        ...state,
                        chatId: null,
                        approval: null,
                      };
                    });
                  }}
                />
                <label htmlFor="chatOption1" className="form-label">
                  Não
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="chat"
                  value="link"
                  style={{ marginRight: 5 }}
                  id="chatOption2"
                  defaultChecked={chatActive}
                  onChange={() => setChatActive(true)}
                />
                <label htmlFor="chatOption2" className="form-label">
                  Sim
                </label>
              </div>
            </div>
          </Col>

          {chatActive && (
            <>
              <Col md={5}>
                <div>
                  <div>
                    <label className="form-label">Chat ID *</label>
                    <input
                      type="checkbox"
                      id="newChatId"
                      className="form-label"
                      style={{ margin: "0 5px" }}
                      defaultChecked={props.data.chats[0] ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectChatId(true);
                        } else setSelectChatId(false);
                      }}
                    />
                    <label className="form-label" htmlFor="newChatId">
                      Chat ID já criado
                    </label>
                  </div>
                  {selectChatId ? (
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setDataForm((state) => {
                          return {
                            ...state,
                            chatId: e.target.value,
                          };
                        })
                      }
                    >
                      <option>Selecione</option>
                      {listChatId.map((e, i) => (
                        <option
                          selected={
                            props.data.chats[0]
                              ? e.chat_id === props.data.chats[0].chatId
                                ? true
                                : false
                              : false
                          }
                        >
                          {e.chat_id}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) =>
                        setDataForm((state) => {
                          return {
                            ...state,
                            chatId: formatChatId(e.target.value),
                          };
                        })
                      }
                    />
                  )}

                  {erroForm.chatId && (
                    <p style={{ color: "red" }}>{erroForm.chatId}</p>
                  )}
                </div>
              </Col>

              <Col md={5}>
                <label className="form-label">Precisa de aprovação? *</label>
                <select
                  className="form-control"
                  onChange={(e) =>
                    setDataForm((state) => {
                      return {
                        ...state,
                        approval: e.target.value,
                      };
                    })
                  }
                >
                  <option>Selecione</option>
                  <option
                    selected={
                      dataForm.approval
                        ? dataForm.approval === "Sim"
                          ? true
                          : false
                        : false
                    }
                  >
                    Sim
                  </option>
                  <option
                    selected={
                      dataForm.approval
                        ? dataForm.approval === "Sim"
                          ? false
                          : true
                        : false
                    }
                  >
                    Não
                  </option>
                </select>
                {erroForm.approval && (
                  <p style={{ color: "red" }}>{erroForm.approval}</p>
                )}
              </Col>
            </>
          )}
        </Row>

        <Row className="mt-3">
          <Col md={3}>
            <div>
              <label className="form-label">Ativar super emoji? *</label>
              <div>
                <input
                  type="radio"
                  name="emoji"
                  style={{ marginRight: 5 }}
                  id="emojiOption1"
                  defaultChecked={props.data.emojiActive ? false : true}
                  onChange={() => {
                    setDataForm((state) => {
                      return {
                        ...state,
                        superEmoji: false,
                      };
                    });
                  }}
                />
                <label htmlFor="emojiOption1" className="form-label">
                  Não
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="emoji"
                  style={{ marginRight: 5 }}
                  id="emojiOption2"
                  defaultChecked={props.data.emojiActive ? true : false}
                  onChange={() => {
                    setDataForm((state) => {
                      return {
                        ...state,
                        superEmoji: true,
                      };
                    });
                  }}
                />
                <label htmlFor="emojiOption2" className="form-label">
                  Sim
                </label>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div>
              <label className="form-label">Ativar perguntas? *</label>
              <div>
                <input
                  type="radio"
                  name="activeQuestion"
                  style={{ marginRight: 5 }}
                  id="activeQuestionOption1"
                  defaultChecked={!props.data.questions}
                  onChange={() => {
                    setDataForm((state) => {
                      return {
                        ...state,
                        activeQuestion: false,
                      };
                    });
                  }}
                />
                <label htmlFor="activeQuestionOption1" className="form-label">
                  Não
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="activeQuestion"
                  style={{ marginRight: 5 }}
                  id="activeQuestionOption2"
                  defaultChecked={props.data.questions}
                  onChange={() => {
                    setDataForm((state) => {
                      return {
                        ...state,
                        activeQuestion: true,
                      };
                    });
                  }}
                />
                <label htmlFor="activeQuestionOption2" className="form-label">
                  Sim
                </label>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={12} style={{ textAlign: "center" }}>
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Canais
            </label>
          </Col>
        </Row>

        <Row>
          <Col md={10}>
            <Row className="mt-3">
              <Col md={4}>
                <label className="form-label">URL *</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.channelUrl ? channel.channelUrl : ""}
                  onChange={(e) =>
                    setChannel((state) => {
                      return {
                        ...state,
                        channelUrl: e.target.value,
                      };
                    })
                  }
                />
                {erroForm.channelUrl && (
                  <p style={{ color: "red" }}>{erroForm.channelUrl}</p>
                )}
              </Col>

              <Col md={4}>
                <label className="form-label">Nome *</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.channelName ? channel.channelName : ""}
                  onChange={(e) =>
                    setChannel((state) => {
                      return {
                        ...state,
                        channelName: e.target.value,
                      };
                    })
                  }
                />
                {erroForm.channelName && (
                  <p style={{ color: "red" }}>{erroForm.channelName}</p>
                )}
              </Col>

              <Col md={4}>
                <label className="form-label">Visível *</label>
                <select
                  className="form-control"
                  value={channel.channelVisible ? channel.channelVisible : ""}
                  onChange={(e) =>
                    setChannel((state) => {
                      return {
                        ...state,
                        channelVisible: e.target.value,
                      };
                    })
                  }
                >
                  <option>Selecione</option>
                  <option>Sim</option>
                  <option>Não</option>
                </select>
                {erroForm.channelVisible && (
                  <p style={{ color: "red" }}>{erroForm.channelVisible}</p>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                <label className="form-label">Idioma *</label>
                <select
                  className="form-control"
                  value={channel.channelLanguage ? channel.channelLanguage : ""}
                  onChange={(e) =>
                    setChannel((state) => {
                      return {
                        ...state,
                        channelLanguage: e.target.value,
                      };
                    })
                  }
                >
                  <option>Selecione</option>
                  <option>PT-BR</option>
                  <option>EN</option>
                  <option>ES</option>
                </select>
                {erroForm.channelLanguage && (
                  <p style={{ color: "red" }}>{erroForm.channelLanguage}</p>
                )}
              </Col>

              <Col md={4}>
                <label className="form-label">Tipo de player *</label>
                <input
                  className="form-control"
                  type="text"
                  value={
                    channel.channelPlayerType ? channel.channelPlayerType : ""
                  }
                  onChange={(e) =>
                    setChannel((state) => {
                      return {
                        ...state,
                        channelPlayerType: e.target.value,
                      };
                    })
                  }
                />
                {erroForm.channelPlayerType && (
                  <p style={{ color: "red" }}>{erroForm.channelPlayerType}</p>
                )}
              </Col>

              <Col md={4}>
                <label className="form-label">Ordenação *</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.channelGuidance ? channel.channelGuidance : ""}
                  onChange={(e) =>
                    setChannel((state) => {
                      return {
                        ...state,
                        channelGuidance: e.target.value,
                      };
                    })
                  }
                />
                {erroForm.channelGuidance && (
                  <p style={{ color: "red" }}>{erroForm.channelGuidance}</p>
                )}
              </Col>
            </Row>
          </Col>

          <Col
            md={2}
            className="mt-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              onClick={() => addChannelItem()}
            >
              Adicionar
            </Button>
          </Col>
        </Row>

        {erroForm.channel && <p style={{ color: "red" }}>{erroForm.channel}</p>}

        {listChannel.length > 0 &&
          listChannel.map((e, i) => (
            <div
              style={{
                border: "1px solid #a1e2de",
                marginTop: 15,
                padding: 5,
                borderRadius: 8,
              }}
              key={e.id}
            >
              <Row className="mb-3">
                <Col md={10}>
                  <Row className="mt-1">
                    <Col md={4}>
                      <label className="form-label">URL *</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={listChannel[i].channelUrl}
                        onChange={(e) =>
                          (listChannel[i].channelUrl = e.target.value)
                        }
                      />
                    </Col>

                    <Col md={4}>
                      <label className="form-label">Nome *</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={listChannel[i].channelName}
                        onChange={(e) =>
                          (listChannel[i].channelName = e.target.value)
                        }
                      />
                    </Col>

                    <Col md={4}>
                      <label className="form-label">Visível *</label>
                      <select
                        className="form-control"
                        defaultValue={listChannel[i].channelVisible}
                        onChange={(e) =>
                          (listChannel[i].channelVisible = e.target.value)
                        }
                      >
                        <option>Selecione</option>
                        <option>Sim</option>
                        <option>Não</option>
                      </select>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={4}>
                      <label className="form-label">Idioma *</label>
                      <select
                        className="form-control"
                        defaultValue={listChannel[i].channelLanguage}
                        onChange={(e) =>
                          (listChannel[i].channelLanguage = e.target.value)
                        }
                      >
                        <option>Selecione</option>
                        <option>PT-BR</option>
                        <option>EN</option>
                        <option>ES</option>
                      </select>
                    </Col>

                    <Col md={4}>
                      <label className="form-label">Tipo de player *</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={listChannel[i].channelPlayerType}
                        onChange={(e) =>
                          (listChannel[i].channelPlayerType = e.target.value)
                        }
                      />
                    </Col>

                    <Col md={4}>
                      <label className="form-label">Ordenação *</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={listChannel[i].channelGuidance}
                        onChange={(e) =>
                          (listChannel[i].channelGuidance = e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>

                <Col
                  md={2}
                  className="mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="success"
                    className="btn btn-danger waves-effect waves-light"
                    style={{ width: 80 }}
                    onClick={() => removeChannelItem(e)}
                  >
                    Excluir
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
        {erroForm.Listchannel && (
          <p style={{ color: "red" }}>{erroForm.Listchannel}</p>
        )}

        <Row
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            color="danger"
            style={{ width: 80, marginRight: 10 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>

          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            style={{ width: 80 }}
            type="submit"
            onClick={() => verifyErros()}
          >
            Salvar
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewSuperTela);
