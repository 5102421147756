import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// componentes
import TextEditor from "./TextEditor";
// service
import { postImg } from "../../utils/services/drive";

export default function ModalNewHtml(props) {
  const { isOpen, toggle } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  // campos do form
  const [nameHtml, setNameHtml] = useState();
  const [tags, setTags] = useState([]);
  // editor de texto
  const refValueEditText = useRef(null);
  // state de erros
  const [nameError, setNameError] = useState("");
  const [htmlError, setHtmlError] = useState("");
  // alerta
  const [confirmCreate, setConfirmCreate] = useState(false);

  function addTags(newTags) {
    setTags([...new Set([...tags, newTags])]);
  }

  function removeTags(index) {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  }

  async function submitForm(e) {
    if (!nameHtml) {
      setNameError("O campo Nome do HTML é obrigatório");
      return;
    } else setNameError("");

    if (!refValueEditText.current.getContent()) {
      setHtmlError("O campo Nome do HTML é obrigatório");
      return;
    } else setHtmlError("");

    const data = new FormData();
    data.append("name", nameHtml);
    data.append("tags", JSON.stringify(tags));

    const arquivoBlob = new Blob([refValueEditText.current.getContent()], { type: "text/html" });
    data.append("files", arquivoBlob);

    await postImg(data, jwt)
      .then((res) => {
        setConfirmCreate(true);
        props.getImages();
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  return (
    <Modal isOpen={isOpen} autoFocus centered>
      {confirmCreate && (
        <SweetAlert
          success
          title="Criado"
          onConfirm={() => {
            setConfirmCreate(false);
            toggle();
          }}
        >
          Arquivo criado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Criar HTML</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <label className="form-label">Nome do HTML*</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => setNameHtml(e.target.value)}
            />
            {nameError && <p style={{ color: "red" }}>{nameError}</p>}
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            <label className="form-label">Tags*</label>
            <input
              className="form-control"
              type="text"
              placeholder="Digite e pressione a tecla Enter para adicionar a Tag"
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value !== "") {
                  addTags(e.target.value);
                  e.target.value = "";
                }
              }}
            />
          </Col>
        </Row>

        {tags?.length > 0 && (
          <Row>
            <div className="tag-container">
              {tags.map((tags, index) => (
                <div key={index} className="tag-wrapper">
                  <span className="tags-field">{tags}</span>
                  <i
                    className="mdi mdi-close"
                    onClick={() => removeTags(index)}
                  />
                </div>
              ))}
            </div>
          </Row>
        )}

        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            <label className="form-label">HTML*</label>
            <TextEditor refState={refValueEditText} />
            {htmlError && <p style={{ color: "red" }}>{htmlError}</p>}
          </Col>
        </Row>

        <Row
          style={{
            width: "100%",
            marginTop: 15,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="danger"
            style={{ width: 80, marginRight: 10 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            style={{ width: 80 }}
            onClick={() => submitForm()}
          >
            Salvar
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}
