import axios from "axios";
import { urlBaseNotifications, urlBase } from "./config";

function getNotification({
  jwt,
  idEvent,
  offset,
  limit,
  search,
  columnSearch = "",
}) {
  var config = {
    method: "get",
    url: `${urlBaseNotifications}/notifications/event/${idEvent}/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
}

function newNotification(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBaseNotifications}/notifications`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function newNotificationPublic(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/notifications/public`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function getNotificationPublic({
  jwt,
  idEvent,
  offset,
  limit,
  search,
  columnSearch = "",
}) {
  var config = {
    method: "get",
    url: `${urlBase}/notifications/public/event/${idEvent}/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
}

function updateNotification(data, jwt, id) {
  var config = {
    method: "put",
    url: `${urlBaseNotifications}/notifications/${id}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteNotification(jwt, id) {
  var config = {
    method: "delete",
    url: `${urlBaseNotifications}/notifications/${id}`,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function addLoginInNotificationsScheduled(event, login, token, groups) {
  const data = {
    eventId: event,
    loginId: login,
    groups: groups,
  };
  var config = {
    method: "post",
    url: `${urlBaseNotifications}/notifications/login`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function removeLoginInNotificationsScheduled(event, login, token) {
  const data = {
    eventId: event,
    loginId: login,
  };

  var config = {
    method: "post",
    url: `${urlBaseNotifications}/notifications/login/remove`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
function getGroupsInNotificationsNotSended(event, groupId, token) {
  const data = {
    eventId: event,
    groupId: groupId,
  };

  var config = {
    method: "post",
    url: `${urlBaseNotifications}/notifications/group`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
function getLanguages(token) {
  var config = {
    method: "get",
    url: `${urlBaseNotifications}/notifications/list/languages`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
export {
  addLoginInNotificationsScheduled,
  getGroupsInNotificationsNotSended,
  getLanguages,
  getNotification,
  getNotificationPublic,
  newNotification,
  newNotificationPublic,
  removeLoginInNotificationsScheduled,
  updateNotification,
  deleteNotification,
};
