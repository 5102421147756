import writeXlsxFile from "write-excel-file";
import { listParticipants } from "../../../utils/services/sorteios";
import { getGroups } from "../../../utils/services/group";

export async function createXlsx({
  id,
  idEvent,
  sessionId,
  limit,
  countData,
  setFinishExport,
  jwt,
}) {
  const formatDate = (date) => {
    return date.split("T")[0].split("-").reverse().join("-");
  };

  const schema = [
    {
      column: "Id do Evento",
      type: String,
      value: (participants) => participants.eventId,
    },
    /* {
      column: "Nome do Evento",
      type: String,
      value: (participants) => participants.name,
    }, */
    {
      column: "ID da Sessão",
      type: String,
      value: (participants) => participants.session.id,
    },
    {
      column: "Descrição da Sessão",
      type: String,
      value: (participants) =>
        `${participants.session.title}, ${formatDate(
          participants.session.startDate
        )} ${participants.session.startHour} ${
          participants.endHour ? "-" : ""
        }${participants.session.endHour}`,
    },
    {
      column: "ID do Participante",
      type: String,
      value: (participants) => participants.id,
    },
    {
      column: "Nome do Participante",
      type: String,
      value: (participants) => participants.name,
    },
    {
      column: "Email do Participante",
      type: String,
      value: (participants) => participants.email,
    },
    {
      column: "Quantidade Total de Ingressos",
      type: Number,
      value: (participants) => participants.companionsNumber,
    },
    {
      column:
        "Você ou seu(s) acompanhante(s) possuem restrição de acessibilidade?",
      type: String,
      value: (participants) =>
        participants.accessibilityRestriction ? "Sim" : "Não",
    },
    {
      column: "Qual?",
      type: String,
      value: (participants) => participants.typeRestriction,
    },
    {
      column: "Observações",
      type: String,
      value: (participants) => participants.notes,
    },
    {
      column: "Empresa",
      type: String,
      value: (participants) => participants.company,
    },
    {
      column: "Cargo",
      type: String,
      value: (participants) => participants.position,
    },
    {
      column: "Código Interno 1",
      type: String,
      value: (participants) => participants.internalCode1,
    },
    {
      column: "Código Interno 2",
      type: String,
      value: (participants) => participants.internalCode2,
    },
    {
      column: "Código Interno 3",
      type: String,
      value: (participants) => participants.internalCode3,
    },
    {
      column: "Grupos/IDs",
      type: String,
      value: (participants) =>
        participants.groups
          ? participants.groups.map((e) => e.id).toString()
          : "",
    },
    {
      column: "Grupos/Descrição",
      type: String,
      value: (participants) =>
        participants.groups
          ? participants.groups.map((e) => e.desc).toString()
          : "",
    },
    {
      column: "Data de Cadastro",
      type: String,
      value: (participants) => formatDate(participants.createdAt),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  const groups = await getGroups({
    jwt: jwt,
    eventId: idEvent,
    offset: 1,
    limit: 0,
    search: "",
  });
  let dataGroup = groups?.findOneGroupData?.data;

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await listParticipants({
      id: id,
      jwt: jwt,
      offset: i,
      limit: limit,
      search: "",
    });
    console.log(dataGroup);
    request.findAllSorteiosData.data.map((e) => {
      e.groups = e.groups.split(",").map((f) => {
        f = {
          id: dataGroup?.find((s) => s.id == f)?.id,
          desc: dataGroup?.find((s) => s.id == f)?.name,
        };
        return f;
      });
    });
    allRequestsPromises.push(request);

    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.findAllSorteiosData.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "Participantes_Sorteio.xlsx",
    });
  }
}
