/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Input,
  Button,
} from "reactstrap";
import { Line } from "rc-progress";
import Select from "react-select";

// import FormEditor from "./components/FormEditor";

import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";

//service
import {
  getLanguages,
  newNotification,
} from "../../../utils/services/notification";
import { getMenus } from "../../../utils/services/menus";

import {
  getParticipants,
  getLoginIdsToNotification,
} from "../../../utils/services/participants";
import { getGroups } from "../../../utils/services/group";
import { getUrlFiles } from "../../../utils/services/files";
import { getMobileAppsByEvent } from "../../../utils/services/mobileApps";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import { linkTypes } from "./static";
const MAX_CHUNK = 500;

function NewNotification(props) {
  const { isOpen, toggle } = props;
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);

  const [title, setTitle] = useState("");
  //users select
  const [selectedMulti, setselectedMulti] = useState([]);
  const [selectedMultiIdiom, setSelectedMultiIdiom] = useState([]);

  const [allLogin, setAllLogin] = useState(false);
  //state de participantes
  const [participants, setParticipants] = useState([]);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  //state de erros
  const [titleError, setTitleError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [userError, setUserError] = useState("");
  const [btnOneTextError, setBtnOneTextError] = useState("");
  const [btnOneLinkError, setBtnOneLinkError] = useState("");
  const [btnTwoTextError, setBtnTwoTextError] = useState("");
  const [btnTwoLinkError, setBtnTwoLinkError] = useState("");
  const [btnOneActionError, setBtnOneActionError] = useState("");
  const [btnTwoActionError, setBtnTwoActionError] = useState("");

  //agendar
  const [schedule, setSchedule] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");

  const [foto, setFoto] = useState("");
  const [link, setLink] = useState("");
  const [selectLinkType, setSelectLinkType] = useState([]);
  const [textHtml, setTextHtml] = useState("");
  const [btnOneText, setBtnOneText] = useState("");
  const [btnOneColor] = useState("");
  const [btnOneLink, setBtnOneLink] = useState("");
  const [btnOneAction, setBtnOneAction] = useState("");
  const [btnTwoText, setBtnTwoText] = useState("");
  const [btnTwoColor, setBtnTwoColor] = useState("");
  const [btnTwoLink, setBtnTwoLink] = useState("");
  const [btnTwoAction, setBtnTwoAction] = useState("");
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [percent, setPercent] = useState(0);
  const [isButtonSaveOn, setIsButtonSaveOn] = useState(true);
  const [sendToParticipants, setSendToParticipants] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [multIdiom, setMultIdiom] = useState(false);
  const [checkIdiomRemove, setCheckIdiomRemove] = useState(false);

  const [mobileapps, setMobileApps] = useState([]);
  // const [mobileappsMulti, setMobileAppsMulti] = useState([]);
  const [editorState, setEditorState] = useState("");

  const [btnList, setBTNList] = useState([]);

  const getAllMenus = async () => {
    var formData = await getMenus(
      props.state.global_user_data.data.token,
      props.idEvent
    );
    if (formData.findOneMenuData) {
      let buttonsMap = formData.findOneMenuData.buttons.map(
        ({ linkUrl, nameExhibition, isEmbeded }) => {
          if (!isEmbeded && linkUrl.indexOf("http") < 0)
            return {
              nameExhibition: nameExhibition,
              label: nameExhibition,
              value: linkUrl,
            };
          else
            return {
              nameExhibition: nameExhibition,
              label: "EMBEDADO",
              value: linkUrl,
            };
        }
      );
      setBTNList([
        ...buttonsMap,
        { nameExhibition: "Externo", label: "Externo", value: "EXTERNAL" },
      ]);

      const objEvent =
        props?.state?.global_user_data?.data?.foundEvents?.filter(
          (e) => e.id === props.idEvent
        )[0];
      // console.log(buttonsMap);
    }
  };

  async function handleOnChange(e) {
    if (e.length >= 3) {
      await getAllParticipants(e);
    }
  }
  function handleMulti(a) {
    const selected = a.filter((e) => e.value === "todos");
    if (selected.length > 0) {
      a = selected;
      setAllLogin(true);
    } else {
      setAllLogin(false);
    }
    setselectedMulti(a);
  }
  // function handleMobiAppsMulti(a) {
  //   setMobileAppsMulti(a);
  // }

  function handleEditorState(textPlan, richText) {
    setEditorState(textPlan);
    setTextHtml(richText);
  }

  function handleMultiIdiom(a) {
    setSelectedMultiIdiom(a);
  }
  function handleGroupMulti(a) {
    const selected = a.filter((e) => e.value === "todos");
    if (selected.length > 0) {
      a = selected;
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
    setGroupsMulti(a);
  }

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function sendLoginsByRange(form) {
    const SUCCESS = "NOTIFICATION_CREATED";
    let loginsArray = [];
    let loginIds = [];
    let idNotification = null;
    let groupsArray = [];

    if (allGroups) {
      groups.map((e) => {
        groupsArray.push({ grupo: e.id });
      });
    } else {
      groupsMulti.map((e) => {
        if (e.value !== "todos") {
          groupsArray.push({ grupo: e.value });
        }
      });
    }

    if (groupsArray.length > 0) {
      form.grupos = groupsArray;
    }

    if (!allLogin) {
      selectedMulti.map((e) => {
        if (e.value !== "todos") {
          loginsArray.push({ login: e.value });
        }
      });
    }

    const queryName = getQueryName();
    let loginsList = await getAllLoginIdsToNotification(1, MAX_CHUNK, {
      queryName: queryName,
      eventId: props.idEvent,
      logins: loginsArray,
      groups: groupsArray,
    });
    const totalLogin = await loginsList?.data?.count[0]?.count;
    const pages = Math.ceil(totalLogin / MAX_CHUNK);
    for (let curPage = 1; curPage <= pages; curPage++) {
      if (curPage > 1) {
        loginIds = [];
        loginsList = await getAllLoginIdsToNotification(curPage, MAX_CHUNK, {
          queryName: queryName,
          eventId: props.idEvent,
          logins: loginsArray,
          groups: groupsArray,
        });
        form = { id: idNotification };
      }
      await loginsList.data.data.map((e) => {
        loginIds.push({ login: e.id });
      });
      form.logins = loginIds;

      let sendNotificationRes = await newNotification(
        form,
        props.state.global_user_data.data.token
      );
      if (sendNotificationRes.message !== SUCCESS) {
        setPercent((curPage * 100) / pages);
      }
      if (sendNotificationRes.message === SUCCESS && idNotification === null) {
        idNotification = sendNotificationRes.createNotificationData.id;
        setPercent((curPage * 100) / pages);
      }
      if (sendNotificationRes.message === SUCCESS && curPage > 1) {
        setPercent((curPage * 100) / pages);
      }
    }
  }
  // ok

  function getQueryName() {
    if (allGroups && allLogin) {
      return "AGAL"; //getAllGroupsAllLogins
    }
    if (allGroups && selectedMulti.length === 0 && !allLogin) {
      return "AGNL"; //getAllGroupsNoLogins
    }
    if (groupsMulti.length > 0 && selectedMulti.length > 0 && !allLogin) {
      return "SGSL"; //getSomeGroupsSomeLogins
    }
    if (groupsMulti.length > 0 && allLogin) {
      return "SGAL"; //getSomeGroupsAllLogins
    }
    if (groupsMulti.length > 0 && !allLogin && selectedMulti.length === 0) {
      return "SGNL"; //getSomeGroupsNoLogins
    }
    if (!allGroups && groupsMulti.length === 0 && allLogin) {
      return "NGAL"; //getNoGroupsAllLogins
    }
    if (
      !allGroups &&
      groupsMulti.length === 0 &&
      !allLogin &&
      selectedMulti.length > 0
    ) {
      return "NGSL"; //getNoGroupsSomeLogins
    }
  }

  async function submitForm(e) {
    var typeLink = document.getElementById("typeLink");
    var typeLinkNameExhibition =
      typeLink.options[typeLink.selectedIndex].getAttribute("nameExhibition");
    if (!isButtonSaveOn) {
      return;
    }

    // campos vazios
    if (title === "") {
      setTitleError("O campo Título é obrigatório.");
      return;
    } else setTitleError("");

    if (editorState === "") {
      setMessageError("O campo Mensagem é obrigatório.");
      return;
    } else setMessageError("");

    if (selectLinkType === "EXTERNAL" && link === "") {
      setLinkError("O campo Link é obrigatório.");
      return;
    } else setLinkError("");
    if (
      (!btnOneAction.hasOwnProperty("value") || btnOneAction?.value === "") &&
      btnOneText !== ""
    ) {
      setBtnOneActionError("A ação é obrigatório.");
      return;
    } else setBtnOneActionError("");

    if (
      btnOneAction.hasOwnProperty("value") &&
      btnOneAction?.value !== "" &&
      btnOneText === ""
    ) {
      setBtnOneTextError("O texto é obrigatório.");
      return;
    } else setBtnOneTextError("");

    if (btnOneAction?.value === "EXTERNAL" && btnOneLink === "") {
      setBtnOneLinkError("O link é obrigatório.");
      return;
    } else setBtnOneLinkError("");

    if (
      (!btnTwoAction.hasOwnProperty("value") || btnTwoAction?.value === "") &&
      btnTwoText !== ""
    ) {
      setBtnTwoActionError("A ação é obrigatório.");
      return;
    } else setBtnTwoActionError("");
    if (
      btnTwoAction.hasOwnProperty("value") &&
      btnTwoAction?.value !== "" &&
      btnTwoText === ""
    ) {
      setBtnTwoTextError("O texto é obrigatório.");
      return;
    } else setBtnTwoTextError("");

    if (btnTwoAction?.value === "EXTERNAL" && btnTwoLink === "") {
      setBtnTwoLinkError("O link é obrigatório.");
      return;
    } else setBtnTwoLinkError("");

    if (schedule && scheduleDate === "") {
      setDateError("Insira uma data");
      return;
    } else setDateError("");

    if (schedule && scheduleTime === "") {
      setTimeError("Insira uma hora");
      return;
    } else setTimeError("");

    if (selectedMulti.length >= 1) {
      setUserError("");
    } else {
      if (!groupsMulti.length > 0) {
        setUserError("Selecione um ou todos usuários");
        return;
      }
    }
    setIsButtonSaveOn(false);

    let urlImg = null;
    let expireDate = null;

    const date = new Date(`${scheduleDate}T${scheduleTime}`);
    var data = new FormData();

    data.append("files", foto ? foto?.files[0] : foto);
    data.append("folder", "notification");
    data.append("eventId", props.idEvent);

    if (foto) {
      await getUrlFiles(data, props.state.global_user_data.data.token)
        .then((response) => {
          response.map((e) => {
            urlImg = e.url;
            expireDate = e.expires;
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    let idiomsArray = [];
    let mobileAppsArray = [];
    let firebaseProjectsId = [];

    selectedMultiIdiom?.map((e) => {
      idiomsArray.push(e?.value);
    });
    idiomsArray = idiomsArray.map((e) => `'${e}'`).join(",");

    // mobileappsMulti.map(e => mobileAppsArray.push(e?.value));
    mobileapps.map((e) => {
      mobileAppsArray.push(e?.bundle);
      firebaseProjectsId.push({
        firebaseProjectId: e?.idfirebaseproject,
        bundleId: e?.bundle,
      });
    });
    mobileAppsArray = mobileAppsArray.map((e) => `'${e}'`).join(",");
    firebaseProjectsId = JSON.stringify(firebaseProjectsId);

    const objEvent = props?.state?.global_user_data?.data?.foundEvents?.filter(
      (e) => e.id === props.idEvent
    )[0];

    const form = {
      title: title,
      text: editorState,
      schedule: schedule,
      event: props.idEvent,
      date: schedule ? date : new Date(),
      hour: schedule ? scheduleTime : getHour(),
      image: urlImg,
      expireDate: expireDate,
      allLogins: allLogin,
      multiIdiom: multIdiom,
      sendToIdiom: multIdiom && !checkIdiomRemove ? idiomsArray : null,
      notSendToIdiom: multIdiom && checkIdiomRemove ? idiomsArray : null,
      bundleId: mobileAppsArray.length > 0 ? mobileAppsArray : null,
      firebaseProjectId:
        firebaseProjectsId.length > 0 ? firebaseProjectsId : null,
      linkLabel: typeLinkNameExhibition,
      codeQrCode: "teste",
      link: selectLinkType === "EXTERNAL" ? link : selectLinkType,
      textHtml: textHtml,
      btnOneText: btnOneText,
      btnOneColor: btnOneColor,
      btnOneAction: btnOneAction.value,
      btnOneLink:
        btnOneAction.value === "EXTERNAL" ? btnOneLink : btnOneAction.value,
      btnTwoText: btnTwoText,
      btnTwoColor: btnTwoColor,
      btnTwoAction: btnTwoAction.value,
      btnTwoLink:
        btnTwoAction.value === "EXTERNAL" ? btnTwoLink : btnTwoAction.value,
      eventImage: objEvent?.image,
      eventTitle: objEvent?.title,
      eventDescription: objEvent?.description,
    };
    console.log(form);

    await sendLoginsByRange(form);
  }

  async function getAllParticipants(search = "") {
    var formData = await getParticipants({
      jwt: props.state.global_user_data.data.token,
      idEvent: props.idEvent,
      offset: 1,
      limit: 10,
      search: search,
      columnSearch: "login",
    });
    if (formData.message === "users") {
      setParticipants(formData?.data?.data);
    }
  }

  async function getAllLanguages() {
    await getLanguages(props.state.global_user_data.data.token).then((res) => {
      setLanguages(res.data);
    });
  }

  async function getAllLoginIdsToNotification(offset, limit = 500, data) {
    return await getLoginIdsToNotification({
      jwt: props.state.global_user_data.data.token,
      offset,
      limit,
      data,
    });
  }
  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function getHour() {
    const now = new Date();
    return now.getHours() + ":" + now.getMinutes();
  }

  async function getMobileAppEvent() {
    await getMobileAppsByEvent({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
    })
      .then((response) => {
        setMobileApps(response.findOneMobileAppData);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getAllParticipants();
    getGroupsFunc();
    getAllLanguages();
    getMobileAppEvent();
    getAllMenus();
  }, []);

  useEffect(() => {
    if (percent >= 100) {
      setsuccess_dlg(true);
    }
  }, [percent]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.getAllNotification();
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"Notificação criada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Nova Notificação</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Título*</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <p style={{ color: "red" }}>{titleError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Mensagem*</label>
                {/* <FormEditor setEditorState={handleEditorState} defaultEditorState={textHtml} /> */}
                {/* <p style={{ color: "red" }}>{messageError}</p>  */}
                <textarea
                  className="form-control"
                  type="text"
                  onChange={(e) => setEditorState(e.target.value)}
                />
                <p style={{ color: "red" }}>{messageError}</p>
              </div>
            </Col>
          </Row>

          <Row
            className="mt-3"
            onClick={() => setIsOpenCollapse(!isOpenCollapse)}
            style={{ cursor: "pointer" }}
          >
            <Col lg={12}>
              <i
                className="dripicons-gear"
                style={{
                  marginRight: 5,
                  marginTop: 2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              />
              <span className="font-size-14">Personalização Avançada </span>
              <i
                className={
                  isOpenCollapse ? "mdi mdi-arrow-up" : "mdi mdi-arrow-down"
                }
                style={{ marginTop: 2, fontSize: 15, fontWeight: "bold" }}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: 2 }}>
            <Collapse isOpen={isOpenCollapse}>
              <Row className="mt-1" style={{ paddingTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Foto</label>
                    <Input
                      className="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => setFoto(e.target)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-2 mb-4">
                <Col lg={12}>
                  <div>
                    <label className="form-label">Tipo de Link</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setSelectLinkType(e.target.value);
                      }}
                      id="typeLink"
                    >
                      <option>Selecione</option>
                      {btnList &&
                        btnList.map((e, i) => (
                          <option
                            key={i}
                            value={e.value}
                            nameExhibition={e.nameExhibition}
                          >
                            {e.nameExhibition}
                          </option>
                        ))}
                    </select>
                  </div>
                </Col>
              </Row>
              {selectLinkType === "EXTERNAL" && (
                <Row className="mt-2 mb-4">
                  <Col lg={12}>
                    <div>
                      <label className="form-label">Link</label>
                      <Input
                        className="form-control"
                        type="text"
                        onChange={(e) => setLink(e.target.value)}
                      />
                      <p style={{ color: "red" }}>{linkError}</p>
                    </div>
                  </Col>
                </Row>
              )}
              {/* <hr class="border-1 border-top border-dark" /> */}
              {/* <Row>
                <Col>
                  <Row className="font-size-13">
                    <span>Botão 1: </span>
                  </Row>
                  <div>
                    <label className="form-label mt-2">Texto</label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => setBtnOneText(e.target.value)}
                    />
                    <p style={{ color: "red" }}>{btnOneTextError}</p>
                  </div>
                   <ColorPicker
                    classOfContainer="btn1-pickr"
                    labelText="Cor do botão"
                    setStateFunc={setBtnOneColor}
                    defaultColor={btnOneColor}
                  /> 
                  <div>
                    <label className="form-label mt-2">Ação</label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => setBtnOneAction(e.target.value)}
                    />
                    <Select
                      value={btnOneAction}
                      placeholder={"Selecione"}
                      isMulti={false}
                      onChange={(e) => {
                        setBtnOneAction(e);
                      }}
                      options={[
                        {
                          options: [
                            ...linkTypes.map((e) => {
                              return { label: e.label, value: e.value };
                            }),
                          ],
                        },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                    <p style={{ color: "red" }}>{btnOneActionError}</p>
                  </div>
                  {btnOneAction.value === "EXTERNAL" && (
                    <div>
                      <label className="form-label mt-2">Link</label>
                      <input
                        className="form-control"
                        type="text"
                        onChange={(e) => setBtnOneLink(e.target.value)}
                      />
                      <p style={{ color: "red" }}>{btnOneLinkError}</p>
                    </div>
                  )}
                </Col>
                <Col>
                  <Row>
                    <span className="font-size-13">Botão 2: </span>
                  </Row>

                  <div>
                    <label className="form-label mt-2">Texto</label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => setBtnTwoText(e.target.value)}
                    />
                    <p style={{ color: "red" }}>{btnTwoTextError}</p>
                  </div>
                  <ColorPicker
                    classOfContainer="btn2-pickr"
                    labelText="Cor do botão"
                    setStateFunc={setBtnTwoColor}
                    defaultColor={btnTwoColor}
                  />
                  <div>
                    <label className="form-label mt-2">Ação</label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => setBtnTwoAction(e.target.value)}
                    />
                    <Select
                      value={btnTwoAction}
                      placeholder={"Selecione"}
                      isMulti={false}
                      onChange={(e) => {
                        setBtnTwoAction(e);
                      }}
                      options={[
                        {
                          options: [
                            ...linkTypes.map((e) => {
                              return { label: e.label, value: e.value };
                            }),
                          ],
                        },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                    <p style={{ color: "red" }}>{btnTwoActionError}</p>
                  </div>
                  {btnTwoAction.value === "EXTERNAL" && (
                    <div>
                      <label className="form-label mt-2">Link</label>
                      <input
                        className="form-control"
                        type="text"
                        onChange={(e) => setBtnTwoLink(e.target.value)}
                      />
                      <p style={{ color: "red" }}>{btnTwoLinkError}</p>
                    </div>
                  )}
                </Col>
              </Row> */}
            </Collapse>
          </Row>
          <hr className="border-1 border-top border-dark" />
          <Row>
            <h5 className="font-size-14 mt-1">Enviar push agora?</h5>
          </Row>
          <Row className="mt-1">
            <Col md={4}>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                  defaultChecked
                  onClick={() => setSchedule(false)}
                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                  Sim
                </label>
              </div>
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="option2"
                  onClick={() => setSchedule(true)}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  Não
                </label>
              </div>
            </Col>
            {schedule && (
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Agendar para dia*</label>
                      <input
                        className="form-control"
                        type="date"
                        onChange={(e) => {
                          setScheduleDate(e.target.value);
                        }}
                      />
                      <p style={{ color: "red" }}>{dateError}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Hora*</label>
                      <input
                        className="form-control"
                        type="time"
                        onChange={(e) => setScheduleTime(e.target.value)}
                      />
                      <p style={{ color: "red" }}>{timeError}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <hr className="border-1 border-top border-dark" />

          <Row>
            <Col md={12}>
              <div className="form-check form-check-end mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={multIdiom}
                  checked={multIdiom}
                  onChange={() => {
                    setMultIdiom(!multIdiom);
                    setSelectedMultiIdiom([]);
                  }}
                />
                <label>O Push vai ser Multi-idiomas?</label>
              </div>
            </Col>
          </Row>

          {multIdiom && (
            <Row>
              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col md={6}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="langRadio"
                      id="participantRadios1"
                      value="opt1"
                      defaultChecked
                      onClick={() => {
                        setCheckIdiomRemove(false);
                        setSelectedMultiIdiom([]);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="participantRadios1"
                    >
                      Indicar idioma(s) que o push será enviado
                    </label>
                  </div>
                </Col>
                <Col>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="langRadio"
                      id="removeLangRadios2"
                      value="opt2"
                      onClick={() => {
                        setCheckIdiomRemove(true);
                        setSelectedMultiIdiom([]);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="removeLangRadios2"
                    >
                      Retirar idioma(s) do push
                    </label>
                  </div>
                </Col>
              </Row>
              <Col lg={12}>
                <div>
                  <label className="form-label">Escolha o idioma</label>
                  <Select
                    value={selectedMultiIdiom}
                    placeholder={"Selecione"}
                    isMulti={true}
                    onChange={(e) => {
                      handleMultiIdiom(e);
                    }}
                    options={[
                      {
                        options: [
                          ...languages.map((e) => {
                            return { label: e.name, value: e.tag };
                          }),
                        ],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>
          )}
          <hr className="border-1 border-top border-dark" />

          {/* <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">
                  Escolha o Aplicativo
                </label>
                <Select
                  value={mobileappsMulti}
                  placeholder={"Selecione"}
                  isMulti={true}
                  onChange={(e) => {
                    handleMobiAppsMulti(e);
                  }}
                  options={[
                    {
                      options: [
                        ...mobileapps.map((e) => {
                          return { label: e.name, value: e.bundle };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row> */}
          <Row>
            <h5 className="font-size-14 mt-1">Enviar para:</h5>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col md={6}>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sendRadio"
                  id="participantRadios1"
                  value="opt1"
                  defaultChecked
                  onClick={() => {
                    setSendToParticipants(true);
                    setAllGroups(false);
                    setGroupsMulti([]);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="participantRadios1"
                >
                  Participantes
                </label>
              </div>
            </Col>
            <Col>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sendRadio"
                  id="grupoRadios2"
                  value="opt2"
                  onClick={() => {
                    setAllLogin(false);
                    setselectedMulti([]);
                    setSendToParticipants(false);
                  }}
                />
                <label className="form-check-label" htmlFor="grupoRadios2">
                  Grupos
                </label>
              </div>
            </Col>
          </Row>

          {sendToParticipants && (
            <Row style={{ paddingTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">
                    Participantes{groupsMulti.length > 0 ? "" : "*"}
                  </label>
                  <Select
                    value={selectedMulti}
                    placeholder={"Selecione"}
                    isMulti={true}
                    onChange={(e) => {
                      handleMulti(e);
                    }}
                    onInputChange={(e) => {
                      handleOnChange(e);
                    }}
                    options={[
                      {
                        options: !allLogin
                          ? [
                              { label: "TODOS", value: "todos" },
                              ...participants.map((e) => {
                                return { label: e.nome, value: e.id };
                              }),
                            ]
                          : [],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                  <p style={{ color: "red" }}>{userError}</p>
                </div>
              </Col>
            </Row>
          )}

          {!sendToParticipants && (
            <Row style={{ paddingTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Grupos</label>
                  <Select
                    isDisabled={allLogin}
                    value={groupsMulti}
                    placeholder={"Selecione"}
                    isMulti={true}
                    onChange={(e) => {
                      handleGroupMulti(e);
                    }}
                    options={[
                      {
                        options: !allGroups
                          ? [
                              { label: "TODOS", value: "todos" },
                              ...groups.map((e) => {
                                return { label: e.name, value: e.id };
                              }),
                            ]
                          : [],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: "2%" }}>
            {percent > 0 && (
              <Col lg={12}>
                <Line percent={percent} strokeWidth={4} strokeColor="#556ee6" />
                <div style={{ textAlign: "center" }}>{percent.toFixed()} %</div>
              </Col>
            )}
          </Row>
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 10,
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              disabled={!isButtonSaveOn}
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewNotification);
