import axios from "axios";
import { urlBaseCertificate } from "./config";

async function getAllCertificate({
  jwt,
  offset,
  limit,
  search,
  eventId,
  enabled = true,
  columnSearch = '',
}) {
  try {
    const config = {
      method: 'get',
      url: `${urlBaseCertificate}/certificates/event/${eventId}?offset=${offset}&limit=${limit}&enabled=${enabled}${
        search ? `&search=${search}` : ''
      }&columnSearch=${columnSearch}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function updateCertificate({data, jwt, id}) {
  try{
    const config = {
      method: 'put',
      url: `${urlBaseCertificate}/certificates/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data,
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

async function newCertificate({data, jwt}) {
  try{
    const config = {
      method: 'post',
      url: `${urlBaseCertificate}/certificates`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data,
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

async function deleteCertificate({id, jwt}) {
  try{
    const config = {
      method: 'delete',
      url: `${urlBaseCertificate}/certificates/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getDownloadCertificate({
  jwt,
  eventId,
  offset,
  limit,
  search,
  columnSearch = '',
}) {
  try {
    const config = {
      method: 'get',
      url: `${urlBaseCertificate}/certificate-download/event/${eventId}?offset=${offset}&limit=${limit}${
        search ? `&search=${search}&columnSearch=${columnSearch}` : ''
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getCertificateByName({jwt, eventId, name}) {
  try{
    const config = {
      method: 'get',
      url: `${urlBaseCertificate}/certificates/${eventId}/event/${name}/name`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export {
  getAllCertificate,
  updateCertificate,
  newCertificate,
  deleteCertificate,
  getDownloadCertificate,
  getCertificateByName,
};