import axios from 'axios';
import { urlBaseImportLoginGroups } from './config';

function createLoginGroup({ email, groups, eventId }, jwt) {
  var data = JSON.stringify({ email, groups, eventId });

  var config = {
    method: 'post',
    url: `${urlBaseImportLoginGroups}/groups`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { createLoginGroup };
