import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

//redux
import { connect } from "react-redux";

//service
import { editContract } from "../../utils/services/contracts";
import { getProducts } from "../../utils/services/products";
import { getUrlFiles } from "../../utils/services/files";

function EditContract(props) {
  const { isOpen, toggle, refetch } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);

  //produtos
  const [products, setProducts] = useState();

  //campos fomulário
  const [title, setTitle] = useState(props.editContract.name);
  const [emailRemetente, setEmailRemetente] = useState(props.editContract.senderEmail)
  const [logoLarge, setLogoLarge] = useState(props.editContract.logoLarge);
  const [logoSmall, setLogoSmall] = useState(props.editContract.logoSmall);
  const [logoLargeView, setLogoLargeView] = useState(
    props.editContract.logoLarge
  );
  const [logoSmallView, setLogoSmallView] = useState(
    props.editContract.logoSmall
  );
  const [appPlusSelected, setAppPlusSelected] = useState([]);

  //validações campos formulários
  const [titleError, setTitleError] = useState(false);
  const [emailRemetenteError, setEmailRemetenteError] = useState("")
  const [productsError, setProductsError] = useState(false);

  //cadastrado com sucesso
  const [success, setSuccess] = useState(false);
  const token = props.state.global_user_data.data.token;
  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }
  const onLogoLargeChange = (event) => {
    setLogoLargeView(null);
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setLogoLargeView(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const onLogoSmallChange = (event) => {
    setLogoSmallView(null);
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setLogoSmallView(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  function chunkArray(array, size) {
    const chunkedArray = [];
    if (array?.length > 0) {
      for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
      }
    }
    return chunkedArray;
  }

  async function submitForm(e) {
    var data = new FormData();

    if (title === "") {
      return setTitleError("O campo Nome da Empresa é obrigatório.");
    } else setTitleError("");

    if (emailRemetente === "") {
      return setEmailRemetenteError("O campo E-mail Remetente é obrigatório.")
    } else setEmailRemetenteError("")

    if (!validateEmail()) {
      setEmailRemetenteError('Formato do email inválido.');
      return;
    }

    if (appPlusSelected.filter((e) => e?.mainProduct?.name !== 'Telas de Configuração' && e.checked).length < 1) {
      return setProductsError(true);
    } else setProductsError(false);

    data.append("folder", "contract");
    data.append("eventId", props.editContract);

    if (logoLarge !== "" && logoLarge?.target?.files[0]) {
      data.append("files", logoLarge.target.files[0]);
    }
    if (logoSmall !== "" && logoSmall?.target?.files[0]) {
      data.append("files", logoSmall.target.files[0]);
    }
    let urlLogoLarge = logoLarge;
    let urlLogoSmall = logoSmall;

    let expireDateLogoLarge = props.editContract?.expireDateLogoLarge;
    let expireDateLogoSmall = props.editContract?.expireDateLogoSmall;

    if (logoLarge !== logoLargeView || logoSmall !== logoSmallView) {
      await getUrlFiles(data, token)
        .then((response) => {
          response.map((e) => {
            if (
              e.name === logoLarge?.target?.files[0]?.name &&
              logoLarge !== logoLargeView
            ) {
              urlLogoLarge = e.url;
              expireDateLogoLarge = e.expires;
            }
            if (
              e.name === logoSmall?.target?.files[0]?.name &&
              logoSmall !== logoSmallView
            ) {
              urlLogoSmall = e.url;
              expireDateLogoSmall = e.expires;
            }
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    const hydratateProduct = [
      ...appPlusSelected,
    ]
      ?.filter((el) => el.checked === true)
      .map((e) => e.id);
    const contractObj = {
      id: props.editContract.id,
      name: title,
      senderEmail: emailRemetente,
      products: hydratateProduct,
      logoLarge: urlLogoLarge,
      logoSmall: urlLogoSmall,
      expireDateLogoLarge: expireDateLogoLarge ? expireDateLogoLarge : null,
      expireDateLogoSmall: expireDateLogoSmall ? expireDateLogoSmall : null,
    };
    await editContract(contractObj, token)
      .then((response) => {
        if (response.message === "CONTRACT_ALREADY_EXISTS") {
          setTitleError("Contrato existente");
          return;
        } else {
          setSuccess(true);
          setTitle("");
          refetch();
        }
      })
    // .catch((error) => console.log(error));
  }

  function validateEmail() {
    const pattern =
      /^(?=[a-zA-Z0-9]*[a-zA-Z])[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return pattern.test(emailRemetente);
  }

  async function checkedSubProducts() {
    const productsData = await getProducts({ jwt: token });
    const data = productsData.data.products;
    setProducts(data);
    setAppPlusSelected(data.filter(f => f.mainProduct !== null).map(el => {
      return { ...el, checked: props.editContract.products.filter(f => f.name === el.name && f.mainProduct.name === el.mainProduct.name).length > 0 }
    }))
  }

  useEffect(() => {
    if (props.editContract.products.length > 0) {
      checkedSubProducts();
    }
  }, []);

  const getRulesInitialCheck = (item) => {
    if (item.name === 'Telas de Configuração') {
      return true
    }
    if (appPlusSelected && appPlusSelected.filter((e) => e?.mainProduct?.name === item.name && e.checked).length > 0) {
      return true
    }
    return false
  }
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        setTitle('');
        setTitleError(false);
        setProductsError(false);
      }}
      autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Editado"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Contrato editado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle} onClick={() => ''}>
        Editar Contrato
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">ID do Contrato</label>
                <p
                  style={{
                    border: '1px solid #ced4da',
                    borderRadius: 3,
                    padding: 9,
                    background: '#eff2f7',
                  }}
                >
                  {props.editContract.id}
                </p>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">Nome da Empresa*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {titleError && <p style={{ color: 'red' }}>{titleError}</p>}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">E-mail Remetente*</label>
                <input
                  className="form-control"
                  // placeholder="naoresponder@inteegra.com.br"
                  value={emailRemetente}
                  type="email"
                  onChange={(e) => setEmailRemetente(e.target.value)}
                />
                {emailRemetenteError && <p style={{ color: 'red' }}>{emailRemetenteError}</p>}
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Label
                      role="button"
                      htmlFor="imgLogoLarge"
                      className="form-label mt-2"
                    >
                      Logo do Cliente
                    </Label>
                    <div id="logoCliente" style={{ height: "16px", paddingRight: "7px" }}>
                      <div className="show-tips">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: '#6f42c1' }}
                        placement="right"
                        target="logoCliente"
                      >
                        Imagem exibida quando o menu lateral estiver aberto
                      </UncontrolledTooltip>
                    </div>
                  </div>
                  <Input
                    className="form-control"
                    type="file"
                    id="imgLogoLarge"
                    // defaultValue={null}
                    onChange={(e) => {
                      setLogoLarge(e);
                      onLogoLargeChange(e);
                    }}
                  />
                </div>
              </Col>
              <Col sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                <img alt="edit" src={logoLargeView} style={{ width: 70 }} />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div style={{}}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Label
                      role="button"
                      htmlFor="formFile"
                      className="form-label mt-2"
                    >
                      Ícone do Cliente
                    </Label>
                    <div id="iconeCliente" style={{ height: "16px", paddingRight: "7px" }}>
                      <div className="show-tips">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip
                        style={{ backgroundColor: '#6f42c1' }}
                        placement="right"
                        target="iconeCliente"
                      >
                        Imagem exibida quando o menu lateral estiver fechado
                      </UncontrolledTooltip>
                    </div>
                  </div>
                  <Input
                    className="form-control"
                    type="file"
                    id="formFile2"
                    defaultValue={null}
                    onChange={(e) => {
                      setLogoSmall(e);
                      onLogoSmallChange(e);
                    }}
                  />
                </div>
              </Col>
              <Col sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                <img alt="edit" src={logoSmallView} style={{ width: 70 }} />
              </Col>
            </Row>
            <hr class="border-1 border-top border-dark" />
          </div>

          {/* Produtos */}
          <div>
            <Row style={{ marginTop: 18 }}>
              <p style={{ marginBottom: 14, fontWeight: 'bold' }}>Produtos*</p>
            </Row>
          </div>
          {chunkArray(
            products?.filter((e) => e.mainProduct === null),
            4
          )?.map((row, rowIndex) => (
            <div key={rowIndex}>
              <Row>
                {row.map((item, itemIndex) => (
                  <Col sm={3} key={itemIndex}>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`appid${itemIndex}`}
                        checked={getRulesInitialCheck(item)}
                        onChange={(a) => {
                          if (item.name === 'Telas de Configuração') {
                            return;
                          }
                          if (a.target.checked) {
                            setAppPlusSelected(
                              appPlusSelected.filter(
                                (e) => e?.mainProduct?.name !== item.name
                              )
                            );
                            setAppPlusSelected((prev) => [
                              ...prev,
                              ...products
                                .filter(
                                  (e) => e?.mainProduct?.name === item.name
                                )
                                .map((el) => {
                                  return { ...el, checked: true };
                                }),
                            ]);
                          } else {
                            setAppPlusSelected(
                              appPlusSelected.filter(
                                (e) => e?.mainProduct?.name !== item.name
                              )
                            );
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="appeventos">
                        {item.name}
                      </label>
                    </div>
                    {appPlusSelected && getRulesInitialCheck(item) && (
                      <div
                        id="app-plus-products"
                        style={{ marginTop: 15, marginLeft: 10 }}
                      >
                        {appPlusSelected
                          ?.filter((e) => e.mainProduct.name === item.name)
                          ?.map((e) => (
                            <div
                              key={e?.id}
                              className="form-check form-switch mb-3"
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`app-${e?.name}`}
                                checked={
                                  appPlusSelected?.filter(
                                    (el) => el.id === e.id
                                  )[0]?.checked
                                }
                                onChange={(a) => {
                                  if (e.name === 'Eventos') {
                                    a.target.checked = true;
                                    return;
                                  }
                                  if (e.name === 'Participantes') {
                                    a.target.checked = true;
                                    return;
                                  }
                                  if (
                                    e.name === 'Usuários' &&
                                    item.name === 'Telas de Configuração'
                                  ) {
                                    a.target.checked = true;
                                    return;
                                  }
                                  setAppPlusSelected((prevState) => {
                                    const newState = prevState.map((obj) => {
                                      if (obj.id === e.id) {
                                        return {
                                          ...obj,
                                          checked: a.target.checked,
                                        };
                                      }
                                      return obj;
                                    });
                                    return newState;
                                  });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`app-${e?.name}`}
                              >
                                {e.name}
                              </label>
                            </div>
                          ))}
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
              {rowIndex + 1 <
              chunkArray(
                products?.filter((e) => e.mainProduct === null),
                3
              ).length ? (
                <hr />
              ) : (
                ''
              )}
            </div>
          ))}
          {productsError && (
            <p style={{ color: 'red' }}>Selecione ao menos um Produto.</p>
          )}
          <Row
            style={{
              width: '100%',
              marginTop: 15,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <hr class="border-1 border-top border-dark" />
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              onClick={submitForm}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(EditContract);
