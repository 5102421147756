import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import WaitExport from "../../../components/WaitExport";
//redux
import { connect } from "react-redux";
//service
import {
  getMatchNoRoom,
  getRoommatesRoom,
  getStockRooms,
  createRoom,
  getParticipants,
  getMatch,
} from "../../../utils/services/match";

import { createXlsx } from "./createGeneratedRoomsXlsx";

function ModalLinksEmbeded(props) {
  const { isOpen, toggle, jwt, eventId } = props;
  const [getByGroup, setGetByGroup] = useState(null);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  const [position, setPosition] = useState("0%");
  const [roomSelectedOption, setRoomSelectedOption] = useState("Quadruplo");
  const [errorAlert, setErrorAlert] = useState("");
  const [getRoomAmount, setRoomAmount] = useState(0);

  async function getAllMatchNoRoom(type) {
    if (props.roomAmount == 0) {
      setErrorAlert("Não existe quartos disponiveis.");
      return;
    }

    setErrorAlert("");

    var rooms = [];

    await getMatchNoRoom({
      eventId: eventId,
      limit: 100000000,
      offset: 1,
      jwt: jwt,
    }).then(async (res) => {
      const { count, data } = res;

      if (count == 0) {
        setErrorAlert("Não existe participantes sem quarto!");
        return;
      }

      let users = data.map((e) => {
        return {
          id: e.login_id,
          name: e.login_nome,
          email: e.login_email,
          status: "completed",
        };
      });

      const stock = await getStockRooms({
        eventId: eventId,
        jwt: jwt,
      });

      let indexRefer = 0;

      setExportInProgress(true);
      let checkUsersAlreadyAdded = [];
      for (let r = 0; r < stock.length; r++) {
        if (stock[r].descricao == "Duplo") indexRefer = 2;
        else if (stock[r].descricao == "Triplo") indexRefer = 3;
        else indexRefer = 4;
        for (let s = 0; s < users.length; s++) {
          if (checkUsersAlreadyAdded.find((e) => e.id == users[s].id)) return;
          const dataUsers = await getParticipants({
            eventId: eventId,
            loginId: users[s].id,
            limit: indexRefer - 1,
            offset: 1,
            jwt: jwt,
          });

          checkUsersAlreadyAdded = [
            ...checkUsersAlreadyAdded,
            users[s],

            ...dataUsers.data.map((e) => {
              return {
                id: e.id,
                name: e.name,
                email: e.email,
                status: "completed",
              };
            }),
          ];

          const payload = {
            sendEmail: false,
            eventId: eventId,
            roommateId: users[s].id,
            roomSize: indexRefer,
            status: "pending_accept",
            enabled: true,
            choosens: [
              [
                {
                  id: users[s].id,
                  name: users[s].name,
                  email: users[s].email,
                  status: "completed",
                  order: 1,
                  type: "choosen",
                },
                ...dataUsers.data.map((e, i) => {
                  return {
                    id: e.id,
                    name: e.name,
                    email: e.email,
                    status: "completed",
                    order: i + 2,
                    type: "choosen",
                  };
                }),
              ],
            ],
            idiom: "ptBr",
          };

          rooms.push({
            typeRoom: stock[r].descricao,
            id1: users[s].id,
            name1: users[s].name,
            email1: users[s].email,
            id2: dataUsers.data[0] ? dataUsers.data[0].id : "",
            name2: dataUsers.data[0] ? dataUsers.data[0].name : "",
            email2: dataUsers.data[0] ? dataUsers.data[0].email : "",
            id3: dataUsers.data[1] ? dataUsers.data[1].id : "",
            name3: dataUsers.data[1] ? dataUsers.data[1].name : "",
            email3: dataUsers.data[1] ? dataUsers.data[1].email : "",
            id4: dataUsers.data[2] ? dataUsers.data[2].id : "",
            name4: dataUsers.data[2] ? dataUsers.data[2].name : "",
            email4: dataUsers.data[2] ? dataUsers.data[2].email : "",
          });

          // console.log(payload);

          if (type != "download") {
            await createRoom(payload, props.state.global_user_data.data.token);
          }
          setPosition(`${r * 10}%`);
        }
      }
    });

    if (type === "download") {
      createXlsx(rooms, setFinishExport);
    } else {
      setFinishExport(true);
      setPosition(`${100}%`);
    }
  }

  async function getAllMatchNoRoomNoGroup(type) {
    if (props.roomAmount == 0) {
      setErrorAlert("Não existe quartos disponiveis.");
      return;
    }

    setErrorAlert("");

    var rooms = [];

    await getMatchNoRoom({
      eventId: eventId,
      limit: 100000000,
      offset: 1,
      jwt: jwt,
    }).then(async (res) => {
      const { count, data } = res;

      if (count == 0) {
        setErrorAlert("Não existe participantes sem quarto!");
        return;
      }
      let users = data.map((e) => {
        return {
          id: e.login_id,
          name: e.login_nome,
          email: e.login_email,
          status: "completed",
        };
      });
      const stock = await getStockRooms({
        eventId: eventId,
        jwt: jwt,
      });

      let stockControl = 0;

      let indexRoom = 0;
      let indexRefer = 0;

      setExportInProgress(true);

      for (let r = 0; r < stock.length; r++) {
        if (stock[r].descricao == "Duplo") indexRefer = 2;
        else if (stock[r].descricao == "Triplo") indexRefer = 3;
        else indexRefer = 4;

        for (let s = 0; s < stock[r].disponivel; s++) {
          if (users.slice(indexRoom, indexRefer + indexRoom).length == 0) break;

          stockControl++;
          if (stockControl >= users.length) break;

          const payload = {
            sendEmail: false,
            eventId: eventId,
            roommateId: users.slice(indexRoom, indexRefer + indexRoom)[0].id,
            roomSize:
              (stock[r].descricao == "Duplo" && 2) ||
              (stock[r].descricao == "Triplo" && 3) ||
              (stock[r].descricao == "Quadruplo" && 4),
            status: "pending_accept",
            enabled: true,
            choosens: [
              users.slice(indexRoom, indexRefer + indexRoom).map((e, i) => {
                return {
                  ...e,
                  order: i + 1,
                  type: "choosen",
                };
              }),
            ],
            idiom: "ptBr",
          };

          const usersRooms = payload.choosens[0];

          rooms.push({
            typeRoom: stock[r].descricao,
            id1: usersRooms[0].id,
            name1: usersRooms[0].name,
            email1: usersRooms[0].email,
            id2: usersRooms[1] ? usersRooms[1].id : "",
            name2: usersRooms[1] ? usersRooms[1].name : "",
            email2: usersRooms[1] ? usersRooms[1].email : "",
            id3: usersRooms[2] ? usersRooms[2].id : "",
            name3: usersRooms[2] ? usersRooms[2].name : "",
            email3: usersRooms[2] ? usersRooms[2].email : "",
            id4: usersRooms[3] ? usersRooms[3].id : "",
            name4: usersRooms[3] ? usersRooms[3].name : "",
            email4: usersRooms[3] ? usersRooms[3].email : "",
          });

          if (type != "download") {
            await createRoom(payload, props.state.global_user_data.data.token);
          }

          if (stock[r].descricao == "Duplo") indexRoom += 2;
          else if (stock[r].descricao == "Triplo") indexRoom += 3;
          else indexRoom += 4;
        }

        setPosition(`${r * 10}%`);
      }
    });

    if (type === "download") {
      createXlsx(rooms, setFinishExport);
    } else {
      setFinishExport(true);
      setPosition(`${100}%`);
    }
  }

  useEffect(() => {
    /* getMatchNoRoom({
      eventId: eventId,
      limit: 100000000,
      offset: 1,
      jwt: jwt,
    }).then(async (res) => {
      const { count, data } = res;
      console.log(data);
    }); */
    /* getParticipants({
      eventId: eventId,
      loginId: "5cd9e95a-865e-4228-9913-17341de51185",
      limit: 100000000,
      offset: 1,
      jwt: jwt,
    }).then(async (res) => {
      const { count, data } = res;
      console.log(data);
    }); */
  }, []);

  return (
    <Modal isOpen={isOpen} autoFocus={true} centered={true}>
      <WaitExport
        customFinishTitle={"Preenchimento concluído."}
        customHeaderText={"Preenchendo Quartos"}
        customBodyText={`Em breve o preenchimento dos quartos será concluído, aguarde alguns instantes.`}
        isOpen={exportInProgress}
        name="Participantes sem Quarto"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={() => {
          toggle();
          props.updateTables();
        }}
        setExportInProgress={setExportInProgress}
        position={position}
      />
      <ModalHeader toggle={toggle}>Gerar Quartos Automaticamente</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col md={12}>
              <label className="form-labe label-tooltip">
                Respeitar segregação por grupos/cargo ? *
              </label>
              <div style={{ display: "flex" }}>
                <div className="form-check mb-3" style={{ marginRight: 10 }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="segregar"
                    id="segregar2"
                    value="option2"
                    defaultChecked={false}
                    onChange={(e) => setGetByGroup(false)}
                  />
                  <label className="form-check-label" htmlFor="segregar2">
                    Não
                  </label>
                </div>
                <div className="form-check mb-3" style={{ marginRight: 10 }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="segregar"
                    id="segregar1"
                    value="option1"
                    defaultChecked={false}
                    onChange={(e) => setGetByGroup(true)}
                  />
                  <label className="form-check-label" htmlFor="segregar1">
                    Sim
                  </label>
                </div>
              </div>
            </Col>
            {/* <div>
              <label className="switch" style={{ display: "flex" }}>
                <input
                  type="checkbox"
                  onChange={(e) => setGetByGroup(e.target.checked)}
                />
                <span className="slider round" style={{ marginLeft: 10 }}>
                  Respeitar segregação por grupos/cargo
                </span>
              </label>
            </div> */}
          </Row>
          <Row>
            <span
              className="slider round"
              style={{ textAlign: "center", marginTop: 20, color: "red" }}
            >
              {errorAlert}
            </span>
          </Row>
          <Row>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: "250px" }}
                disabled={getByGroup == null}
                onClick={() => {
                  if (getByGroup) getAllMatchNoRoom();
                  else getAllMatchNoRoomNoGroup();
                }}
              >
                Gerar Quartos e Salvar
              </Button>
            </div>
          </Row>

          <Row>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: "250px" }}
                disabled={getByGroup == null}
                onClick={() => {
                  if (getByGroup) getAllMatchNoRoom("download");
                  else getAllMatchNoRoomNoGroup("download");
                }}
              >
                Gerar Quartos e exportar Planilha
              </Button>
            </div>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalLinksEmbeded);
