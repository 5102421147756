import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Input,
  Label,
  Button,
} from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// select
import Select from "react-select";
// cor
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
//react router dom
import { useLocation } from "react-router-dom";
// service
import {
  createConfigApp,
  getConfigApp,
} from "../../../utils/services/configApp";
import { getUrlFiles } from "../../../utils/services/files";
import { getMobileApps } from "../../../utils/services/mobileApps";
//redux
import { connect } from "react-redux";

function ModalConfigApp(props) {
  const { toggle, isOpen, eventId, jwt } = props;
  const location = useLocation();
  // carregamento
  const [loading, setLoading] = useState(false);
  // dados do form
  const [mobileapps, setMobileApps] = useState([]);
  const [mobileappsMulti, setMobileAppsMulti] = useState([]);
  const [color, setColor] = useState("#ffffff");
  const [numberColum, setNumberColum] = useState(0);
  const [imageBackground, setImageBackground] = useState("");
  const [image, setImage] = useState("");
  const [menuLogo, setMenuLogo] = useState("");
  const [hideCredential, setHideCredential] = useState(false);
  const [hideLocation, setHideLocation] = useState(false);
  // imgs para visualização
  const [imageBackgroundView, setImageBackgroundView] = useState();
  const [imageView, setImageView] = useState();
  const [imageLogoView, setImageLogoView] = useState();
  // erros
  const [appsEventError, setAppsEventError] = useState("");
  const [numberColumnError, setNumberColumnError] = useState("");
  const [imageError, setImageError] = useState("");
  // alerta
  const [successAlert, setSuccessAlert] = useState(false);
  // tipo de requisição e dados da config criada
  const [typeRequest, setTypeRequest] = useState("");
  const [idConfig, setIdConfig] = useState(null);
  // carregamento do form
  const [loadingForm, setLoadingForm] = useState(true);

  function handleMobiAppsMulti(a) {
    setMobileAppsMulti(a);
  }

  function verifyForm() {
    if (!mobileappsMulti[0]) {
      setAppsEventError("Selecione ao menos um Aplicativo do Evento");
      return;
    } else setAppsEventError("");

    if (!numberColum || numberColum < 1) {
      setNumberColumnError("O campo Quantidade de Colunas é obrigatório");
      return;
    } else setNumberColumnError("");

    if (!image && !imageView) {
      setImageError("Selecione uma imagem para o evento");
      return;
    } else setImageError("");

    submitForm();
  }

  async function submitForm() {
    let urlEventImg = imageView;
    let expireDateEventImg = null;

    let urlBackGroundImage = imageBackgroundView;
    let expireDateBackGroundImage = null;

    let urlLogoMenu = imageLogoView;
    let expireDateLogoMenu = null;

    var data = new FormData();

    var haveImg = false;

    if (image && image?.target?.files[0]) {
      data.append("files", image.target.files[0]);
      haveImg = true;
    }
    if (imageBackground && imageBackground?.target?.files[0]) {
      data.append("files", imageBackground.target.files[0]);
      haveImg = true;
    }
    if (menuLogo && menuLogo?.target?.files[0]) {
      data.append("files", menuLogo.target.files[0]);
      haveImg = true;
    }
    data.append("folder", "event");
    data.append("eventId", eventId);

    if (haveImg) {
      await getUrlFiles(data, props.state.global_user_data.data.token).then(
        (response) => {
          response.map((e) => {
            if (e.name === image?.target?.files[0]?.name) {
              urlEventImg = e.url;
              expireDateEventImg = e.expires;
            }
            if (e.name === imageBackground?.target?.files[0]?.name) {
              urlBackGroundImage = e.url;
              expireDateBackGroundImage = e.expires;
            }
            if (e.name === menuLogo?.target?.files[0]?.name) {
              urlLogoMenu = e.url;
              expireDateLogoMenu = e.expires;
            }
          });
        }
      );
    }

    var mobileAppsArray = [];

    mobileappsMulti.map((e) => mobileAppsArray.push(e.value));

    const dataForm = {
      eventId: eventId,
      image: urlEventImg,
      backgroundColorMenu: color,
      menuLogo:
        urlLogoMenu === undefined || urlLogoMenu === null ? "" : urlLogoMenu,
      numberColumns: numberColum,
      backgroundImageMenu:
        urlBackGroundImage === undefined || urlBackGroundImage === null
          ? ""
          : urlBackGroundImage,
      hideCredential: hideCredential,
      hideLocation: hideLocation,
      expireDateImgEvent: expireDateEventImg,
      expireDateImgBackground: expireDateBackGroundImage,
      expireDateImgMenuLogo: expireDateLogoMenu,
      mobileApps: mobileAppsArray,
    };

    await createConfigApp(dataForm, jwt, typeRequest, idConfig).then((res) => {
      if (res.data.message === "created" || res.data.message === "updated") {
        setSuccessAlert(true);
      }
    });
    // .catch((error) => console.log("error form", error));
  }

  async function getMobileAppEvent() {
    await getMobileApps({
      jwt: props.state.global_user_data.data.token,
      offset: 1,
      limit: 100,
      search: "",
      contractId: props.state.global_user_data.data.theme[0].id,
    }).then((response) => {
      setMobileApps(response.findAllMobileAppData.data);
    });
  }

  async function verifyConfigApp() {
    await getConfigApp(eventId, jwt).then((res) => {
      if (res.data.findAllMobileAppData.count > 0) {
        setTypeRequest("put");
        const config = res.data.findAllMobileAppData.data[0];

        var appsSelected = [];

        config.app.map((e) => {
          appsSelected.push({
            label: e.name,
            value: e.id,
          });
        });

        setColor(config.backgroundColorMenu);
        setNumberColum(config.numberColumns);
        setImageBackgroundView(config.backgroundImageMenu);
        setImageView(config.image);
        setImageLogoView(config.menuLogo);
        setHideCredential(config.hideCredential);
        setHideLocation(config.hideLocation);
        setIdConfig(config.id);

        handleMobiAppsMulti(appsSelected);
      } else setTypeRequest("post");

      setLoadingForm(false);
    });
    // .catch((error) => console.log("error get", error));
  }

  useEffect(() => {
    verifyConfigApp();
    getMobileAppEvent();
  }, []);

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title={"Concluído"}
          onConfirm={() => {
            toggle();
          }}
        >
          {"Configurações salvas com sucesso!"}
        </SweetAlert>
      ) : null}
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Configurar APP</ModalHeader>
        {loadingForm ? (
          <p style={{ textAlign: "center" }}>Carregando ...</p>
        ) : (
          <ModalBody>
            <div>
              <Row style={{ marginTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">
                      Aplicativo(s) do Evento*
                    </label>
                    <Select
                      value={mobileappsMulti}
                      placeholder={"Selecione"}
                      isMulti={true}
                      onChange={(e) => {
                        handleMobiAppsMulti(e);
                      }}
                      options={[
                        {
                          options: [
                            ...mobileapps.map((e) => {
                              return { label: e.name, value: e.id };
                            }),
                          ],
                        },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                    <p style={{ color: "red" }}>{appsEventError}</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <ColorPicker
                  classOfContainer="bg-new-event-menu-pickr"
                  labelText="Cor de Fundo (Menu)"
                  setStateFunc={setColor}
                  defaultColor={color}
                />
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mt-3">
                    <label className="form-label">
                      Deixa Quantidade de Colunas - Max 3 (Menu)*
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      onChange={(e) => {
                        if (Number(e.target.value) <= 3) {
                          setNumberColum(e.target.value);
                        } else setNumberColum(3);
                      }}
                      value={numberColum}
                    />
                    <p style={{ color: "red" }}>{numberColumnError}</p>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginBottom: 10 }}>
                <Col md={imageBackgroundView && !imageBackground ? 6 : 12}>
                  <div>
                    <Label htmlFor="formFile" className="form-label">
                      Imagem de fundo (Menu)
                    </Label>
                    <Input
                      className="form-control"
                      type="file"
                      id="formFile"
                      defaultValue={null}
                      onChange={(e) => setImageBackground(e)}
                    />
                  </div>
                </Col>

                {imageBackgroundView && !imageBackground && (
                  <Col md={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={imageBackgroundView}
                        style={{ width: 100, height: 100 }}
                      />
                      <i
                        className="mdi mdi-trash-can icon-trash"
                        onClick={() => {
                          setImageBackgroundView(null);
                          setImageBackground(null);
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              <Row style={{ marginBottom: 10 }}>
                <Col sm={imageView && !image ? 6 : 12}>
                  <div>
                    <Label htmlFor="formFile" className="form-label">
                      Imagem do Evento*
                    </Label>
                    <Input
                      className="form-control"
                      type="file"
                      id="formFile"
                      defaultValue={null}
                      onChange={(e) => setImage(e)}
                    />
                    {imageError && <p style={{ color: "red" }}>{imageError}</p>}
                  </div>
                </Col>

                {imageView && !image && (
                  <Col md={6}>
                    <img src={imageView} style={{ width: 100, height: 100 }} />
                  </Col>
                )}
              </Row>

              <Row style={{ marginBottom: 10 }}>
                <Col sm={imageLogoView && !menuLogo ? 6 : 12}>
                  <div>
                    <Label htmlFor="formFile" className="form-label">
                      Logo (Menu)
                    </Label>
                    <Input
                      className="form-control"
                      type="file"
                      id="formFile"
                      defaultValue={null}
                      onChange={(e) => setMenuLogo(e)}
                    />
                  </div>
                </Col>

                {imageLogoView && !menuLogo && (
                  <Col md={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={imageLogoView}
                        style={{ width: 100, height: 100 }}
                      />
                      <i
                        className="mdi mdi-trash-can icon-trash"
                        onClick={() => {
                          setImageLogoView(null);
                          setMenuLogo(null);
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              <Row style={{ marginBottom: 20 }}>
                <Col md={6}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={hideCredential}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setHideCredential(true);
                        } else setHideCredential(false);
                      }}
                      id="checkAccreditationEvent"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="checkAccreditationEvent"
                    >
                      Ocultar Credencial
                    </label>
                  </div>
                </Col>

                {location?.state?.event?.eventFormat != "digital" && (
                  <Col md={6}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={hideLocation}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setHideLocation(true);
                          } else setHideLocation(false);
                        }}
                        id="checkLocalizationEvent"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkLocalizationEvent"
                      >
                        Ocultar Localização
                      </label>
                    </div>
                  </Col>
                )}
              </Row>

              <Row
                style={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <Button
                      color="danger"
                      style={{ width: 80, marginRight: 10 }}
                      onClick={() => toggle()}
                    >
                      Fechar
                    </Button>

                    <Button
                      color="success"
                      className="btn btn-success waves-effect waves-light"
                      style={{ width: 80 }}
                      onClick={() => verifyForm()}
                    >
                      Salvar
                    </Button>
                  </>
                )}
              </Row>
            </div>
          </ModalBody>
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalConfigApp);
