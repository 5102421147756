import React, { useState, useEffect } from "react";
import {
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  Table,
  Card,
  CardBody,
} from "reactstrap";

import Select from "react-select";
//redux
import { connect } from "react-redux";
//react router dom
import { useLocation } from "react-router-dom";
//alerta
import SweetAlert from "react-bootstrap-sweetalert";
// mascara
import { inputMask } from "../../../utils/helpers/masks/masks";
//service
import { newParticipant } from "../../../utils/services/participants";
import { getGroups } from "../../../utils/services/group";
import { addLoginInNotificationsScheduled } from "../../../utils/services/notification";
import { createQuotas } from "../../../utils/services/quotas";

import CompositonModals from "./Session/CompositionModals";

function NewParticipant(props) {
  const { isOpen, toggle } = props;
  const location = useLocation();
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  //state dos campos obrigatórios
  const [email, setEmail] = useState();
  const [nome, setNome] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [titleQrCode, setTitleQrCode] = useState("");
  const [codeQrCode, setCodeQrCode] = useState("");
  const [infoAcess, setInfoAccess] = useState("");
  const [cpf, setCpf] = useState("");
  const [company, setCompany] = useState("");
  const [office, setOffice] = useState("");
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [chat, setChat] = useState("");

  const [showModalNewSession, setShowModalNewSession] = useState(false);
  const [showModalEditSession, setShowModalEditSession] = useState(false);
  const [showModalDeleteSession, setShowModalDeleteSession] = useState(false);

  const [sessionItem, setSessionItem] = useState("");
  const [sessions, setSessions] = useState([]);

  // state de erros
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sectionError, setSectionError] = useState();
  const [cpfError, setCpfError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [passwordConfirmValidationError, setPasswordConfirmValidationError] =
    useState("");

  const [passwordChecked, setPasswordChecked] = useState(false);
  const [updateNotification, setUpdateNotification] = useState(false);
  //exibir cargo e empresa
  const [showCompany, setShowCompany] = useState(false);
  const [showOffice, setShowOffice] = useState(false);

  const [productsList, setProductsList] = useState([]);

  const [isOpenSession, setIsOpenSession] = useState(true);

  const [propsModals, setPropModals] = useState({});

  const addSession = (data) => setSessions((prev) => [...prev, data]);
  const updateSession = (id, newData) => {
    setSessions((prev) => {
      return prev.map((session) => {
        if (session.sessionId === id) {
          return { ...session, ...newData };
        }
        return session;
      });
    });
  };
  const deleteSession = (sessionIdToDelete) => {
    setSessions((prev) => {
      return prev.filter((session) => session.sessionId !== sessionIdToDelete);
    });
  };
  const toggleSession = () => setIsOpenSession(!isOpenSession);

  const handleChange = (value) => {
    inputMask({ mask: "cpf", value, setMaskedValue: setCpf });
  };

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function toggleModalNewSession() {
    setShowModalNewSession(!showModalNewSession);
  }

  function toggleModalEditSession() {
    setShowModalEditSession(!showModalEditSession);
  }

  function toggleModalDeleteSession() {
    setShowModalDeleteSession(!showModalDeleteSession);
  }

  function setPropsModals() {
    setPropModals({
      showModalNewSession,
      showModalEditSession,
      showModalDeleteSession,
      toggleModalNewSession,
      toggleModalEditSession,
      toggleModalDeleteSession,
      sessionItem,
      addSession,
      updateSession,
      deleteSession,
      sessions,
    });
  }

  function handleGroupMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  const submitForm = async (e) => {
    if (!nome) {
      setNameError("O campo Nome é obrigatório.");
      return;
    } else setNameError("");

    if (!email) {
      setEmailError("O campo Email é obrigatório.");
      return;
    } else setEmailError("");

    if (checkProductExists("Distribuição de Cotas") && sessions.length < 1) {
      setSectionError("O campo Sessão é obrigatório");
      return;
    } else setSectionError("");

    if (
      cpf.replace(/[^\d]+/g, "").length > 0 &&
      cpf.replace(/[^\d]+/g, "").length < 11
    ) {
      setCpfError("Insira todos os dígitos do CPF");
      return;
    } else setCpfError("");

    if (cpf.replace(/[^\d]+/g, "").length === 11 && !cpfValidator(cpf)) {
      setCpfError("Digite um CPF válido");
      return;
    } else setCpfError("");

    if (passwordChecked) {
      if (password === "") {
        setPasswordError("O campo Senha é obrigatório.");
        return;
      } else setPasswordError("");
      if (confirmPassword === "") {
        setPasswordConfirmError("O campo Confirmar senha é obrigatório.");
        return;
      } else setPasswordConfirmError("");

      if (password !== confirmPassword) {
        setPasswordConfirmValidationError(
          "A Senha e a confirmação devem ser iguais."
        );
        return;
      } else setPasswordConfirmValidationError("");
    }

    var groupsArray = [];

    if (allGroups) {
      groups.map((e) => groupsArray.push({ grupo: e.id }));
    } else {
      groupsMulti.map((e) => groupsArray.push({ grupo: e.value }));
    }

    var formData = await newParticipant({
      email: email,
      nome: nome,
      senha: password,
      titleQrCode: titleQrCode,
      codeQrCode: codeQrCode,
      infoAcess: infoAcess,
      token: props.state.global_user_data.data.token,
      id_evento: props.idEvent,
      grupos: groupsArray,
      empresa: company,
      cpf: cpf.replace(/[^\d]+/g, ""),
      cargo: office,
      interesseChat: chat,
      codigoInterno1: code1,
      codigoInterno2: code2,
      codigoInterno3: code3,
    });

    if (formData.message === "EMAIL_ALREADY_REGISTERED") {
      setEmailError("O email informado já está cadastrado.");
      return;
    } else setEmailError("");

    if (formData.message === "CPF_ALREADY_REGISTERED_IN_EVENT") {
      setCpfError("Esse CPF já está em uso");
      return;
    } else setCpfError("");

    if (formData.message === "created") {
      try {
        const { data } = await addLoginInNotificationsScheduled(
          props?.idEvent,
          formData.data.id,
          props.state.global_user_data.data.token,
          groupsArray
        );
        setUpdateNotification(data);

        if (checkProductExists("Distribuição de Cotas")) {
          const sessionsTransform = sessions.map((el) => ({
            eventId: props.idEvent,
            loginId: formData.data.id,
            sessionId: el.sessionId,
            companionsNumber: el.companionsNumber,
            accessibilityRestriction: el.accessibilityRestriction,
            typeRestriction: el.accessibilityRestriction
              ? el.typeRestriction
              : "",
            notes: el.notes,
            typeQuota: "manual",
            isSendEmailQuota: true,
            email: email,
            name: nome,
          }));
          await createQuotas({
            jwt: props.state.global_user_data.data.token,
            data: sessionsTransform,
          });
        }
      } catch (error) {
        // console.log(error)
      } finally {
        setsuccess_dlg(true);
      }
    }
  };

  // verificar se o evento tem o app como produto
  function checkItemMenuExists(menuOption) {
    // console.log("opt", props.state.global_user_data.data.foundProducts);
    return productsList.filter((item) => item === menuOption).length > 0;
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  //verificar cpf válidos
  function cpfValidator(cpfFormat) {
    cpfFormat = cpfFormat.replace(/[^\d]+/g, "");
    if (cpfFormat == "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpfFormat.length != 11 ||
      cpfFormat == "00000000000" ||
      cpfFormat == "11111111111" ||
      cpfFormat == "22222222222" ||
      cpfFormat == "33333333333" ||
      cpfFormat == "44444444444" ||
      cpfFormat == "55555555555" ||
      cpfFormat == "66666666666" ||
      cpfFormat == "77777777777" ||
      cpfFormat == "88888888888" ||
      cpfFormat == "99999999999"
    )
      return false;
    // Valida 1o digito
    var add = 0;
    for (var i = 0; i < 9; i++) add += parseInt(cpfFormat.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpfFormat.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpfFormat.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpfFormat.charAt(10))) return false;
    return true;
  }

  useEffect(() => {
    if (cpf.replace(/[^\d]+/g, "").length === 11 && !cpfValidator(cpf)) {
      setCpfError("CPF inválido");
    } else setCpfError("");
  }, [cpf]);

  useEffect(() => {
    getGroupsFunc();
    setProductsList(
      [
        ...new Set(
          props.state.global_user_data.data.foundProducts.map(
            (e) => e.namemainproduct
          )
        ),
      ].filter((f) => f !== "Telas de Configuração")
    );
  }, []);

  //verificar se o evento exibe empresa e cargo
  useEffect(() => {
    setShowCompany(
      props.state.global_user_data.data.foundEvents.filter(
        (e) => e.id === props.idEvent
      )[0]?.showCompany
    );

    setShowOffice(
      props.state.global_user_data.data.foundEvents.filter(
        (e) => e.id === props.idEvent
      )[0]?.showJobPosition
    );
  }, [props.state.global_user_data.data.foundEvents]);

  useEffect(() => {
    setPropsModals();
  }, [showModalNewSession, showModalEditSession, showModalDeleteSession]);

  return (
    <>
      {checkProductExists("Distribuição de Cotas") && (
        <CompositonModals {...propsModals} />
      )}

      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggleBackdrop();
        }}
        autoFocus={true}
        centered={true}
      >
        {success_dlg ? (
          <SweetAlert
            success
            title={"Criado"}
            onConfirm={() => {
              props.getAllParticipants();
              toggle();
              setsuccess_dlg(false);
            }}
          >
            <>
              <p>Participante criado com sucesso!</p>
              {updateNotification && (
                <p style={{ color: "#218a64", fontStyle: "italic" }}>
                  As Notificações agendadas foram atualizadas com esse
                  participante.
                </p>
              )}
            </>
          </SweetAlert>
        ) : null}
        <ModalHeader toggle={toggle}>Novo Participante</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm(e);
            }}
          >
            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Nome do Participante*</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                  {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                </div>
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Email do Participante*</label>
                  <input
                    className="form-control"
                    type="email"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                </div>
              </Col>
            </Row>

            {(checkProductExists("APP Eventos Plus") ||
              checkProductExists("Gateway de Pagamentos (Adesão)") ||
              checkProductExists("Match de Hospedagem") ||
              checkProductExists("RSVP")) && (
              <Row style={{ marginBottom: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">CPF</label>
                    <input
                      className="form-control"
                      value={cpf}
                      onChange={({ target }) => handleChange(target.value)}
                    />
                    {cpfError && <p style={{ color: "red" }}>{cpfError}</p>}
                  </div>
                </Col>
              </Row>
            )}

            {checkItemMenuExists("APP Eventos Plus") &&
              checkProductExists("APP Eventos Plus") && (
                <Row style={{ marginBottom: 10 }}>
                  <Col md={6}>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="checkpassword"
                        onChange={() => setPasswordChecked(!passwordChecked)}
                      />
                      <label htmlFor="checkpassword">Senha</label>
                    </div>
                  </Col>
                </Row>
              )}

            {passwordChecked && (
              <>
                <Row>
                  <Col lg={12}>
                    <div>
                      <label className="form-label">Senha*</label>
                      <input
                        className="form-control"
                        type="password"
                        defaultValue={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {passwordError && (
                        <p style={{ color: "red" }}>{passwordError}</p>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <div>
                      <label className="form-label">Confirmar Senha*</label>
                      <input
                        className="form-control"
                        type="password"
                        defaultValue={confirmPassword}
                        onChange={(e) => setconfirmPassword(e.target.value)}
                      />
                      {passwordConfirmError && (
                        <p style={{ color: "red" }}>{passwordConfirmError}</p>
                      )}
                    </div>
                  </Col>
                  {passwordConfirmValidationError && (
                    <p style={{ color: "red" }}>
                      {passwordConfirmValidationError}
                    </p>
                  )}
                </Row>
              </>
            )}

            {checkProductExists("APP Eventos Plus") && (
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Título QRCode</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={titleQrCode}
                        onChange={(e) => setTitleQrCode(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Código QRCode</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={codeQrCode}
                        onChange={(e) => setCodeQrCode(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: 10 }}>
                  <Col lg={12}>
                    <div>
                      <label className="form-label">
                        Informações de Acesso
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={infoAcess}
                        onChange={(e) => setInfoAccess(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: 10 }}>
                  <Col md={12}>
                    <label className="form-label">Bio do Chat</label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => setChat(e.target.value)}
                    />
                  </Col>
                </Row>
              </>
            )}
            {checkProductExists("Distribuição de Cotas") && (
              <Row style={{ marginBottom: 10 }}>
                <Col md={12}>
                  <label className="form-label" onClick={toggleSession}>
                    Sessão*
                    <i
                      className={
                        isOpenSession
                          ? "mdi mdi-arrow-up"
                          : "mdi mdi-arrow-down"
                      }
                      style={{ marginTop: 2, cursor: "pointer" }}
                    />
                  </label>
                </Col>
                <Collapse isOpen={isOpenSession}>
                  <Card
                    style={{
                      border: "0.5px solid lightgray",
                      marginBottom: "0.5px",
                    }}
                  >
                    <CardBody>
                      <Row
                        style={{
                          justifyContent: "flex-end",
                          marginRight: "5px",
                        }}
                      >
                        <Button
                          color="success"
                          className="btn-rounded waves-effect waves-light events-button-add-lg"
                          style={{ alignItems: "center", width: "auto" }}
                          onClick={() => {
                            toggleModalNewSession();
                          }}
                        >
                          Adicionar Sessão
                        </Button>
                      </Row>

                      <div style={{ marginTop: "10px" }}>
                        <Table striped className="table mb-0">
                          <thead style={{ backgroundColor: "#e4e4e4" }}>
                            <tr>
                              <th>Sessão</th>
                              <th>Quantidade de Ingressos</th>
                              <th>Restrição</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sessions &&
                              sessions.map((item) => (
                                <tr>
                                  <td>{item.title}</td>
                                  <td>{item.companionsNumber}</td>
                                  <td>
                                    {item.accessibilityRestriction
                                      ? "Sim"
                                      : "Não"}
                                  </td>
                                  <td>
                                    <i
                                      className={"mdi mdi-pencil"}
                                      style={{
                                        color: "green",
                                        fontSize: 17,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setSessionItem(item);
                                        toggleModalEditSession();
                                      }}
                                    />
                                    <i
                                      className="mdi mdi-trash-can"
                                      style={{
                                        color: "red",
                                        fontSize: 17,
                                        cursor: "pointer",
                                        minHeight: "100px",
                                      }}
                                      onClick={() => {
                                        setSessionItem(item);
                                        toggleModalDeleteSession();
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  {sectionError && (
                    <a style={{ color: "red" }}>{sectionError}</a>
                  )}
                </Collapse>
              </Row>
            )}

            {(showCompany || showOffice) && (
              <Row style={{ marginBottom: 10 }}>
                {showCompany && (
                  <Col md={showOffice ? 6 : 12}>
                    <label className="form-label">Empresa</label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Col>
                )}

                {showOffice && (
                  <Col md={showCompany ? 6 : 12}>
                    <label className="form-label">Cargo</label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => setOffice(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            )}

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">Código interno 1</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCode1(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={6}>
                <label className="form-label">Código interno 2</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCode2(e.target.value)}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Código interno 3</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCode3(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Grupos</label>
                  <Select
                    value={groupsMulti}
                    placeholder={"Selecione"}
                    isMulti={true}
                    onChange={(e) => {
                      handleGroupMulti(e);
                    }}
                    options={[
                      {
                        options: [
                          { label: "TODOS", value: "todos" },
                          ...groups.map((e) => {
                            return { label: e.name, value: e.id };
                          }),
                        ],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
              >
                Salvar
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewParticipant);
