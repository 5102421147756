
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { instructionsEN, instructionsPT, instructionsSP } from './core';

export const TooltipModal = ({ toggle, modal, type }) => {

  useEffect(() => {
    console.log({type})
  }, [type])

  function getLangModal(type) {
    return (
      {
        PT: instructionsPT,
        EN: instructionsEN,
        SP: instructionsSP,
      }[type] || instructionsEN
    );
  }

  return (
    <Modal style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "200px" }} isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{getLangModal(type)?.title}</ModalHeader>
      <ModalBody>
        <span>{getLangModal(type)?.chooserName}</span> <br />
        <span>{getLangModal(type)?.listRoomates}</span> <br />
        <span>{getLangModal(type)?.chosenName}</span> <br />

        <span>{getLangModal()?.warn}</span>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color="danger">Fechar</Button>
      </ModalFooter>
    </Modal>
  )
}