import axios from "axios";
import { urlBase } from "./config";

async function createConfigApp(data, jwt, typeRequest, idConfig) {
  var url = "";
  if (idConfig) {
    url = `${urlBase}/mobileappconfig/${idConfig}`;
  } else {
    url = `${urlBase}/mobileappconfig`;
  }

  try {
    const config = {
      method: typeRequest,
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      data,
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

async function getConfigApp(idEvent, jwt) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/mobileappconfig/${idEvent}/event`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

export { createConfigApp, getConfigApp };
