import axios from "axios";
import { urlBaseRanking } from "./config";
const urlBase = urlBaseRanking;

// const urlBase =
//   "https://gateway-admin-plus.inteegrav2.com.br/api-admin-ranking";

function getConfigurations({
  id,
  jwt,
  offset = 1,
  limit = 10,
  search = '',
  columnSearch = '',
}) {
  var config = {
    method: 'get',
    url: `${urlBase}/events/${id}/configurations/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createConfiguration(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: 'post',
    url: `${urlBase}/events/${data.idEvent}/configurations`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function editConfiguration(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: 'put',
    url: `${urlBase}/configurations/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.jwt}`,
    },
    data: dataForm,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteConfiguration(id, jwt) {
  var config = {
    method: 'delete',
    url: `${urlBase}/configurations/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getAllRanking({ id, jwt, offset, limit, search, columnSearch = '' }) {
  var config = {
    method: 'get',
    url: `${urlBase}/events/${id}/ranking/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getEarnedPoints({ loginId, eventId, jwt, offset, limit }) {
  var config = {
    method: "get",
    url: `${urlBaseRanking}/events/${eventId}/logins/${loginId}/earnedpoints?offset=${offset}&limit=${limit}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}


export { getConfigurations, createConfiguration, editConfiguration, deleteConfiguration, getAllRanking, getEarnedPoints };
