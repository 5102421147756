import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { updateApp } from "../../utils/services/mobileApps";
import { getFirebaseProjects } from "../../utils/services/firebaseProjects";
import { getMenusDefault } from './menuDefault';

// import Alerts from './Personalization/Alerts';
// import EventsList from './Personalization/EventsList';
// import Feed from './Personalization/Feed';
// import General from './Personalization/General';
// import Guests from './Personalization/Guests';
// import InitialConfigs from './Personalization/InitialConfigs';
// import Localization from './Personalization/Localization';
// import Login from './Personalization/Login';
// import Logistics from './Personalization/Logistics';
// import Profile from './Personalization/Profile';
// import Ranking from './Personalization/Ranking';
// import Schedule from './Personalization/Schedule';

export default function EditApps(props) {
  const { isOpen, toggle, editAppId, refetch, contractId } = props;
  const { mobileApps } = useSelector((state) => state);
  const { token } = useSelector((state) => state.global_user_data.data);
  const { personalization } = useSelector((state) => state);

  const state = useSelector((state) => state);

  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [app, setApp] = useState('');
  const [bundleId, setBundleId] = useState('');
  const [termoDeAcesso, setTermoDeAcesso] = useState('');
  const [telaPrincipal, setTelaPrincipal] = useState('');
  //validações campos formulários
  const [appError, setAppError] = useState(false);
  const [bundleIdError, setBundleIdError] = useState(false);

  //editado com sucesso
  const [success, setSuccess] = useState(false);

  const [firebaseProjects, setFirebaseProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [idFirebaseProjectError, setIdFirebaseProjectError] = useState(false);

  const selectedApp = mobileApps.find((e) => e.id === editAppId);

  // state options
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState('');
  const [code, setCode] = useState('Selecione');
  const [config, setConfig] = useState([]);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setApp(selectedApp?.name);
    setBundleId(selectedApp?.bundleId);
    setTermoDeAcesso(selectedApp?.termoDeAcesso);
    setTelaPrincipal(selectedApp?.telaPrincipal);
    setAppError(false);
    setBundleIdError(false);
    setIdFirebaseProjectError(false);
  }

  const getCode = (value) => {
    switch (value) {
      case 'Geral':
        return 'GERAL';
      case 'Agenda':
        return 'AGENDA';
      case 'Feed':
        return 'FEED';
      case 'Localização':
        return 'LOCALIZATION';
      case 'Logística':
        return 'LOGISTICA';
      case 'Ranking':
        return 'RANKING';
      case 'Convidados':
        return 'CONVIDADOS';
      case 'Networking':
        return 'NETWORKING';
      case 'Notes':
        return 'NOTES';

      default:
        return '';
    }
  };
  const getListCode = (value) => {
    switch (value) {
      case 'Geral':
        return 'GERAL';
      case 'AGENDA':
        return 'Agenda';
      case 'FEED':
        return 'Feed';
      case 'LOCALIZATION':
        return 'Localização';
      case 'LOGISTICA':
        return 'Logística';
      case 'RANKING':
        return 'Ranking';
      case 'CONVIDADOS':
        return 'CONVIDADOS';
      case 'NETWORKING':
        return 'Networking';
      case 'NOTES':
        return 'Notes';

      default:
        return '';
    }
  };
  async function updateMobileApps() {
    const data = {
      name: app,
      bundleId: bundleId,
      idFirebaseProject: selectedProject,
      enabled: true,
      contractId,
      termoDeAcesso: termoDeAcesso,
      telaPrincipal: telaPrincipal === 'Selecione' ? '' : telaPrincipal,
    };
    updateApp({ jwt: token, id: editAppId, data }).then((response) => {
      setSuccess(true);
      refetch();
      // resetFields();
    });
  }

  function submitForm() {
    if (app === '') {
      return setAppError(true);
    } else setAppError(false);
    if (!selectedProject || selectedProject === 'Selecione') {
      return setIdFirebaseProjectError(true);
    } else setIdFirebaseProjectError(false);

    if (bundleId === '') {
      return setBundleIdError(true);
    } else setBundleIdError(false);

    updateMobileApps();
    // resetFields();
  }

  function getMenus() {
    const newOptions = getMenusDefault();
    setOptions([...newOptions]);
  }

  useEffect(() => {
    if (editAppId) {
      getMenus();
      setApp(selectedApp?.name);
      setBundleId(selectedApp?.bundleId);
      setTermoDeAcesso(selectedApp?.termoDeAcesso);
      setTelaPrincipal(selectedApp?.telaPrincipal);
    }
  }, [mobileApps, editAppId, selectedApp]);

  useEffect(() => {
    if (isOpen) {
      getFBProjects();
      setSelectedProject(selectedApp.idFirebaseProject);
      return;
    }
    setSelectedProject(null);
  }, [isOpen]);

  function getFBProjects() {
    getFirebaseProjects({ jwt: token }).then((res) => {
      setFirebaseProjects(res.findAll.data);
    });
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        resetFields();
      }}
      autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Edição"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          App editado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Editar App</ModalHeader>
      <ModalBody>
        <form>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">ID do App</label>
              <input
                className="form-control"
                type="text"
                value={selectedApp?.id ? selectedApp.id : null}
                disabled
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">Nome do Aplicativo*</label>
              <input
                className="form-control"
                type="text"
                value={app}
                onChange={(e) => setApp(e.target.value)}
              />
              {appError && (
                <p style={{ color: 'red' }}>
                  O campo Nome do Aplicativo é obrigatório.
                </p>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">ID do Projeto (Firebase)*</label>

              <select
                className="form-control"
                onChange={(e) => {
                  setSelectedProject(e.target.value);
                }}
              >
                <option>Selecione</option>
                {firebaseProjects &&
                  firebaseProjects?.map((e, i) => (
                    <option
                      selected={
                        selectedApp?.idFirebaseProject === e.name ? true : false
                      }
                      key={i}
                    >
                      {e.name}
                    </option>
                  ))}
              </select>
              {idFirebaseProjectError && (
                <p style={{ color: 'red' }}>
                  O campo ID do Projeto (Firebase) é obrigatório.
                </p>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">Bundle ID*</label>
              <input
                className="form-control"
                type="text"
                value={bundleId}
                onChange={(e) => setBundleId(e.target.value)}
              />
              {bundleIdError && (
                <p style={{ color: 'red' }}>O campo Bundle ID é obrigatório.</p>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">Termo de Acesso</label>
              <input
                className="form-control"
                type="text"
                value={termoDeAcesso}
                onChange={(e) => setTermoDeAcesso(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10, marginBottom: 20 }}>
            <Col lg={12}>
              <label className="form-label">Tela Principal</label>
              <select
                className="form-control"
                value={getListCode(telaPrincipal)}
                onChange={({ target }) =>
                  setTelaPrincipal(getCode(target.value))
                }
              >
                {/* <option>Selecione</option> */}
                {/* <option>Geral</option> */}
                {options &&
                  options
                    .filter(
                      (e) =>
                        !config.find(
                          (a) => a.code === e.option || a.code === e.code
                        )
                    )
                    .map((e, i) => (
                      <option key={i}>{e?.describe || e.option}</option>
                    ))}
              </select>
            </Col>
          </Row>
          {/* <InitialConfigs />
          <Login />
          <EventsList />
          <General />
          <Alerts />
          <Profile />
          <Schedule />
          <Feed />
          <Localization />
          <Guests />
          <Logistics />
          <Ranking /> */}
          <Row
            style={{
              width: '100%',
              marginTop: 15,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              onClick={submitForm}
              disabled={personalization?.login?.toggleError || false}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
