import axios from "axios";
import { urlBase } from "./config";

function getQuotas({ jwt, id }) {
  var config = {
    method: "get",
    url: `${urlBase}/logineventoscotas/${id}/event`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getQuotasXlsx({ jwt, eventId, offSet, limit }) {
  var config = {
    method: "get",
    url: `${urlBase}/login/logineventcotas/${eventId}?offset=${offSet}&limit=${limit}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getQuotasByLogin({ jwt, idEvent, idLogin }) {
  var config = {
    method: "get",
    url: `${urlBase}/logineventoscotas/${idEvent}/event/${idLogin}/login`,

    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createQuotas({ jwt, data }) {
  var config = {
    method: "post",
    url: `${urlBase}/logineventoscotas`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function updateQuotas({ jwt, data }) {
  var config = {
    method: "put",
    url: `${urlBase}/logineventoscotas`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function disableQuotas({ jwt, id }) {
  var config = {
    method: "delete",
    url: `${urlBase}/logineventoscotas/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function disableAllQuotas({ jwt, data }) {
  var config = {
    method: "post",
    url: `${urlBase}/logineventoscotas/disableall`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function activateQuotas({ jwt, id }) {
  var config = {
    method: "put",
    url: `${urlBase}/logineventoscotas/${id}/activate`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getLoginBySession({
  jwt,
  eventId,
  sessionId,
  offset,
  limit,
  columnSearch,
  search,
}) {
  var config = {
    method: "get",
    url: `${urlBase}/logineventoscotas/${eventId}/event/${sessionId}/session?offset=${offset}&limit=${limit}&columnSearch=${columnSearch}&search=${search}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getReportLoginBySession({
  jwt,
  eventId,
  sessionId,
  offset,
  limit,
  columnSearch,
  search,
}) {
  var config = {
    method: "get",
    url: `${urlBase}/logineventoscotas/report/${eventId}/event/${sessionId}/session?offset=${offset}&limit=${limit}&columnSearch=${columnSearch}&search=${search}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getSessionUser(jwt, loginId) {
  var config = {
    method: "get",
    url: `${urlBase}/logineventoscotas/${loginId}/login`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getConfigQuota(jwt, idEvent) {
  var config = {
    method: "get",
    url: `${urlBase}/configsitequota/${idEvent}/event`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function changeConfigQuota({ jwt, data, id, type }) {
  var config = {
    method: type,
    url: `${urlBase}/configsitequota${id ? `/${id}` : ""}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  getLoginBySession,
  getReportLoginBySession,
  getQuotas,
  getQuotasByLogin,
  getQuotasXlsx,
  createQuotas,
  updateQuotas,
  disableQuotas,
  disableAllQuotas,
  activateQuotas,
  getSessionUser,
  getConfigQuota,
  changeConfigQuota,
};
