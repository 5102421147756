import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import { upsertRoomCapacity } from "../../../utils/services/match";
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";

function ModalEditStock(props) {
  const { isOpen, toggle, data, eventId } = props;

  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [double, setDouble] = useState(getTotal("Duplo"));
  const [triple, setTriple] = useState(getTotal("Triplo"));
  const [quadruple, setQuadruple] = useState(getTotal("Quadruplo"));

  const [errorDouble, setErrorDouble] = useState(false);
  const [errorTriple, setErrorTriple] = useState(false);
  const [errorQuadruple, setErrorQuadruple] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function getTotal(type) {
    return props?.data?.filter((e) => e.descricao === type)[0]?.total ?? 0;
  }

  function getOccupiedRoom(type) {
    return (
      props?.data?.filter((e) => e?.descricao === type)[0]?.preenchido ?? 0
    );
  }

  async function sendPayload() {
    let payload = [];
    if (double < getOccupiedRoom("Duplo")) {
      setErrorDouble(true);
      return;
    }
    payload.push({
      eventId: eventId,
      description: "Duplo",
      roomSize: 2,
      capacity: double,
    });

    if (triple < getOccupiedRoom("Triplo")) {
      setErrorTriple(true);
      return;
    }
    payload.push({
      eventId: eventId,
      description: "Triplo",
      roomSize: 3,
      capacity: triple,
    });

    if (quadruple < getOccupiedRoom("Quadruplo")) {
      setErrorQuadruple(true);
      return;
    }
    payload.push({
      eventId: eventId,
      description: "Quadruplo",
      roomSize: 4,
      capacity: quadruple,
    });

    if (payload.length > 0) {
      await upsertRoomCapacity(
        payload,
        props.state.global_user_data.data.token
      ).then((res) => {
        if (res.status === 200) {
          setShowAlertSuccess(true);
        }
      });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {showAlertSuccess ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            setShowAlertSuccess(false);
            toggle();
          }}
        >
          {"Estoque editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Estoque</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            sendPayload();
            e.preventDefault();
          }}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col lg={12}>
              <label className="form-label">Duplo</label>
              <input
                className="form-control"
                type="number"
                id="duplo"
                value={double}
                onChange={(e) => {
                  setErrorDouble(false);
                  setDouble(
                    isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value)
                  );
                }}
              />
              {errorDouble && (
                <div
                  style={{ color: "red", marginLeft: "2px" }}
                >{`Estoque mínimo é ${getOccupiedRoom("Duplo")}`}</div>
              )}
            </Col>
          </Row>

          <Row style={{ marginBottom: 10 }}>
            <Col lg={12}>
              <label className="form-label">Triplo</label>
              <input
                className="form-control"
                type="number"
                id="triplo"
                value={triple}
                onChange={(e) => {
                  setErrorTriple(false);
                  setTriple(
                    isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value)
                  );
                }}
              />
              {errorTriple && (
                <div
                  style={{ color: "red", marginLeft: "2px" }}
                >{`Estoque mínimo é ${getOccupiedRoom("Triplo")}`}</div>
              )}
            </Col>
          </Row>

          <Row style={{ marginBottom: 10 }}>
            <Col lg={12}>
              <label className="form-label">Quadruplo</label>
              <input
                className="form-control"
                type="number"
                id="quadruplo"
                value={quadruple}
                onChange={(e) => {
                  setErrorQuadruple(false);
                  setQuadruple(
                    isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value)
                  );
                }}
              />
              {errorQuadruple && (
                <div
                  style={{ color: "red", marginLeft: "2px" }}
                >{`Estoque mínimo é ${getOccupiedRoom("Quadruplo")}`}</div>
              )}
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 10,
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              type="submit"
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalEditStock);
