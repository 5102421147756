import axios from 'axios';
import { urlBaseFiles } from './config';

async function getParticipants({
  idEvent,
  offset,
  limit,
  search,
  columnSearch = '',
}) {
  try {
    const config = {
      method: 'GET',
      url: `${urlBaseFiles}/download/${idEvent}/?offset=${offset}&limit=${limit}${
        search ? `&search=${search}&columnSearch=${columnSearch}` : ''
      }`,
    };

    const response = await axios(config);

    return response;
  } catch (error) {
    // console.log(error);
    return error;
  }
}

async function getDownloads({ idEvent, search, offset, limit }) {
  try {
    const config = {
      method: 'GET',
      url: `${urlBaseFiles}/download/amount/${idEvent}/?offset=${offset}&limit=${limit}${search ? `&search=${search}` : ''}`,
    };
  
    const response = await axios(config);

    return response;
  } catch (error) {
    // console.log(error);
    return error;
  }
}

export { getParticipants, getDownloads };
