import axios from "axios";
import { urlBase } from "./config";
async function getAllSurveys({
  jwt,
  offset,
  limit,
  eventId,
  enabled = true,
  search,
  columnSearch = '',
}) {
  try {
    const config = {
      method: 'get',
      url: `${urlBase}/surveys/${eventId}/event/?offset=${offset}&limit=${limit}${
        search ? `&search=${search}` : ''
      }&columnSearch=${columnSearch}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getSurveysByGroup({
  jwt,
  offset,
  limit,
  search,
  eventId,
  enabled = true,
  reportName,
}) {
  try {
    const config = {
      method: 'get',
      url: `${urlBase}/surveys/grouped/${eventId}/event/${reportName}/report/?offset=${offset}&limit=${limit}${
        search ? `&search=${search}` : ''
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
}
export { getAllSurveys, getSurveysByGroup };
