import axios from "axios";
import { urlBaseMatch, urlBase } from "./config";

function delMatch({ id, jwt }) {
  var config = {
    method: "delete",
    url: `${urlBaseMatch}/rooms/${id}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getMatch({ eventId, limit, offset, search, jwt }) {
  var config = {
    method: "get",
    url: `${urlBaseMatch}/report?limit=${limit}&offset=${offset}&eventId=${eventId}&search=${search}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function createRoom(data, jwt) {
  const config = {
    method: "post",
    url: `${urlBaseMatch}/room`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

async function getParticipants({
  eventId,
  loginId,
  offset,
  limit,
  search = "",
  jwt,
}) {
  const config = {
    method: "get",
    url: `${urlBaseMatch}/login/event/${eventId}/login/${loginId}?offset=${offset}&limit=${limit}&search=${search}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

async function sendInviteConfirmation(data) {
  const config = {
    method: "post",
    url: `${urlBaseMatch}/roommateroom/inviteconfirmation`,
    headers: {
      Authorization: `Bearer ${data.jwt}`,
      "Content-Type": "application/json",
    },
    data: { ...data },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

async function getRoommatesRoom(eventId, roommateId, jwt) {
  const config = {
    method: "get",
    url: `${urlBaseMatch}/roommateroom/event/${eventId}/roommate/${roommateId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

function getMatchNoRoom({ eventId, limit, offset, jwt }) {
  var config = {
    method: "get",
    url: `${urlBaseMatch}/report/roomatesavailable?limit=${limit}&offset=${offset}&eventId=${eventId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getStockRooms({ eventId, jwt = "" }) {
  // console.log(`${urlBaseMatch}/events/${eventId}/rooms`)
  var config = {
    method: "get",
    url: `${urlBaseMatch}/events/${eventId}/rooms`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function upsertRoomCapacity(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBaseMatch}/roomscapacity`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    data: { payload: data },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createConfigMatch({ data, token, typeSendApi }) {
  var config = {
    method: typeSendApi,
    url: `${urlBase}/matchconfig`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getConfigMatch({ token, eventId }) {
  var config = {
    method: "get",
    url: `${urlBase}/matchconfig/${eventId}/event?offset=1&limit=10`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  getMatch,
  delMatch,
  getStockRooms,
  upsertRoomCapacity,
  createConfigMatch,
  getConfigMatch,
  getMatchNoRoom,
  createRoom,
  getRoommatesRoom,
  getParticipants,
  sendInviteConfirmation,
};
