import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { updateImg } from "../../utils/services/drive";

export default function EditImg(props) {
  const { isOpen, toggle, editImgId, refetch } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [tag, setTag] = useState([]);

  //validações campos formulários
  const [nameError, setNameError] = useState(false);

  //editado com sucesso
  const [success, setSuccess] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setNameError(false);
  }

  function addTag(newTag) {
    setTag([...new Set([...tag, newTag])]);
  }

  function removeTag(index) {
    const newTag = [...tag];
    newTag.splice(index, 1);
    setTag(newTag);
  }

  async function submitForm() {
    if (name === "") {
      return setNameError(true);
    } else setNameError(false);

    const obj = {
      id: editImgId.id,
      name,
      tags: tag,
    };

    await updateImg(jwt, obj);
    await refetch();

    resetFields();

    setSuccess(true);
  }

  useEffect(() => {
    if (editImgId) {
      setName(editImgId.name);
      setUrl(editImgId.url);
      setTag(editImgId.tags.map((tag) => tag.value));
    }
  }, [editImgId]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        resetFields();
      }}
      autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Edição"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Arquivo editado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Editar Arquivo</ModalHeader>
      <ModalBody>
        <form>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">ID do Arquivo</label>
              <input
                className="form-control"
                type="text"
                value={editImgId?.id ? editImgId.id : null}
                disabled
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">Nome do Arquivo*</label>
              <input
                className="form-control"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && (
                <p style={{ color: 'red' }}>O campo Nome é obrigatório.</p>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">Link</label>
              <input
                className="form-control"
                type="text"
                value={url}
                disabled
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col>
              <label className="form-label">Tags</label>
              <input
                className="form-control"
                type="text"
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && e.target.value !== '') {
                    addTag(e.target.value);
                    e.target.value = '';
                  }
                }}
              />
            </Col>
          </Row>
          {tag?.length > 0 && (
            <Row>
              <div className="tag-container">
                {tag.map((tag, index) => (
                  <div key={index} className="tag-wrapper">
                    <span className="tag-field">{tag}</span>
                    <i
                      className="mdi mdi-close"
                      onClick={() => removeTag(index)}
                    />
                  </div>
                ))}
              </div>
            </Row>
          )}

          <Row
            style={{
              width: '100%',
              marginTop: 15,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              onClick={submitForm}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
