import React, { useState, useEffect } from "react";
import {
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  Table,
  Card,
  CardBody,
} from "reactstrap";

import Select from "react-select";
//redux
import { connect } from "react-redux";
//react router dom
import { useLocation } from "react-router-dom";
//alerta
import SweetAlert from "react-bootstrap-sweetalert";
// mascara
import { inputMask } from "../../../utils/helpers/masks/masks";
//service
import { addParticipant } from "../../../utils/services/sorteios";
import { getGroups } from "../../../utils/services/group";
import { addLoginInNotificationsScheduled } from "../../../utils/services/notification";
import { createQuotas } from "../../../utils/services/quotas";
import { useSelector } from "react-redux";

import CompositonModals from "../Participant/Session/CompositionModals";

function NewParticipant(props) {
  const { isOpen, toggle, selectedSorteioEdit, refetch } = props;

  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  /* const { id: eventId } = location.state.event;
   */
  const location = useLocation();
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  //state dos campos obrigatórios
  const [email, setEmail] = useState();
  const [nome, setNome] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [titleQrCode, setTitleQrCode] = useState("");
  const [codeQrCode, setCodeQrCode] = useState("");
  const [infoAcess, setInfoAccess] = useState("");
  const [cpf, setCpf] = useState("");

  const [showModalNewSession, setShowModalNewSession] = useState(false);
  const [showModalEditSession, setShowModalEditSession] = useState(false);
  const [showModalDeleteSession, setShowModalDeleteSession] = useState(false);

  const [sessionItem, setSessionItem] = useState("");
  const [sessions, setSessions] = useState([]);
  const [typeRestriction, setTypeRestriction] = useState("");
  const [notes, setNotes] = useState("");

  // state de erros
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sectionError, setSectionError] = useState();
  const [cpfError, setCpfError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [ingressos, setIngressos] = useState(0);

  const [internalCode1, setInternalCode1] = useState("");
  const [internalCode2, setInternalCode2] = useState("");
  const [internalCode3, setInternalCode3] = useState("");
  const [access, setAcess] = useState(null);
  const [passwordConfirmValidationError, setPasswordConfirmValidationError] =
    useState("");

  const [ingressosError, setIngressosError] = useState("");
  const [accessError, setAcessError] = useState("");
  const [typeRestrictionError, setTypeRestrictionError] = useState("");
  const [notesError, setNotesError] = useState("");

  const [passwordChecked, setPasswordChecked] = useState(false);
  const [updateNotification, setUpdateNotification] = useState(false);
  //exibir cargo e empresa
  const [showCompany, setShowCompany] = useState(false);
  const [showOffice, setShowOffice] = useState(false);

  const [productsList, setProductsList] = useState([]);

  const [isOpenSession, setIsOpenSession] = useState(true);

  const [propsModals, setPropModals] = useState({});

  const [empresa, setEmpresa] = useState("");
  const [cargo, setCargo] = useState("");
  // carregamento
  const [loading, setLoading] = useState(false);

  const addSession = (data) => setSessions((prev) => [...prev, data]);
  const updateSession = (id, newData) => {
    setSessions((prev) => {
      return prev.map((session) => {
        if (session.sessionId === id) {
          return { ...session, ...newData };
        }
        return session;
      });
    });
  };
  const deleteSession = (sessionIdToDelete) => {
    setSessions((prev) => {
      return prev.filter((session) => session.sessionId !== sessionIdToDelete);
    });
  };

  const handleChange = (value) => {
    inputMask({ mask: "cpf", value, setMaskedValue: setCpf });
  };

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function toggleModalNewSession() {
    setShowModalNewSession(!showModalNewSession);
  }

  function toggleModalEditSession() {
    setShowModalEditSession(!showModalEditSession);
  }

  function toggleModalDeleteSession() {
    setShowModalDeleteSession(!showModalDeleteSession);
  }

  function setPropsModals() {
    setPropModals({
      showModalNewSession,
      showModalEditSession,
      showModalDeleteSession,
      toggleModalNewSession,
      toggleModalEditSession,
      toggleModalDeleteSession,
      sessionItem,
      addSession,
      updateSession,
      deleteSession,
      sessions,
    });
  }

  function handleGroupMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  const submitForm = async (e) => {
    setLoading(true);

    if (!nome) {
      setNameError("O campo Nome é obrigatório.");
      setLoading(false);
      return;
    } else setNameError("");

    if (!email) {
      setEmailError("O campo Email é obrigatório.");
      setLoading(false);
      return;
    } else setEmailError("");

    if (!ingressos) {
      setIngressosError("O campo Quantidade Total de Ingressos é obrigatório.");
      setLoading(false);
      return;
    } else setIngressosError("");

    if (ingressos < 1) {
      setIngressosError(
        "O campo Quantidade Total precisa de um valor maior que zero."
      );
      setLoading(false);
      return;
    } else setIngressosError("");

    if (access?.value == null) {
      setAcessError("O campo restrição de acessibilidade é obrigatório.");
      setLoading(false);
      return;
    } else setAcessError("");

    if (!typeRestriction && access?.value) {
      setTypeRestrictionError("O campo tipo de restrição é obrigatório.");
      setLoading(false);
      return;
    } else setTypeRestrictionError("");

    var groupsArray = [];
    if (groupsMulti.length > 0) {
      if (allGroups) {
        groups.map((e) => groupsArray.push(e.id));
      } else {
        groupsMulti.map((e) => groupsArray.push(e.value));
      }
    }

    let payload = {
      sorteioId: props.selectedSorteioEdit.id,
      eventId: props?.selectedSorteioEdit.eventId,
      sessionId: props.selectedSorteioEdit.session.id,
      name: nome,
      email: email,
      companionsNumber: parseInt(ingressos),
      accessibilityRestriction: access.value,
      typeRestriction: JSON.parse(access.value) ? typeRestriction : "",
      notes: notes,
      company: empresa,
      position: cargo,
      internalCode1: internalCode1,
      internalCode2: internalCode2,
      internalCode3: internalCode3,
      groups: groupsArray.toString(),
      jwt: jwt,
      isSendEmail: checkProductExists("Distribuição de Cotas"),
    };

    var formData = await addParticipant({
      ...payload,
    });
    if (formData.message.indexOf("EMAIL_ALREADY_REGISTERED") > -1) {
      setEmailError("O email informado já está cadastrado.");
      setLoading(false);
      return;
    } else setEmailError("");

    refetch();
    toggle();
    setLoading(false);
  };

  // verificar se o evento tem o app como produto
  function checkItemMenuExists(menuOption) {
    // console.log("opt", props.state.global_user_data.data.foundProducts);
    return productsList.filter((item) => item === menuOption).length > 0;
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: jwt,
      eventId: props?.selectedSorteioEdit.eventId,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  //verificar cpf válidos
  function cpfValidator(cpfFormat) {
    cpfFormat = cpfFormat.replace(/[^\d]+/g, "");
    if (cpfFormat == "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpfFormat.length != 11 ||
      cpfFormat == "00000000000" ||
      cpfFormat == "11111111111" ||
      cpfFormat == "22222222222" ||
      cpfFormat == "33333333333" ||
      cpfFormat == "44444444444" ||
      cpfFormat == "55555555555" ||
      cpfFormat == "66666666666" ||
      cpfFormat == "77777777777" ||
      cpfFormat == "88888888888" ||
      cpfFormat == "99999999999"
    )
      return false;
    // Valida 1o digito
    var add = 0;
    for (var i = 0; i < 9; i++) add += parseInt(cpfFormat.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpfFormat.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpfFormat.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpfFormat.charAt(10))) return false;
    return true;
  }

  useEffect(() => {
    if (cpf.replace(/[^\d]+/g, "").length === 11 && !cpfValidator(cpf)) {
      setCpfError("CPF inválido");
    } else setCpfError("");
  }, [cpf]);

  useEffect(() => {
    getGroupsFunc();
    setProductsList(
      [
        ...new Set(
          props.state.global_user_data.data.foundProducts.map(
            (e) => e.namemainproduct
          )
        ),
      ].filter((f) => f !== "Telas de Configuração")
    );
  }, []);

  //verificar se o evento exibe empresa e cargo
  useEffect(() => {
    setShowCompany(
      props.state.global_user_data.data.foundEvents.filter(
        (e) => e.id === props?.selectedSorteioEdit.eventId
      )[0]?.showCompany
    );

    setShowOffice(
      props.state.global_user_data.data.foundEvents.filter(
        (e) => e.id === props?.selectedSorteioEdit.eventId
      )[0]?.showJobPosition
    );
  }, [props.state.global_user_data.data.foundEvents]);

  useEffect(() => {
    setPropsModals();
  }, [showModalNewSession, showModalEditSession, showModalDeleteSession]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggleBackdrop();
        }}
        autoFocus={true}
        centered={true}
      >
        {success_dlg ? (
          <SweetAlert
            success
            title={"Criado"}
            onConfirm={() => {
              props.getAllParticipants();
              toggle();
              setsuccess_dlg(false);
            }}
          >
            <>
              <p>Participante criado com sucesso!</p>
              {updateNotification && (
                <p style={{ color: "#218a64", fontStyle: "italic" }}>
                  As Notificações agendadas foram atualizadas com esse
                  participante.
                </p>
              )}
            </>
          </SweetAlert>
        ) : null}
        <ModalHeader toggle={toggle}>Novo Participante</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!loading) {
                submitForm(e);
              }
            }}
          >
            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Nome do Participante*</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                  {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                </div>
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Email do Participante*</label>
                  <input
                    className="form-control"
                    type="email"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                </div>
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">Sessão*</label>
                <input
                  className="form-control"
                  min={1}
                  value={selectedSorteioEdit.session.title}
                  disabled
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">
                  Quantidade Total de Ingressos*
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={ingressos}
                  onChange={(e) => setIngressos(e.target.value)}
                />
              </Col>
              {ingressosError && (
                <p style={{ color: "red" }}>{ingressosError}</p>
              )}
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">
                  Você ou seu(s) acompanhante(s) possuem restrição de
                  acessibilidade?*
                </label>
                <Select
                  placeholder={"Selecione"}
                  onChange={(e) => {
                    setAcess(e);
                  }}
                  options={[
                    {
                      options: [
                        { label: "Sim", value: true },
                        { label: "Não", value: false },
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </Col>
              {accessError && <p style={{ color: "red" }}>{accessError}</p>}
            </Row>

            {access?.value && (
              <Row style={{ marginBottom: 10 }}>
                <Col md={12}>
                  <label className="form-label">Qual?*</label>
                  <input
                    className="form-control"
                    type="text"
                    value={typeRestriction}
                    onChange={(e) => setTypeRestriction(e.target.value)}
                  />
                </Col>
                {typeRestrictionError && (
                  <p style={{ color: "red" }}>{typeRestrictionError}</p>
                )}
              </Row>
            )}

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">Observações</label>
                <textarea
                  className="form-control"
                  type="text"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Col>
            </Row>

            {(showCompany || showOffice) && (
              <Row style={{ marginBottom: 10 }}>
                {showCompany && (
                  <Col md={showOffice ? 6 : 12}>
                    <label className="form-label">Empresa</label>
                    <input
                      className="form-control"
                      type="text"
                      value={empresa}
                      onChange={(e) => setEmpresa(e.target.value)}
                    />
                  </Col>
                )}

                {showOffice && (
                  <Col md={showCompany ? 6 : 12}>
                    <label className="form-label">Cargo</label>
                    <input
                      className="form-control"
                      type="text"
                      value={cargo}
                      onChange={(e) => setCargo(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            )}

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">Código interno 1</label>
                <input
                  className="form-control"
                  type="text"
                  value={internalCode1}
                  onChange={(e) => setInternalCode1(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={6}>
                <label className="form-label">Código interno 2</label>
                <input
                  className="form-control"
                  type="text"
                  value={internalCode2}
                  onChange={(e) => setInternalCode2(e.target.value)}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Código interno 3</label>
                <input
                  className="form-control"
                  type="text"
                  value={internalCode3}
                  onChange={(e) => setInternalCode3(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Grupos</label>
                  <Select
                    value={groupsMulti}
                    placeholder={"Selecione"}
                    isMulti={true}
                    onChange={(e) => {
                      handleGroupMulti(e);
                    }}
                    options={[
                      {
                        options: [
                          { label: "TODOS", value: "todos" },
                          ...groups.map((e) => {
                            return { label: e.name, value: e.id };
                          }),
                        ],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <Button
                color="success"
                type="sumit"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
              >
                {loading ? "Carregando" : "Salvar"}
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewParticipant);
