/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";

import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

import Select from "react-select";

import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";

//service
import { updateNotification } from "../../../utils/services/notification";
import { getParticipants } from "../../../utils/services/participants";
import { getGroups } from "../../../utils/services/group";
import { getUrlFiles } from "../../../utils/services/files";
// import FormEditor from "./components/FormEditor";

function EditNotification(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //users select
  const [selectedMulti, setselectedMulti] = useState([]);
  const [allLogin, setAllLogin] = useState(false);
  //state de participantes
  const [participants, setParticipants] = useState([]);
  //state de erros
  const [titleError, setTitleError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [userError, setUserError] = useState("");
  //agendar
  const [schedule, setSchedule] = useState(
    props.editNotification.schedule ? true : false
  );
  //confirmção de edição
  const [success_dlg, setsuccess_dlg] = useState(false);
  //foto
  const [imageView, setImageView] = useState(props.editNotification.image);
  //data
  const [date, setDate] = useState();
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [groupsMulti, setGroupsMulti] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  const [title, setTitle] = useState(props.editNotification.title);
  const [editorState, setEditorState] = useState(props.editNotification.text);
  const [textHtml, setTextHtml] = useState(props.editNotification.textHtml);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageView(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  function handleMulti(a) {
    setselectedMulti(a);
    if (a[0]?.value === "todos") {
      setAllLogin(true);
    } else {
      setAllLogin(false);
    }
  }

  function handleGroupsMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  function handleEditorState(textPlan, richText) {
    setEditorState(textPlan);
    setTextHtml(richText);
  }

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    // campos vazios
    if (title === "") {
      setTitleError("O campo Título é obrigatório.");
      return;
    } else setTitleError("");

    if (editorState === "") {
      setMessageError("O campo Mensagem é obrigatório.");
      return;
    } else setMessageError("");

    // if (schedule && e.target[5].value === "") {
    //   setDateError("Insira uma data e hora");
    //   return;
    // } else setDateError("");

    // if (schedule && e.target[6].value === "") {
    //   setTimeError("Insira uma hora");
    //   return;
    // } else setTimeError("");

    // if (selectedMulti.length >= 1) {
    //   setUserError("");
    // } else {
    //   if (!groupsMulti.length > 0) {
    //     setUserError("Selecione um ou todos usuários");
    //     return;
    //   }
    // }

    var login = [];
    // if (allLogin) {
    //   participants.map((e) => {
    //     login.push({ login: e.id });
    //   });
    // } else {
    //   selectedMulti.map((e) => {
    //     login.push({ login: e.value });
    //   });
    // }

    var groupsArray = [];

    // if (allGroups) {
    //   groups.map((e) => {
    //     groupsArray.push({ grupo: e.id });
    //   });
    // } else {
    //   groupsMulti.map((e) => {
    //     groupsArray.push({ grupo: e.value });
    //   });
    // }

    let urlImg = props.editNotification.image;
    let expireDate = props.editNotification.expireDate;
    let image = null;

    if (imageView === props.editNotification.image) {
      image = imageView;
    } else {
      image = e.target[3].files[0];
    }

    // const date = new Date(`${e.target[5].value}T${e.target[6].value}`);

    // var data = new FormData();

    // data.append("files", image);
    // data.append("folder", "notification");
    // data.append("eventId", props.idEvent);

    // if (imageView !== props.editNotification.image) {
    //   await getUrlFiles(data, props.state.global_user_data.data.token)
    //     .then((response) => {
    //       response.map(e => {
    //         urlImg = e.url
    //         expireDate = e.expires
    //       })
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    const form = {
      title: title,
      text: editorState,
      textHtml: textHtml,
      schedule: true,
      event: props.idEvent,
      sended: false,
      // logins: login,
      // grupos: groupsArray,
      // date: schedule ? date : null,
      // hour: schedule
      //   ? e.target[6].value
      //   : new Date().toLocaleTimeString().substring(0, 5),
      // image: urlImg,
      // expireDate: expireDate
    };
    var notificationData = await updateNotification(
      form,
      props.state.global_user_data.data.token,
      props.editNotification.id
    );

    if (notificationData.message === "NOTIFICATION_UPDATED") {
      setsuccess_dlg(true);
    }
  }

  async function getAllParticipants() {
    var formData = await getParticipants({
      jwt: props?.state.global_user_data.data.token,
      idEvent: props?.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    });

    if (formData.message === "users") {
      setParticipants(formData.data.data);
    }
  }

  function funcParticipants() {
    var part = [];
    if (participants?.length > 0) {
      props.editNotification?.logins?.map((a) => {
        part.push({ label: a.login.nome, value: a.login.id });
      });
      if (participants?.length === part.length) {
        part = [];
        part.push({ label: "TODOS", value: "todos" });
        setAllLogin(true);
      }
    }
    setselectedMulti(part);
  }

  function funcGroups() {
    var groupsArray = [];
    if (groups?.length > 0) {
      props.editNotification.grupos.map((a) => {
        groupsArray.push({ label: a.grupo.name, value: a.grupo.id });
      });
      if (groups?.length === groupsArray.length) {
        groupsArray = [];
        groupsArray.push({ label: "TODOS", value: "todos" });
        setAllGroups(true);
      }
    }
    setGroupsMulti(groupsArray);
  }

  function dateFunc() {
    if (props.editNotification.date) {
      setDate(formatData(props.editNotification.date));
    }
  }

  function formatData(startDate) {
    var data = new Date(startDate);
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = ano + "-" + mes + "-" + dia;

    return dataAtual;
  }
  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    // getAllParticipants();
    dateFunc();
    // getGroupsFunc();
  }, []);

  // useEffect(() => {
  //   funcParticipants();
  // }, [participants]);

  // useEffect(() => {
  //   funcGroups();
  // }, [groups]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.getAllNotification();
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"Notificação editada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader
        toggle={toggle}
        // onClick={() => console.log(props.editNotification)}
      >
        Editar Notificação
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">ID da Notificação</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editNotification.id}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Título*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={title}
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={props.editNotification.sended}
                />
                <p style={{ color: "red" }}>{titleError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Mensagem*</label>
                {/* <FormEditor
                  setEditorState={handleEditorState}
                  defaultEditorState={textHtml}
                />
                <p style={{ color: "red" }}>{messageError}</p> */}

                <textarea
                  className="form-control"
                  type="text"
                  defaultValue={props.editNotification.text}
                  onChange={(e) => setEditorState(e.target.value)}
                  disabled={props.editNotification.sended}
                />
                <p style={{ color: "red" }}>{messageError}</p>
              </div>
            </Col>
          </Row>

          {/* <Row style={{ marginTop: 10 }}>
            <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
              <label
                htmlFor="formFile"
                className="form-label"
                style={{ marginBottom: 0 }}
              >
                Foto
              </label>
              <label htmlFor="inputImage" style={{ marginBottom: 0 }}>
                <i class="bx bx-pencil" style={{ cursor: "pointer" }} />
              </label>

              <input
                id="inputImage"
                type={"file"}
                onChange={(e) => {
                  onImageChange(e);
                }}
                style={{ display: "none" }}
                disabled={!props.editNotification.schedule || props.editNotification.sended}
              />
            </Col>

            <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
              <img alt="edit" src={imageView} style={{ width: 70 }} />
            </Col>
          </Row> */}

          {/* <Row>
            <h5 className="font-size-14 mt-4">Enviar push agora?</h5>
          </Row>
          <Row className="mt-1">
            <Col md={4}>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                  defaultChecked={
                    !props.editNotification.schedule ? true : false
                  }
                  onClick={(e) => {
                    if (e.target.checked === true) {
                      setSchedule(false);
                    }
                  }}
                  disabled={!props.editNotification.schedule || props.editNotification.sended}
                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                  Sim
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="option2"
                  defaultChecked={
                    props.editNotification.schedule ? true : false
                  }
                  onClick={(e) => {
                    if (e.target.checked === true) {
                      setSchedule(true);
                    }
                  }}
                  disabled={!props.editNotification.schedule || props.editNotification.sended}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  Não
                </label>
              </div>
            </Col>

            {schedule && (
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Agendar para dia*</label>
                      <input
                        className="form-control"
                        type="date"
                        defaultValue={date}
                        disabled={props.editNotification.sended}
                      />
                      <p style={{ color: "red" }}>{dateError}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Hora*</label>
                      <input
                        className="form-control"
                        type="time"
                        defaultValue={props.editNotification.hour}
                        disabled={props.editNotification.sended}
                      />
                      <p style={{ color: "red" }}>{timeError}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row> */}

          {/* <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label
                  className="form-label"
                  onClick={() => funcParticipants()}
                >
                  Usuários{groupsMulti.length > 0 ? "" : "*"}
                </label>
                <Select
                  // value={selectedMulti}
                  value={0}
                  placeholder={"Selecione"}
                  isMulti={true}
                  // isDisabled={props.editNotification.sended}
                  isDisabled={true}
                  onChange={(e) => {
                    handleMulti(e);
                  }}
                  options={[
                    {
                      options: [
                        { label: "TODOS", value: "todos" },
                        ...participants.map((e) => {
                          return { label: e.nome, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
                <p style={{ color: "red" }}>{userError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: 10 }}>
            <Col lg={12}>
              <div>
                <label
                  className="form-label"
                  onClick={() => funcParticipants()}
                >
                  Grupos
                </label>
                <Select
                  value={groupsMulti}
                  placeholder={"Selecione"}
                  isMulti={true}
                  // isDisabled={props.editNotification.sended}
                  isDisabled={true}
                  onChange={(e) => {
                    handleGroupsMulti(e);
                  }}
                  options={[
                    {
                      options: [
                        { label: "TODOS", value: "todos" },
                        ...groups.map((e) => {
                          return { label: e.name, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row> */}

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 10,
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              disabled={
                !props.editNotification.schedule ||
                props.editNotification.sended
              }
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EditNotification);
