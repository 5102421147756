import { addDays, format } from "date-fns";
import { excelDateToJSDate } from "../../../utils/helpers/files/exceldatetojs";

export const excelColumnsPtBr = {
  Titulo: "title",
  DataDaProgramacao: "startDate",
  HoraInicio: "startHour",
  HoraFim: "endHour",
  QuantidadeCotas: "qtdCotas",
  CotasFechadas: "cotasFechadas",
  CotasSorteio: "cotasSorteio",
  Grupos: "groups",
};

function formatDate(date) {
  try {
    return format(addDays(new Date(date), 1), "MM-dd-yyyy");
  } catch (error) {
    return undefined;
  }
}

function formatTime(time) {
  try {
    return format(excelDateToJSDate(time), "HH:mm");
  } catch (error) {
    return undefined;
  }
}

export function allExcelColumns(row, index) {
  const cotasFechadas = row.cotasFechadas ? row.cotasFechadas : 0;
  const cotasSorteio = row.cotasSorteio ? row.cotasSorteio : 0;
  const formatedRow = {
    index,
    startDate: formatDate(row.startDate),
    startHour: row.startHour ? formatTime(row.startHour) : "",
    endHour: row.endHour ? formatTime(row.endHour) : "",
    title: row.title ? row.title : undefined,
    qtdCotas:
      row.qtdCotas > cotasFechadas + cotasSorteio ? row.qtdCotas : undefined,
    cotasFechadas,
    cotasSorteio,
    groups: row.groups ? row.groups.split(", ") : "",
  };

  return formatedRow;
}
