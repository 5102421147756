import axios from "axios";
import { urlBase } from "./config";

function createGroup({ eventId, description, jwt }) {
  var data = JSON.stringify({
    eventid: eventId,
    name: description,
  });

  var config = {
    method: "post",
    url: `${urlBase}/grupos`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getGroups({ jwt, eventId, offset, limit, search }) {
  var config = {
    method: "get",
    url: `${urlBase}/grupos/event/${eventId}/?offset=${offset}&limit=${limit}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // if (error.response) {
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      // } else if (error.request) {
      //   console.log(error.request);
      // } else {
      //   console.log('Error', error.message);
      // }
      return error.response.data;
    });
}

async function getGroupsByName({ jwt, eventId, search }) {
  var config = {
    method: "get",
    url: `${urlBase}/grupos/event/${eventId}/?search=${search}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // if (error.response) {
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      // } else if (error.request) {
      //   console.log(error.request);
      // } else {
      //   console.log('Error', error.message);
      // }
      return error.response.data;
    });
}

function deleteGroup({ id, jwt }) {
    var config = {
      method: "delete",
      url: `${urlBase}/grupos/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
  
    return axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

async function editGroup({ groupId, eventId, description, sourceName, jwt }) {
    var data = JSON.stringify({
      name: description,
      sourceName: sourceName,
      eventid: eventId
    });
    var config = {
      method: "put",
      url: `${urlBase}/grupos/${groupId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      data,
    };
  
    return axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

export { createGroup, getGroups, deleteGroup, editGroup, getGroupsByName };
