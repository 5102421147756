import axios from "axios";
import { urlBase } from "./config";

function createEventType(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/event-types`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function editEventType({ data, jwt, id }) {
  var config = {
    method: "put",
    url: `${urlBase}/event-types/${id}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function getEventType({ jwt, offset, limit, search, active }) {
  var config = {
    method: "get",
    url: `${urlBase}/event-types?offset=${offset}&limit=${limit}&columnSearch=type&search=${search}&active=${active}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { createEventType, editEventType, getEventType };
